import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


function BloodPressure({ data }) {
    // Use data.history for your chart rendering
    const pressureData = data?.history || [];

    let systolic = []; 
    let diastolic = []; 
    let dates = []; 
    pressureData.forEach(element => {
        systolic.push(element.systolic); 
        diastolic.push(element.diastolic); 
        dates.push(element.date); 
    });

    
    // Process blood pressure history data
   

    const options = {
        chart: {
            type: 'line',
            height: 260,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: dates,
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Blood Pressure'
            },
            min: null,
            max: null
        },
        series: [
            {
                name: 'Systolic',
                data: systolic,
                color: '#1279be',
                marker: {
                    enabled: true,
                    radius: 5,
                    fillColor: '#1279be'
                }
            },
            {
                name: 'Diastolic',
                data: diastolic,
                color: '#03335b',
                marker: {
                    enabled: true,
                    radius: 5,
                    fillColor: '#03335b'
                }
            }
        ],
        tooltip: {
            valueSuffix: ' mg/dL'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
    };

    return (
        <div className="col-md-12">
            <div className="member-engagement mb-3">
                <h1 className='dashboard-graph-title mb-2'>
                    <span>Blood Pressure</span>
                </h1>
                <h1 className="card-title1">{data?.current} <span>mmHg</span></h1>
                <div className='mt-3'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

export default BloodPressure; 