import React, { useState, useEffect, useRef } from 'react';
import { admin_telehealth_insights, admin_telehealth_insights_list, telehealth_status_graph, fetch_member_agent_list, download_telehealth_report
 } from '../../../services/met/Service';
import { LoaderLoader, exportExcelTableData } from '../../utils/CommonLibrary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatDate } from '../utils/CommonLibrary';
import ServerSideSortableDataTable from "../../cn/components/Tables/ServerSideSortableDataTable";
import { FiToggleRight } from 'react-icons/fi';
import BarGraph from './Graphs/BarGraph';
import PieChart from './Graphs/PieChart';
import { ToastContainer, toast } from 'react-toastify';




function TelehealthInsightsTable({ startDate, endDate, setIsLoading }) {
    
    const [searchQuery, setSearchQuery] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [bookingsList, setBookingsList] = useState([]); 
    const [localCommunicationStats, setLocalCommunicationStats] = useState({
        totalTeleHealthBookings: 0, 
        membersReached: 0, 
        agentCount: 0, 
        confirmation: 0
    });
    
    const { totalTeleHealthBookings, membersReached, agentCount, confirmation } = localCommunicationStats;

    const [paginationConfig, setPaginationConfig] = useState({
        perPage: 10,
        currentPage: 1,
        totalRecords: 0,
        totalPages: 0
    });


    const [filter,setFilter] = useState({ 
        searchString: "", 
        agent: "", 
        status: ''
    }); 

    const [graphData, setGraphData] = useState({ 
        barGraph: { 
            weekBooked: [], 
            callsBooked: []
        },  


        pie: { }
    }); 

    const [agentData, setAgentData] = useState([]); 
    const [sortConfig, setSortConfig] = useState({
        field: null,    
        direction: "asc"
    });

    useEffect(() => { 
        fetchAgentUserlist(); 
    }, []);

    useEffect(()  => { 
        fetchTeleHealthBookingsList(); 
    }, [paginationConfig.perPage, paginationConfig.currentPage, startDate, endDate, filter.status, filter.agent, filter.searchString, sortConfig.direction, sortConfig.field]); 

    useEffect(() => { 
        fetchTeleHealthStatusGraph();
        fetchTeleHealthStats(); 

    }, [paginationConfig.perPage, paginationConfig.currentPage, startDate, endDate, filter.status, filter.agent, filter.searchString]); 

    // debouncing search on sarch query
    useEffect(() => { 
        let timer; 

        timer = setTimeout(() => { 
            setFilter({...filter, searchString: searchQuery})
        }, 300); 

        return () => clearInterval(timer); 
    }, [searchQuery]); 

    const renderMETP1Tooltip = (props) => ( 
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the member.
        </Tooltip>
    );

    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Main Membership No for the member.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the member.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the Agent.
        </Tooltip>
    );


    

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The date and time when the communication was sent or made.
        </Tooltip>
    );


    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication status
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Click to listen to the recorded communication message.  
        </Tooltip>
    );


    const columns = [

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Agent Name</span>
                </OverlayTrigger>
            ),
            selector: 'agentName',
            sortable: true,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Member ID</span>
                </OverlayTrigger>
            ),
            selector: 'membershipNo',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Main Membership No</span>
                </OverlayTrigger>
            ),
            selector: 'mainMembershipNo',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Member Name</span>
                </OverlayTrigger>
            ),
            selector: 'memberName',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Member Mobile No</span>
                </OverlayTrigger>
            ),
            selector: 'mobile',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Network Type</span>
                </OverlayTrigger>
            ),
            selector: 'memberNetwork',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Contract No</span>
                </OverlayTrigger>
            ),
            selector: 'contractNo',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Contract Name</span>
                </OverlayTrigger>
            ),
            selector: 'contractName',
            sortable: false,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Policy Start Date</span>
                </OverlayTrigger>
            ),
            selector: 'policyStartDate',
            sortable: true,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Policy End Date</span>
                </OverlayTrigger>
            ),
            selector: 'policyEndDate',
            sortable: true,
        },

        {

            name: (
                <OverlayTrigger placement="top" overlay={renderMETP3Tooltip}>
                    <span>Contract Termination Date</span>
                </OverlayTrigger>
            ),
            selector: 'terminationDate',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Status</span>
                </OverlayTrigger>
            ),
            selector: 'policyStatus',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Requested Medication</span>
                </OverlayTrigger>
            ),
            selector: 'medication',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Member Gender</span>
                </OverlayTrigger>
            ),
            selector: 'gender',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Age</span>
                </OverlayTrigger>
            ),
            selector: 'age',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Chronic</span>
                </OverlayTrigger>
            ),
            selector: 'chronicConditions',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>City</span>
                </OverlayTrigger>
            ),
            selector: 'city',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Doctor Name</span>
                </OverlayTrigger>
            ),
            selector: 'doctorName',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Appointment Date</span>
                </OverlayTrigger>
            ),
            selector: 'appointmentDate',
            sortable: false,
        },


        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>Appointment Status</span>
                </OverlayTrigger>
            ),
            selector: 'edgeStatus',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP1Tooltip}>
                    <span>TH Notes</span>
                </OverlayTrigger>
            ),
            selector: 'notes',
            sortable: false,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP12Tooltip}>
                    <span>Booking Date</span>
                </OverlayTrigger>
            ),
            selector: 'createdTime',
            sortable: true,
        },

        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>TH onboarding status</span>
                </OverlayTrigger>
            ),
            selector: 'enrollmentStatusOld',
            sortable: true,
        },

        
        {
            name: (
                <OverlayTrigger placement="top" overlay={renderMETP6Tooltip}>
                    <span>Current Onboaring status</span>
                </OverlayTrigger>
            ),
            selector: 'enrollmentStatus',
            sortable: true,
        },

    ];

    function setPerPage(value) {
        setPaginationConfig({
            ...paginationConfig,
            currentPage: 1,
            perPage: value
        })
    }



    const updateSortConfig = (field, direction, isSortable) => {
        if (!isSortable) return;
        setSortConfig({
            field: field,
            direction: direction
        });
    }

    
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery("");
        setFilter({ 
            searchString: "", 
            agent: "", 
            status: ''
        })

        setSortConfig({
            field: null,
            direction: null
        });
    };


    const handleExport = async (downloadType) => {
        const requestData = {
            searchStr: filter.searchString,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            searchStr: filter.searchString,
            agentId: filter.agent,
            status: filter.status
        };

        try {
            const response = await download_telehealth_report(requestData);
            if (response.data.code === 200) {
                // Handle the successful response here, e.g., download the data
                console.log(response.data.data); // Replace with actual export logic
                
                // Show success toast with options
                toast.success("Export Successful, report will be available in the reports section", {
                    pauseOnHover: false,
                    draggable: false,
                    closeOnClick: false,
                    pauseOnFocusLoss: false,
                    autoClose: 5000 // Optional: duration for which the toast will be visible
                });
            }
        } catch (error) {   
            console.error("Error exporting data:", error);
        }
    };


    function fetchTeleHealthBookingsList(){ 
        setIsLoading(true); 
        admin_telehealth_insights_list({searchStr: filter.searchString, startDate: formatDate(startDate), endDate: formatDate(endDate), perPage: paginationConfig.perPage, pageNumber: paginationConfig.currentPage, agentId: filter.agent, status: filter.status, 
            orderCoumn: sortConfig.field, 
            order: sortConfig.direction
        }).then((response) => { 
            if(response.data.code === 200){ 

                setBookingsList(response.data.data?.list || []); 
                setPaginationConfig({
                    ...paginationConfig, 
                    totalRecords: response?.data?.paginationInfo?.totalEntries,
                    totalPages: response?.data?.paginationInfo?.totalPages
                }); 
            }
        }).catch(() => { 

        }).finally(() => {  
            setIsLoading(false); 
        })
      }

      function hanldeFitlerChange(e){ 
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        }) 
      }


       function fetchTeleHealthStatusGraph(){ 
          telehealth_status_graph({startDate: formatDate(startDate), endDate: formatDate(endDate), 
            status: filter.status, 
            searchStr: filter.searchString, 
            agentId: filter.agent
          }).then((response) => { 
              if(response.data.code === 200){ 
                    // formatting pie chart response as per graph requirements
                    let pieChartData = response?.data?.graph?.data || []; 
                    let newPieObject = {}; 
                    pieChartData.forEach((data)  => { 
                        newPieObject[Object.keys(data)[0]] = Object.values(data)[0]; 
                    });     

                    console.log(newPieObject); 

                    setGraphData({ 
                        ...graphData, 
                        barGraph: {
                            weekBooked: response?.data?.graph?.bookingraph?.columnName, 
                            callsBooked: response?.data?.graph?.bookingraph?.dataValue
                        }, 
                        pie: newPieObject
                    })
              }
          }).catch((e) => { 
             
          })
        }


    const fetchAgentUserlist = async () =>{
        setIsLoading(true); //to show loader
        try {
            let resp = await fetch_member_agent_list();
            
            if(resp.data.data.showSelect){
                setAgentData(resp.data.data.agentList);
            }
            
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  
        }
    }
    
    function fetchTeleHealthStats(){  
        setIsLoading(true); 
        admin_telehealth_insights({
            startDate: formatDate(startDate), 
            endDate: formatDate(endDate),
            agentId: filter.agent,
            searchStr: filter.searchString,
            status: filter.status,
        }).then((response) => { 
            if(response.data.code === 200){ 
                setLocalCommunicationStats({
                    totalTeleHealthBookings: response.data.data?.teleHealthBookingCount, 
                    membersReached: response.data.data?.memberReachCount, 
                    agentCount: response.data.data?.agentCount,
                    confirmation: response.data.data?.confirmation
                }); 
            }
        }).catch((e) => {   
            console.error(e);
        }).finally(() => { 
            setIsLoading(false); 
        });
    }

    return (
        <>
            <div className='container-fluid member-engagement tw-shadow-none'>
            <ToastContainer />
            <div className='tw-flex tw-gap-2 tw-flex-row tw-w-full tw-justify-between mb-4'>
                <div className='tw-w-1/3 tw-px-1'>
                    <div className="form-group" style={{ width: "100%" }}>
                        <input  
                            type="text"
                            placeholder="Search By Member ID"
                            name="searchQuery"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                    </div>
                </div>

                <div className='tw-w-1/3 tw-px-1'>
                    <div className="form-group" style={{ width: "100%" }}>
                            <select
                                name="agent"
                                value={filter.agent}
                                onChange={e => hanldeFitlerChange(e)}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select Agent--</option>
                                { 
                                    agentData.map((agent)   => (
                                        <option value={agent.agentId} key={agent.agentId}>{agent.fullName}</option>

                                    ))
                                }
                                
                            </select>
                    </div>
                </div>
                
                <div className='tw-w-1/3 tw-px-1'>
                    <div className="form-group" style={{ width: "100%" }}>
                            <select
                                name="status"
                                value={filter.status}
                                onChange={e => hanldeFitlerChange(e)}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select TH Status--</option>
                                <option value="rescheduled">Rescheduled</option>
                                <option value="completed">Completed</option>
                                <option value="done">Done</option>
                                <option value="confirmed">Confirmed</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="pending">Pending</option>
                            </select>
                    </div>
                </div>


                <div className=''>
                    <button onClick={handleClear} className='btn tw-w-full clear-search-btn'>Clear</button>
                </div>
                <div className=''>
                    <button onClick={handleExport} className='btn tw-w-full clear-search-btn'>Export</button>
                </div>

                </div>

                <div className="row text-center">
                    {/* <div className="col-4 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{ membersReached || 0}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Members Reached
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip"> Total number of members contacted for TeleHealth consultations.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>      
                    </div> */}

                    <div className="col-4 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{totalTeleHealthBookings || 0}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Telehealth Actions
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Total number of TeleHealth consultations across all statuses, including Confirmed, Completed, Canceled, and Rescheduled.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{confirmation || 0}</h1>
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Telehealth Confirmations
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Total number of telehealth consultations that are successfully confirmed or completed.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-4 px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1" style={{fontSize: '26px'}}>{agentCount || 0}</h1>   
                                        <h5 className="card-text1" style={{fontSize: '14px'}}>Agents
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Total number of agents handling TeleHealth bookings.</Tooltip>}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Daily Telehealth Bookings
                                <OverlayTrigger placement="top" 
                                    overlay={<Tooltip className="custom-tooltip">Number of telehealth bookings made for the selected day.</Tooltip>}>
                                    <i className='bx bx-info-circle ms-1'></i>
                                </OverlayTrigger>   
                                </span> 
                            </h1>

                            <BarGraph graphData={graphData.barGraph} />
                        </div>
                    </div>

                    <div className="col-md-6 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Telehealth Booking Status
                                <OverlayTrigger placement="top" 
                                    overlay={<Tooltip className="custom-tooltip">Visual representation of the status of confirmed, completed, canceled, or rescheduled telehealth appointments.</Tooltip>}>
                                    <i className='bx bx-info-circle ms-1'></i>
                                </OverlayTrigger>
                                </span> 
                            </h1>

                            <PieChart data={graphData.pie || {}} />
                        </div>
                    </div>

                </div>

            </div>

            <ServerSideSortableDataTable
                columns={columns}
                data={bookingsList}
                paginationInfo={paginationConfig}
                sortConfig={sortConfig}
                updateSortConfig={updateSortConfig}
                setPerPage={setPerPage}
                setPaginationInfo={setPaginationConfig}
                isLoading={tableLoading}
            />


        </>
    );


}

export default TelehealthInsightsTable;
