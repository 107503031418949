import React from 'react';
import './MemberProfileTable.css';
import { useNavigate } from 'react-router-dom';
import { LoaderLoader } from '../../utils/CommonLibrary';

function MemberProfileTable({ 
  profiles = [], 
  pagination = {
    totalRecords: 0,
    totalPages: 0,
    currentPage: 1,
    perPage: 12,
    pageSizeOptions: [12, 24, 36, 50],
    displayedRecords: 0,
    startRecord: 0,
    endRecord: 0
  },
  isLoading = false,
  onPageChange = () => {},
  onRowsPerPageChange = () => {}
}) {
  const navigate = useNavigate();

  // Handler for page change buttons
  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  // Handler for rows per page dropdown
  const handleRowsPerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value, 10);
    onRowsPerPageChange(newPerPage);
  };

  const getStatusClass = (type) => {
    switch(type) {
      case "warning": return "profile-bg-orange";
      case "success": return "profile-bg-green";
      case "danger": return "profile-bg-red";
      default: return "profile-bg-orange";
    }
  };

  // Helper to format the vital values with appropriate unit and handle missing data
  const formatVitalValue = (vitalKey, value) => {
    if (value === false || value === null || value === undefined) return '-';
    
    switch(vitalKey) {
      case 'spo2': return `${value}%`;
      case 'bodyTemp': return `${value}°F`;
      case 'prBpm': return `${value} bpm`;
      case 'hrv': return `${value} ms`;
      case 'rrp': return `${value} min`;
      case 'bp': return `${value} mmHg`;
      case 'blg': return `${value} mg/dL`; // Fasting
      case 'bgpp': return `${value} mg/dL`; // Post-prandial
      case 'ecg': return value; // ECG is usually text
      default: return value;
    }
  };

  // Function to check if a value is abnormal based on observation data from backend
  const isAbnormal = (member, vitalKey) => {
    if (!member.vitals || !member.vitals[vitalKey] || member.vitals[vitalKey] === false) {
      return false;
    }

    // Check if we have observation data
    if (member.vitals.observations && member.vitals.observations[vitalKey]) {
      const observation = member.vitals.observations[vitalKey];
      return observation && (
        observation.includes("High") || 
        observation.includes("Low") || 
        observation.includes("Hypo") || 
        observation.includes("Hyper") || 
        observation.includes("Diabetic") ||
        observation.includes("Fever") ||
        observation.includes("AFib") ||
        observation.includes("Abnormal") ||
        observation.includes("Critical")
      );
    }

    // If no observation data, check if member has alert data
    return member.alertData && member.alertData[vitalKey === 'bodyTemp' ? 'bt' : vitalKey === 'prBpm' ? 'pr' : vitalKey === 'blg' ? 'bglf' : vitalKey];
  };

  // Get class for cell background based on value status
  const getCellClass = (member, vitalKey) => {
    return isAbnormal(member, vitalKey) ? "vital-abnormal" : "";
  };

  return (
    <>
    <style>
      {`
        .vital-abnormal {
          background-color: rgba(255, 0, 0, 0.1);
          color: #B91C1C;
          font-weight: 600;
        }
        .table-responsive table thead th {
          background-color: #f8f9fa;
          position: sticky;
          top: 0;
          z-index: 10;
        }
      `}
    </style>
    <div className='comntent-UserManagement mb-3'>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className='table table-striped mb-0'>
            <thead>
              <tr>
                <th className='table-top-header'>Image</th>
                <th className='table-top-header'>Name</th>
                <th className='table-top-header'>Member ID</th>
                <th className='table-top-header'>Status</th>
                <th className='table-top-header'>Spo2</th>
                <th className='table-top-header'>Body Temp</th>
                <th className='table-top-header'>Heart Rate</th>
                <th className='table-top-header'>HRV</th>
                <th className='table-top-header'>Resp. Rate</th>
                <th className='table-top-header'>Blood Pressure</th>
                <th className='table-top-header'>BG (Fasting)</th>
                <th className='table-top-header'>BG (PP)</th>
                <th className='table-top-header'>ECG</th>
                <th className='table-top-header'>Last Checked</th>
              </tr>
            </thead>
              <tbody>
                {isLoading ? (
                  <LoaderLoader />
                ) : profiles.length > 0 ? (
                  profiles.map(member => (
                    <tr key={member.id} onClick={() => navigate(`/rpm-member-profile/${member.id}`)} style={{cursor: "pointer"}}>
                      <td>
                        <img src={member.image} className='member-profile-images-img' alt='profile' />
                      </td>
                      <td>{member.name}</td>
                      <td>{member.membershipNo || '-'}</td>
                      <td>
                        {member.status && member.status.type && (
                          <div className={`member-profile-box-header-actions-button-container status-badge ${getStatusClass(
                            member.status.type
                          )}`}>
                            {member.status.count}
                          </div>
                        )}
                      </td>
                      <td className={getCellClass(member, 'spo2')}>{formatVitalValue('spo2', member.vitals.spo2)}</td>
                      <td className={getCellClass(member, 'bodyTemp')}>{formatVitalValue('bodyTemp', member.vitals.bodyTemp)}</td>
                      <td className={getCellClass(member, 'prBpm')}>{formatVitalValue('prBpm', member.vitals.prBpm)}</td>
                      <td className={getCellClass(member, 'hrv')}>{formatVitalValue('hrv', member.vitals.hrv)}</td>
                      <td className={getCellClass(member, 'rrp')}>{formatVitalValue('rrp', member.vitals.rrp)}</td>
                      <td className={getCellClass(member, 'bp')}>{formatVitalValue('bp', member.vitals.bp)}</td>
                      <td className={getCellClass(member, 'blg')}>{formatVitalValue('blg', member.vitals.blg)}</td>
                      <td className={getCellClass(member, 'bgpp')}>{formatVitalValue('bgpp', member.vitals.bgpp)}</td>
                      <td className={getCellClass(member, 'ecg')}>{formatVitalValue('ecg', member.vitals.ecg)}</td>
                      <td>{member.lastChecked}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13" className="text-center py-4">
                      <h4>No members found matching your search criteria</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="pagination-container">
            <div className="rows-per-page">
              <label>Rows per page: </label>
              <select 
                value={pagination.perPage} 
                onChange={handleRowsPerPageChange}
              >
                {pagination.pageSizeOptions && pagination.pageSizeOptions.length > 0 
                  ? pagination.pageSizeOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))
                  : [12, 24, 36, 50].map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))
                }
              </select>
            </div>

            <div className="records-info">
              {pagination.displayedRecords > 0 ? 
                `${pagination.startRecord} - ${pagination.endRecord} of ${pagination.displayedRecords} records` :
                '0 records found'}
            </div>

            <div className="pagination-controls">
              <button onClick={() => handlePageChange(1)} disabled={pagination.currentPage === 1}>
                <i className='bx bx-first-page'></i>
              </button>
              <button onClick={() => handlePageChange(pagination.currentPage - 1)} disabled={pagination.currentPage === 1}>
                <i className='bx bx-chevron-left'></i>
              </button>
              <button onClick={() => handlePageChange(pagination.currentPage + 1)} disabled={pagination.currentPage === pagination.totalPages}>
                <i className='bx bx-chevron-right'></i>
              </button>
              <button onClick={() => handlePageChange(pagination.totalPages)} disabled={pagination.currentPage === pagination.totalPages}>
                <i className='bx bx-last-page'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemberProfileTable;