import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize modules
exporting(Highcharts);
highcharts3d(Highcharts);

const HourlyAnalyticsBar = ({ 
  categories, 
  series,
  height = 400,
  title = null,
  yAxisTitle = null
}) => {
  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: height,
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 0
      }
    },
    title: {
      text: title || "   "
    },
    subtitle: null,
    xAxis: {
      categories: categories,
      gridLineWidth: 1,
      gridLineColor: '#E5E5E5',
      lineColor: '#E5E5E5',
      tickColor: '#E5E5E5'
    },
    yAxis: {
      title: {
        text: yAxisTitle
      },
      gridLineColor: '#E5E5E5',
      gridLineDashStyle: 'ShortDash'
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        pointPadding: 0.2,
        groupPadding: 0.1,
        dataLabels: {
          enabled: false
        }
      }
    },
    series: series.map(s => ({
      ...s,
      color: s.color || (s.name === 'Delivery Time' ? '#1279BE' : '#03335B')
    })),
    credits: {
      enabled: false
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom'
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
    tooltip: {
      shared: true,
      formatter: function() {
        let tooltip = '<b>' + this.x + '</b><br/>';
        this.points.forEach(point => {
          tooltip += point.series.name + ': ' + point.y + '<br/>';
        });
        return tooltip;
      }
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default HourlyAnalyticsBar;