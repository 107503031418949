import React from 'react';

function BMI({ bmiValue = 0 }) {
    // Parse BMI to ensure it's a number
    const bmiNumeric = parseFloat(bmiValue) || 0;

    const getBMICategory = (bmi) => {
        if (bmi < 18.5) return 'Underweight';
        if (bmi < 25) return 'Normal';
        if (bmi < 30) return 'Overweight';
        return 'Obese';
    };

    const getMarkerPosition = (bmi) => {
        const min = 15;
        const max = 40;
        const position = ((bmi - min) / (max - min)) * 100;
        return Math.min(Math.max(position, 0), 100);
    };

    return (
        <div className="col-md-12">
            <div className="member-engagement  tw-min-h-[356px] mb-3">
                <h1 className='dashboard-graph-title mb-2'>
                    <span>Body Mass Index (BMI)</span>
                </h1>
                <h1 className="card-title1">{bmiValue}</h1>
                <div className=' tw-mt-16'>
                    <div className="flex flex-col items-center pt-3">
                        <div className="tw-relative tw-w-full tw-h-10 tw-rounded-full tw-bg-gradient-to-r tw-from-sky-400 tw-via-green-200 tw-to-red-400">
                            <div
                                className="tw-absolute tw-rotate-180 tw-w-0 tw-h-0 border tw-border-l-8 tw-border-r-8 tw-border-b-[16px] tw-border-l-transparent tw-border-r-transparent tw-border-b-red-500"
                                style={{
                                    top: '-16px',
                                    left: `${getMarkerPosition(bmiNumeric)}%`,
                                    transform: 'translateX(-50%)'
                                }}
                            ></div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-w-full tw-text-gray-500 tw-mt-2">
                            <span>15</span>
                            <span>18.5</span>
                            <span>25</span>
                            <span>30</span>
                            <span>40</span>
                        </div>
                        <div className="tw-text-center tw-mt-2">
                            <span className="tw-text-gray-600">{getBMICategory(bmiNumeric)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BMI; 