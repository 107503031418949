const allowedImageTypes = ['jpeg', 'jpg', 'gif', 'png'];
const maxFileSize = 2 * 1024 * 1024; // 2MB

export const validateForm = (formData) => {
  let errors = {};

  // Email validation
  if (!formData['emailId'].trim()) {
    errors['emailId'] = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
    errors['emailId'] = 'Email is invalid';
  } else if (formData['emailId'] !== '' && (formData['firstName'] === '' || formData['lastName'] === '')) {
    errors['emailId'] = 'Please type email id & select from below suggestions';
    errors['firstName'] = 'First Name is required';
    errors['lastName'] = 'Last name is required';
  }

  // Program ID validation
  //   if (!formData['programId'].trim()) {
  //     errors['programId'] = 'Program is required';
  //   }
  // if (formData['programId'].length === 0) {
  //   errors['programId'] = 'Select at least 1 program';
  // }

  // Role ID validation
  if (!formData['roleId']) {
    errors['roleId'] = 'Role is required';
  }


  return errors;
};


export const editValidateForm = (formData) => {
  let errors = {};

  // First Name validation
  const nameRegex = /^[a-zA-Z\s]+$/;
  if (!formData['firstName'].trim()) {
    errors['firstName'] = 'First Name is required';
  } else if (!nameRegex.test(formData['firstName'])) {
    errors['firstName'] = 'First Name should contain only letters and spaces';
  }

  // Last Name validation
  if (!formData['lastName'].trim()) {
    errors['lastName'] = 'Last Name is required';
  } else if (!nameRegex.test(formData['lastName'])) {
    errors['lastName'] = 'Last Name should contain only letters and spaces';
  }

  // Email validation
  if (!formData['emailId'].trim()) {
    errors['emailId'] = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
    errors['emailId'] = 'Email is invalid';
  }

  // Role ID validation
  if (!formData['roleId']) {
    errors['roleId'] = 'Role is required';
  }


  // if (formData['programIdArr'].length === 0) {
  //   errors['programId'] = 'Select at least 1 program';
  // }

  // status validation
  if (!formData['adminStatus'].trim()) {
    errors['adminStatus'] = 'Status is required';
  }


  return errors;
};




export const validateFormCareAdd = (formData) => {
  let errors = {};

  // Role ID validation
  if (formData['firstNameAr'] == '') {
    errors['firstNameAr'] = 'Arabic First Name is required';
  } else if (formData['lastNameAr'] == '') {
    errors['lastNameAr'] = 'Arabic Last Name is required';
  } else if (formData['mobileNumber'] == '') {
    errors['mobileNumber'] = 'Mobile Number is required';
  } else if (formData['mobileNumber'].length < 8) {
    errors['mobileNumber'] = 'Mobile Number should be of 8 digits';
  }

  if (formData['imagePath'] == 'https://storage.googleapis.com/ksabupatest/2024/09/24/hjmlr/n03jr8bjba.png' || formData['imagePath'] === '' || formData['imagePath'] === null) {
    errors['imagePath'] = 'Profile pic is required';
  }

  if (formData['emailId'] === '' || formData['emailId'] === null) {
    errors['emailId'] = 'Email ID is required';
  } else if (!/\S+@\S+\.\S+/.test(formData['emailId'])) {
    errors['emailId'] = 'Email is invalid';
  } else if (formData['emailId'] !== '' && (formData['firstName'] === '' || formData['lastName'] === '')) {
    errors['emailId'] = 'Please type email id & select from below suggestions';
    errors['firstName'] = 'Name is required';
    errors['lastName'] = 'Last name is required';
  }

  if (formData['supervisorId'] === '' || formData['supervisorId'] === null) {
    errors['supervisorId'] = 'This is required';
  }

  if (formData['licensed'] === '' || formData['licensed'] === null) {
    errors['licensed'] = 'This is required';
  }

  if (formData['accessLevel'] === '' || formData['accessLevel'] === null) {
    errors['accessLevel'] = 'This is required';
  }

  if (formData['callExt'] === '' || formData['callExt'] === null || formData['callExt'] === 0) {
    errors['callExt'] = 'This is required';
  }

  if (formData['language'] === '' || formData['language'] === null) {
    errors['language'] = 'Language is required';
  }

  if (formData['yourSelf'] === '' || formData['yourSelf'] === null) {
    errors['yourSelf'] = 'This is required';
  }

  if (formData['gender'] === '' || formData['gender'] === null) {
    errors['gender'] = 'Gender is required';
  }

  return errors;
};


export const validateFormServiceAdd = (formData) => {
  let errors = {};
  console.log("validation", formData);
  if (formData['selectedValue'] == '' || formData['selectedValue'] == undefined) {
    errors['selectedValue'] = 'Select Category';
  } else if (formData['serviceName'] == undefined || formData['serviceName'].trim() == '') {
    errors['serviceName'] = 'Add Service Name';
  } else if (formData['serviceRequestType'] == undefined || formData['serviceRequestType'] == '') {
    errors['serviceRequestType'] = 'Service Request Type';
  }
  return errors;
};

export const validateFormCallDispositionAdd = (formData) => {
  let errors = {};

  if (formData['dataRating'] == '' || formData['dataRating'] == undefined) {
    errors['dataRating'] = 'select data rating';
  } else if (formData['reason'] == undefined || formData['reason'].trim() == '') {
    errors['reason'] = 'Enter reason';
  }
  return errors;
};




export const validateFormContractPriorityAdd = (formData) => {
  let errors = {};

  if (formData['contractNumber'] == '' || formData['contractNumber'] == undefined) {
    errors['contractNumber'] = 'Enter Contract Number';
  } else if (formData['priority'] == undefined || formData['priority'].trim() == '') {
    errors['priority'] = 'Select Priority';
  }
  return errors;
};

export const validateFormServiceRecommendationsAdd = (formData) => {
  let errors = {};

  if (formData['serviceType'] == '' || formData['serviceType'] == undefined) {
    errors['serviceType'] = 'Select Service Type';
  } else if (formData['title'] == undefined || formData['title'].trim() == '') {
    errors['title'] = 'Enter the title';
  } else if (formData['services'].length <= 0) {
    errors['services'] = 'Select the services';
  }
  return errors;
};


export const validateFormWhatTemplateAdd = ({ templateName, categoryType, dashboard, selectedTags, html, uploaderComment }) => {
  let errors = {};
  const regex = /^[a-z_]+$/;

  if (templateName === '' || templateName === undefined || !regex.test(templateName)) {
    errors['templateName'] = 'Only lowercase letters and underscores are allowed';
  } else if (categoryType === '') {
    errors['category'] = 'Select category field';
  } else if (selectedTags.length === 0 || selectedTags === undefined) {
    errors['selectedTags'] = 'Please select tags';
  } else if (dashboard.length === 0) {
    errors['dashboard'] = 'Please select utilize template';
  } else if (html === undefined || html === '') {
    errors['messageBody'] = 'Message body cannot be empty';
  } else if (!uploaderComment || uploaderComment.trim() === '') {
    errors.uploaderComment = 'Uploader note is required';
  } else if (uploaderComment.length > 250) {
    errors.uploaderComment = 'Uploader note must not exceed 250 characters';
  }
  // else if(selectnone === 'Text' && buttonType === 'url' && (websiteUrls.some(item => item.phone === '' || item.url === ''))){
  //   errors['websiteMessage'] = 'Message body cannot be empty';
  //   return;
  // }
  return errors;
};

export const validateFormNewProgramAdd = (formData) => {
  let errors = {};
  const lettersAndSpacesOnly = /^[A-Za-z\s]+$/;
  const lettersSpacesAndSpecialCharsDec = /^[A-Za-z\s.,;-]+$/;

  if (formData['programName'].trim() === '' || !lettersAndSpacesOnly.test(formData['programName'])) {
    errors['programName'] = 'Program name should contain only letters and spaces';
  } else if (formData['programName'].length < 5 || formData['programName'].length > 100) {
    errors['programName'] = 'Program name must be between 5 and 100 characters';
  }

  // Validate program description (letters and spaces only)
  if (formData['programDescription'].trim() === '') {
    errors['programDescription'] = 'Program description should contain only letters and spaces';
  }
  // else if(formData['programDescription'].length < 5 || formData['programDescription'].length > 250) {
  //   errors['programDescription'] = 'Program description must be between 5 and 250 characters';
  // }

  // Validate program member description (letters and spaces only)
  // if (formData['descriptionForMember'].trim() === '' || !lettersAndSpacesOnly.test(formData['descriptionForMember'])) {
  //   errors['descriptionForMember'] = 'Program member description should contain only letters and spaces';
  // }
  return errors;
};

export const validateFormNewConditionAdd = (formData) => {
  let errors = {};
  const lettersAndSpacesOnly = /^[A-Za-z\s]+$/;
  const lettersSpacesAndSpecialCharsDec = /^[A-Za-z\s.,;-]+$/;

  if (formData['conditionName'].trim() === '' || !lettersAndSpacesOnly.test(formData['conditionName'])) {
    errors['conditionName'] = 'Condition name should contain only letters and spaces';
  } else if (formData['conditionName'].length < 5 || formData['conditionName'].length > 100) {
    errors['conditionName'] = 'Condition name must be between 5 and 100 characters';
  }

  // Validate program description (letters and spaces only)
  if (formData['conditionDescription'].trim() === '') {
    errors['conditionDescription'] = 'Condition description should contain only letters and spaces';
  }
  // else if(formData['conditionDescription'].length < 5 || formData['conditionDescription'].length > 100) {
  //   errors['conditionDescription'] = 'Condition description must be between 5 and 250 characters';
  // }
  return errors;
};




export const validateFormPathwayAdd = (formData) => {
  let errors = {};
  const lettersAndSpacesOnly = /^[A-Za-z\s]+$/;

  if (formData['pathwayName'].trim() === '' || !lettersAndSpacesOnly.test(formData['pathwayName'])) {
    errors['pathwayName'] = 'Pathway name should contain only letters and spaces';
  } else if (formData['pathwayName'].length < 5 || formData['pathwayName'].length > 100) {
    errors['pathwayName'] = 'Pathway name must be between 5 and 100 characters';
  }
  return errors;
};

export const validateFormCNCallDispositionAdd = (formData) => {
  let errors = {};

  if (formData['callStatus'] == '' || formData['callStatus'] == undefined) {
    errors['callStatus'] = 'Select call status';
  } else if (formData['callStream'] == undefined || formData['callStream'].trim() == '') {
    errors['callStream'] = 'Enter stream';
  }
  return errors;
};
