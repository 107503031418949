import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetch_role_data, rpm_fetch_admin_user_data, rpm_admin_user_update_data } from '../../../../services/rpm/Service';
import AddUser from '../../../componenents/UserManagement/AddUser';


const EditMember = () => {
    const navigate = useNavigate();
    const { id: userId } = useParams();
    const [formData, setFormData] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        emailId: '',
        roleId: '',
        adminStatus: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [roleData, setRoleData] = useState([]);

    useEffect(() => {
        fetchUserData(userId);
        fetchRoleData();
    }, [userId]);

    const fetchRoleData = async () => {
        try {
            const response = await fetch_role_data();
            if (response.data.code === 200) {
                setRoleData(response.data.data);
            } else {
                console.error("Error fetching roles:", response.data);
            }
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    const fetchUserData = async (userId) => {
        try {
            setIsLoading(true);
            const response = await rpm_fetch_admin_user_data(userId);
            if (response.data.code === 200) {
                const userData = response.data.data;
                setFormData({
                    ...userData,
                    roleId: userData.roleId.toString()
                });
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error fetching user data');
        } finally {
            setIsLoading(false);
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.emailId) errors.emailId = 'Email is required';
        if (!data.firstName) errors.firstName = 'First name is required';
        if (!data.lastName) errors.lastName = 'Last name is required';
        if (!data.roleId) errors.roleId = 'Role is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const errors = validateForm(formData);
            setErrors(errors);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            const response = await rpm_admin_user_update_data(userId, formData);
            console.log(response.data); 
            if (response.data.code === 200) {
                setSuccessMessage(response.data.data.message);
                navigate('/rpm-member-management');
            } else {
                setErrorMessage(response.data.data.message);
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('Error updating user');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const handleClear = () => {
        window.history.back();
    };

    return (
        <>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleClear}>
                        <i className="bi bi-chevron-left me-1"></i> Back
                    </button>
                    <div className="col">
                        <h4 className="mb-0 content-main-title">Edit User</h4>
                    </div>
                </div>
            </div>
            <AddUser
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClear={handleClear}
                errors={errors}
                isLoading={isLoading}
                successMessage={successMessage}
                errorMessage={errorMessage}
                roleOptions={roleData}
                isEditMode={true}
                isDisabled={true}
                action="Update"
            />
        </>
    );
};

export default EditMember;
