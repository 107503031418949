import  common_axios  from "./commonAxios";

let baseURL =   common_axios.defaults.baseURL;

export const checkValidUser = () => {
    var dataPass = common_axios.get(baseURL + 'apihub/check_valid_user');
    return dataPass;
};

export const fetchApiData = (apiName, paramValue) => {
    let params = { 'apiName': apiName, 'paramValue': paramValue }
    var dataPass = common_axios.post(baseURL + 'apihub/fetch_api_data', params);
    return dataPass;
};