import React, { useState, useEffect } from "react";
import TopFilter from "../../components/TopFilter";
import MemberProfileBox from "../../components/MemberProfileBox";
import MemberProfileTable from "../../components/MemberProfileTable";
import CalendarDatePicker from "../../../componenents/CalendarDatePicker";
import { decryptData } from "../../../utils/Utils";
import moment from "moment";
import PageTip from "../../../componenents/PageTip/PageTip";
import VitalsCards from "../../components/VitalsCards";
import {
  fetch_alert_stats,
  fetch_all_users,
} from "../../../../services/rpm/Service";
import { formatDate } from "../../../utils/CommonLibrary";
import "../../css/MainPage.css";

function MainPage() {


  // get the stored dates for the date range filter
  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  // set the start and end dates for the date range filter
  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );

  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const [isListView, setIsListView] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [memberProfiles, setMemberProfiles] = useState([]);
  const [pagination, setPagination] = useState({
    totalRecords: 0,
    totalPages: 0,
    currentPage: 1,
    perPage: 12,
    pageSizeOptions: [12, 24, 36, 50],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allAlertStats, setAllAlertStats] = useState([]);
  

  // Fetch data when component mounts or refreshTrigger changes
  useEffect(() => {
    fetchAllUsers(pagination.currentPage, pagination.perPage);
  }, [refreshTrigger]);


  // Also fetch data when page or page size changes
  useEffect(() => {
    fetchAllUsers(pagination.currentPage, pagination.perPage);
  }, [pagination.currentPage, pagination.perPage, startDate, endDate]);

  // get alert stats
  useEffect(() => {
    getAlertStats();
  }, [startDate, endDate]);

  // Centralized fetch function
  async function fetchAllUsers(pageNumber = 1, pageSize = pagination.perPage, searchString = "", conditionId = "", selected = []) {
    setIsLoading(true);

    let selectedArray = selected.map((item) => item.value);
    try {
      const response = await fetch_all_users({
        pageNumber: pageNumber,
        perPage: pageSize,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        searchString: searchString,
        conditionId: conditionId,
        selectedVitalTypes: JSON.stringify(selectedArray)
      });

      if (response.data?.code === 200) {
        const apiData = response.data.data?.data || [];

        // Get pagination info from response
        const paginationInfo = response.data.paginationInfo || {
          totalRecords: 0,
          totalPages: 0,
          currentPage: pageNumber,
          perPage: pageSize,
          pageSizeOptions: [12, 24, 36, 50],
        };

        setPagination({
          ...paginationInfo,
          perPage: pageSize,
          currentPage: pageNumber,
        });

        // Transform the data to match expected format
        const transformedData = apiData.map((item) => {
          // Helper function to extract vital value with proper parsing
          const getVitalValue = (vitalKey) => {
            if (!item.vitals[vitalKey]) return false;
            const value = item.vitals[vitalKey].value;
            // Try to parse as float if possible, otherwise return the original value
            return !isNaN(parseFloat(value)) ? parseFloat(value) : value;
          };

          // Helper function to get alert priority for a vital
          const getAlertPriority = (vitalKey) => {
            if (
              !item.vitals[vitalKey] ||
              !item.vitals[vitalKey].observation ||
              !item.vitals[vitalKey].observation.alertPriority
            ) {
              return 0;
            }
            return item.vitals[vitalKey].observation.alertPriority;
          };

          // Helper function to get observation text for a vital
          const getObservation = (vitalKey) => {
            if (
              !item.vitals[vitalKey] ||
              !item.vitals[vitalKey].observation ||
              !item.vitals[vitalKey].observation.observation
            ) {
              return null;
            }
            return item.vitals[vitalKey].observation.observation;
          };

          // Get alert priorities using the helper function for all vitals
          const vitalKeys = Object.keys(item.vitals).filter(
            (key) => item.vitals[key]
          );
          const alertPriorities = vitalKeys
            .map((key) => getAlertPriority(key))
            .filter((priority) => priority > 0);

          // Count alerts and determine overall status type based on highest alert priority
          const alertCount = alertPriorities.length;
          let statusType = "success";

          if (alertPriorities.length > 0) {
            // Find the highest alert priority
            const maxPriority = Math.max(...alertPriorities);

            if (maxPriority >= 8) {
              statusType = "danger";
            } else if (maxPriority >= 3) {
              statusType = "warning";
            }
          }

          // Map blood pressure which needs special handling
          let bpValue = false;
          if (item.vitals.bp && item.vitals.bp.value) {
            bpValue = item.vitals.bp.value;
          }

          // For ECG, use observation instead of value if available
          let ecgValue = false;
          if (item.vitals.ecg && item.vitals.ecg.value) {
            ecgValue = item.vitals.ecg.value;
          }
          // If there's an observation for ECG, use that instead
          const ecgObservation = getObservation("ecg");
          if (ecgObservation) {
            ecgValue = ecgObservation;
          }

          return {
            id: item.id,
            name: item.memberName,
            membershipNo: item.membershipNo,
            mobileNo: item.mobile,
            city: item.city,
            dob: item.dob,
            gender: item.gender,

            image:
              "https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png",
            status: {
              count: alertCount,
              type: alertCount ? statusType : false,
            },
            vitals: {
              // Map each vital from backend to frontend expected structure
              spo2: getVitalValue("spo2"),
              bodyTemp: getVitalValue("bt"), // Note: 'bt' in backend maps to 'bodyTemp' in frontend
              prBpm: getVitalValue("pr"), // 'pr' in backend maps to 'prBpm' in frontend
              hrv: getVitalValue("hrv"),
              rrp: getVitalValue("rrp"),
              bp: bpValue,
              // Blood glucose values - both fasting and post-prandial
              blg: getVitalValue("bglf"), // 'bglf' in backend maps to 'blg' in frontend (fasting)
              bgpp: getVitalValue("bgpp"), // Adding blood glucose post-prandial
              ecg: ecgValue,
              // Add observations for each vital for more detailed display if needed
              observations: {
                spo2: getObservation("spo2"),
                bodyTemp: getObservation("bt"),
                prBpm: getObservation("pr"),
                hrv: getObservation("hrv"),
                rrp: getObservation("rrp"),
                bp: getObservation("bp"),
                blg: getObservation("bglf"),
                bgpp: getObservation("bgpp"),
                ecg: getObservation("ecg"),
              },
              // Add alert priorities for each vital
              priorities: {
                spo2: getAlertPriority("spo2"),
                bodyTemp: getAlertPriority("bt"),
                prBpm: getAlertPriority("pr"),
                hrv: getAlertPriority("hrv"),
                rrp: getAlertPriority("rrp"),
                bp: getAlertPriority("bp"),
                blg: getAlertPriority("bglf"),
                bgpp: getAlertPriority("bgpp"),
                ecg: getAlertPriority("ecg"),
              },
            },
            files: Math.floor(Math.random() * 4) + 1,
            lastChecked: "December 20, 2023 | 08:38am",
            deviceStatus: "connected",
            // Store original alert data for potential future use
            alertData: Object.keys(item.vitals)
              .filter((key) => item.vitals[key] && item.vitals[key].observation)
              .reduce((acc, key) => {
                acc[key] = item.vitals[key].observation;
                return acc;
              }, {}),
          };
        });

        setMemberProfiles(transformedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  // Function to handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPagination((prev) => ({
        ...prev,
        currentPage: newPage,
      }));
    }
  };

  // Function to handle rows per page change
  const handleRowsPerPageChange = (newPerPage) => {
    setPagination((prev) => ({
      ...prev,
      perPage: newPerPage,
      currentPage: 1, // Reset to first page when changing page size
    }));
  };

  const toggleView = (checked) => {
    setIsListView(checked);
  };

  const handleSearch = (searchTerm) => {
    setSearchFilter(searchTerm);
  };

  // handle refresh page on pres of refresh button
  const handleRefresh = () => {
    setSearchFilter("");
    setRefreshTrigger((prev) => prev + 1);
  };

  // handle date range change
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Function to filter profiles based on search term
  const getFilteredProfiles = () => {
    if (!searchFilter.trim()) {
      return memberProfiles;
    }

    const lowerSearchFilter = searchFilter.toLowerCase();

    return memberProfiles.filter(
      (profile) =>
        profile.name.toLowerCase().includes(lowerSearchFilter) ||
        String(profile.id).includes(lowerSearchFilter) ||
        (profile.membershipNo &&
          profile.membershipNo.toLowerCase().includes(lowerSearchFilter))
    );
  };

  // Get filtered profiles for display
  const filteredProfiles = getFilteredProfiles();

  // Calculate pagination display info
  const displayedRecords = searchFilter.trim()
    ? filteredProfiles.length
    : pagination.totalRecords;
  const startRecord = Math.min(
    pagination.perPage * (pagination.currentPage - 1) + 1,
    displayedRecords
  );
  const endRecord = Math.min(
    startRecord + filteredProfiles.length - 1,
    displayedRecords
  );

  
  // get alert stats
  function getAlertStats(searchTerm=null,selectedCondition=null, selected = []) {
    let selectedArray = selected.map((item) => item.value);
    fetch_alert_stats({startDate: formatDate(startDate), endDate: formatDate(endDate), conditionId: selectedCondition, searchTerm: searchTerm, selectedVitalTypes: JSON.stringify(selectedArray)})
      .then((response) => {
        if(response.data?.code === 200){
          setAllAlertStats(response.data.data?.data || []);
        }else { 
          setAllAlertStats([]);
        }
      })
      .catch((error) => {
        setAllAlertStats([]);
      });
  }

  // Filter the stats based on selection or show all if nothing selected
  const filteredAlertStats = allAlertStats;

  // Calculate total alerts for filtered items
  const totalAlerts = filteredAlertStats.reduce(
    (sum, stat) => sum + parseInt(stat.value || 0),
    0
  );

  // Find the highest alert count among filtered items
  const highestAlertCount = Math.max(
    ...filteredAlertStats.map((stat) => parseInt(stat.value || 0))
  );

  return (
    <>
      <section className="ContentMainPage">
        <div className="container-fluid px-0 pb-3 mb-5">
          <div className="row align-items-center sticky-top-bar">
            <div className="col-md tw-flex tw-flex-row tw-items-center tw-justify-between sticky-top-bar">
              <h1 className="main-title">Live Data</h1>
              <div className="tw-w-[30%]">
                <CalendarDatePicker
                  startDate={startDate}
                  endDate={endDate}
                  onDateChange={handleDateChange}
                />
              </div>
            </div>
            <div className="col-md-auto">{/* <ProfileAvatar /> */}</div>
          </div>

          <div className="tw-my-3">
            <PageTip
              imgSrc="https://appcdn.goqii.com/storeimg/15308_1742548329.jpg"
              imgAlt="image2"
              text="A cutting-edge end to end health monitoring platform providing real-time insights into key vital parameters with AI-driven algorithms, customizable alerts, and Early Warning Scores for proactive care."
            />
          </div>

          <div className="row align-items-center tw-bg-[#f1f5fc] tw-sticky tw-z-20 tw-top-[101px]">
            <TopFilter
              onSwitchChange={toggleView}
              searchTerm={searchFilter}
              setSearchTerm={setSearchFilter}
              onSearch={handleSearch}
              onRefresh={handleRefresh}
              
              selected={selected}
              setSelected={setSelected}
              fetchAllUsers={fetchAllUsers}
              getAlertStats={getAlertStats}
            />
          </div>
          <VitalsCards
            totalAlerts={totalAlerts}
            highestAlertCount={highestAlertCount}
            selected={selected}
            filteredAlertStats={filteredAlertStats}
          />

          {/* Pass centralized data and handlers to both components */}
          {isListView ? (
            <MemberProfileTable
              profiles={filteredProfiles}
              pagination={{
                ...pagination,
                displayedRecords,
                startRecord,
                endRecord,
              }}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <MemberProfileBox
              profiles={filteredProfiles}
              pagination={{
                ...pagination,
                displayedRecords,
                startRecord,
                endRecord,
              }}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
      </section>
    </>
  );
}

export default MainPage;
