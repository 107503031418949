import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TbDeviceWatch } from "react-icons/tb";
import { Link } from 'react-router-dom';
import '../../css/Notification.css';

const Notifications = ({allAlerts, dismissAlert}) => {

    return (
        <>
            <div className='notificationDiv tw-w-auto'>
                <Row>
                    <Col>
                        <div className='row align-items-center'>
                            <div className='col-auto'>
                                <span className='tw-text-[#03335B] tw-text-5xl'>
                                    {/* <i className="fa fa-heartbeat" aria-hidden="true"></i> */}
                                    <TbDeviceWatch />
                                </span>
                            </div>
                            <div className='col px-1 notificationTitle'>
                                { allAlerts[0].message}
                            </div>
                            <div className='col-auto'>
                                <span>
                                    {/* <Link to={'/members/123'} className='name'>
                                        <button className="btn btn-primary save-btn">View Profile</button>
                                    </Link> */}
                                    <button className="btn notification-btn-closs ms-2" onClick={() => dismissAlert(allAlerts[0].id)}>
                                        <i className="bi bi-x-lg" aria-hidden="true"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Notifications;