import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  addEdge,
  Panel,
  Handle,
  Position,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";

// Custom Node Component with explicit handles
const CustomNode = ({
  data,
  sourcePosition = Position.Right,
  targetPosition = Position.Left,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-min-w-[200px] tw-max-w-[300px] tw-overflow-hidden">
      {/* These are the connection points for the node */}
      <Handle
        type="target"
        position={targetPosition}
        style={{ background: "#03335B", width: "10px", height: "10px" }}
        id={`${data.label}-target`}
      />

      <div
        className="tw-bg-[#03335B] tw-text-white tw-font-medium tw-px-3 tw-py-2 tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="tw-truncate tw-flex-1 tw-mr-2">{data.label}</span>
        <span className="tw-flex-shrink-0">{collapsed ? "▼" : "▲"}</span>
      </div>
      {!collapsed && (
        <div className="tw-p-3 tw-w-full">
          <div className="tw-bg-gray-100 tw-p-2 tw-rounded-lg tw-text-gray-800 tw-text-sm tw-whitespace-pre-line tw-break-words">
            {data.content}
          </div>
        </div>
      )}
      <Handle
        type="source"
        position={sourcePosition}
        style={{ background: "#03335B", width: "10px", height: "10px" }}
        id={`${data.label}-source`}
      />
    </div>
  );
};

// Define nodeTypes outside the component to avoid recreation on each render
const nodeTypes = {
  default: CustomNode,
};

const ReactFlowMain = ({ flowData }) => {
  const [selectedNode, setSelectedNode] = useState(null);

  const onNodeDragStart = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const onNodeDrag = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const onNodeDragStop = useCallback((event, node) => {
    // Keep showing the position for a moment after stopping drag
    setTimeout(() => {
      setSelectedNode(null);
    }, 2000);
  }, []);

  // Transform nodes to include data needed for handles
  const processNodes = useCallback((nodes) => {
    return nodes.map((node) => {
      return {
        ...node,
        style: {
          ...node.style,
          border: "none",
          padding: "0px",
          width: node.style?.width || 250, // Default width if not specified
        },
        // Keep sourcePosition and targetPosition from original data
        // but add explicit handle IDs in data for reference
        data: {
          ...node.data,
          sourceHandleId: `${node.id}-source`,
          targetHandleId: `${node.id}-target`,
        },
      };
    });
  }, []);

  // Transform edges to use explicit handle IDs
  const processEdges = useCallback((edges, nodes) => {
    return edges.map((edge) => {
      return {
        ...edge,
        type: "smoothstep",
        style: { stroke: "#03335B", strokeWidth: 3 },
        animated: true,
      };
    });
  }, []);

  // Use processed nodes and edges
  const processedNodes = useMemo(
    () =>
      flowData?.nodes
        ? processNodes(
            flowData.nodes.map((node) => ({
              ...node,
              style: {
                ...node.style,
                border: "none",
                padding: "0px",
              },
            }))
          )
        : [],
    [flowData?.nodes, processNodes]
  );

  const processedEdges = useMemo(
    () => (flowData?.edges ? processEdges(flowData.edges, processedNodes) : []),
    [flowData?.edges, processedNodes, processEdges]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(processedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(processedEdges);
  const [stats, setStats] = useState({
    conversationSteps: 0,
    branches: 0,
    endpoints: 0,
  });

  // Update nodes and edges when flowData changes
  useEffect(() => {
    if (flowData) {
      const updatedNodes = processNodes(
        flowData.nodes
          ? flowData.nodes.map((node) => ({
              ...node,
              style: {
                ...node.style,
                border: "none",
                padding: "0px",
              },
            }))
          : []
      );
      const updatedEdges = processEdges(flowData.edges || [], updatedNodes);

      setNodes(updatedNodes);
      setEdges(updatedEdges);

      // Calculate flow statistics
      const steps = (flowData.nodes || []).length;
      const uniqueSourceNodes = new Set(
        (flowData.edges || []).map((edge) => edge.source)
      );

      // Calculate endpoints
      const endNodes = [];

      if (flowData.nodes && flowData.edges) {
        flowData.nodes.forEach((node) => {
          const hasOutgoing = flowData.edges.some(
            (edge) => edge.source === node.id
          );
          const hasIncoming = flowData.edges.some(
            (edge) => edge.target === node.id
          );

          if (hasIncoming && !hasOutgoing) {
            endNodes.push(node.id);
          }
        });
      }

      setStats({
        conversationSteps: steps,
        branches: uniqueSourceNodes.size,
        endpoints: endNodes.length || 0,
      });
    }
  }, [flowData, setNodes, setEdges, processNodes, processEdges]);

  // Memoize the onConnect callback
  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "smoothstep",
            animated: true,
            style: { stroke: "#03335B", strokeWidth: 3 },
          },
          eds
        )
      ),
    [setEdges]
  );

  // Memoize default edge options
  const defaultEdgeOptions = useMemo(
    () => ({
      type: "smoothstep",
      animated: true,
      style: { stroke: "#03335B", strokeWidth: 3 },
    }),
    []
  );

  return (
    <div className="tw-h-[58vh] tw-w-full tw-mx-auto tw-rounded-md tw-relative">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        elementsSelectable={true}
        proOptions={{ hideAttribution: true }}
        fitView
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeDragStart={onNodeDragStart}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
      >
        <Controls />
        <MiniMap
          nodeStrokeColor={() => "#03335B"}
          nodeColor={() => "#000000"}
          nodeBorderRadius={10}
          style={{
            backgroundColor: "#f8f8f8",
            // border: '2px solid #03335B',
            borderRadius: "8px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          }}
          maskColor="rgba(3, 51, 91, 0.1)"
        />
        <Background
          id="1"
          gap={10}
          color="#f1f1f1"
          variant={BackgroundVariant.Lines}
        />

        <Background
          id="2"
          gap={50}
          color="#ccc"
          variant={BackgroundVariant.Cross}
        />

        <Panel position="top-right">
          <div className="tw-bg-white tw-rounded-md tw-shadow-sm tw-p-3 tw-text-sm">
            <div className="tw-font-medium tw-mb-2 tw-text-gray-700">
              Flow Statistics
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <div className="tw-flex tw-justify-between">
                <span className="tw-text-gray-600">Conversation Steps:</span>
                <span className="tw-font-medium">
                  {stats.conversationSteps}
                </span>
              </div>
              <div className="tw-flex tw-justify-between">
                <span className="tw-text-gray-600">Branches:</span>
                <span className="tw-font-medium">{stats.branches}</span>
              </div>
              <div className="tw-flex tw-justify-between">
                <span className="tw-text-gray-600">End Points:</span>
                <span className="tw-font-medium">{stats.endpoints}</span>
              </div>
              <div className="tw-flex tw-justify-between tw-mt-2">
                <span className="tw-text-gray-600">Status:</span>
                <span
                  className={`tw-font-medium ${
                    flowData?.status?.type === "sent"
                      ? "tw-text-green-600"
                      : "tw-text-gray-600"
                  }`}
                >
                  {flowData?.status?.text || "Not Available"}
                </span>
              </div>
            </div>
          </div>
        </Panel>

        {selectedNode && (
          <Panel position="bottom-left">
            <div className="tw-bg-white tw-rounded-md tw-shadow-sm tw-p-3 tw-text-sm tw-border tw-border-gray-200">
              <div className="tw-font-medium tw-mb-1 tw-text-gray-700">
                Node Position
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-justify-between">
                  <span className="tw-text-gray-600">Node:</span>
                  <span className="tw-font-medium">
                    {selectedNode.data.label}
                  </span>
                </div>
                <div className="tw-flex tw-justify-between">
                  <span className="tw-text-gray-600">X:</span>
                  <span className="tw-font-medium">
                    {Math.round(selectedNode.position.x)}
                  </span>
                </div>
                <div className="tw-flex tw-justify-between">
                  <span className="tw-text-gray-600">Y:</span>
                  <span className="tw-font-medium">
                    {Math.round(selectedNode.position.y)}
                  </span>
                </div>
              </div>
            </div>
          </Panel>
        )}
      </ReactFlow>
    </div>
  );
};

export default ReactFlowMain;
