import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


function VitalStats({data}) {
    
    return (
        <div className="member-engagement mb-3">
            <div className="row text-center px-2">
                <div className="col-12 px-1">
                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                        <div className="card-body p-3">
                            <div className="row">
                                {data.bloodPressure.current && (
                                    <div className="col border-end">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{data.bloodPressure.current} <span>mm Hg</span></h1>
                                                <h5 className="card-text1">Blood Pressure
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Observations currently assigned to the moderator for review.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {data.heartRate.current && (
                                <div className="col border-end">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{data.heartRate.current}  <span>beats/min</span></h1>
                                            <h5 className="card-text1">Pulse
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Observations currently assigned to the moderator for review.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                )}

                                {data.spO2.current && (
                                <div className="col-auto border-end">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{data.spO2.current}%</h1>
                                            <h5 className="card-text1">Oxygen
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Observations requiring input or action from the business team.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                )}

                                {data.bloodGlucose.current && (
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{data?.bloodGlucose?.current} <span>mg / dL</span></h1>
                                            <h5 className="card-text1">Blood Sugar
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Observations under the GOQii's queue for resolution.</Tooltip>}
                                                >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VitalStats; 