import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackendDrivenDataTable from '../cn/components/common/BackendDrivenDataTable';
import { chatbotFlowsData } from './dummyData';

const ChatbotFlowsTab = () => {
  const navigate = useNavigate();

  // Define columns for the table with a view action button
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => row.status.text,
      sortable: true,
      cell: (row) => (
        <div className={`tw-px-3 tw-py-1 tw-rounded-md tw-text-sm tw-inline-block ${
          row.status.type === 'sent' ? 'tw-bg-green-100 tw-text-green-800' : 'tw-bg-gray-100 tw-text-gray-600'
        }`}>
          {row.status.text}
        </div>
      )
    },
    {
      name: "Created by",
      selector: (row) => row.createdBy,
      sortable: true
    },
    {
      name: "Created on",
      selector: (row) => row.createdOn,
      sortable: true
    },
    {
      name: "Actions",
    //   selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <button 
          className="tw-bg-[#03335B] hover:tw-bg-[#1279BE] tw-border-none tw-text-white tw-px-3 tw-py-1 tw-rounded-md tw-text-xs tw-transition-all tw-duration-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleViewFlow(row.id);
          }}
        >
          View Flow
        </button>
      )
    }
  ];

  // State for table pagination and sorting
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleViewFlow = (flowId) => {
    navigate(`/automation-chatbot-dashboard/flow/${flowId}`);
  };

  return (
    <div className="tw-bg-white tw-p-4 tw-rounded-md tw-shadow-sm">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h5 className="tw-text-gray-700 tw-font-medium">Chatbot Flows</h5>
        {/* <button className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-md tw-text-sm">
          + Create New Flow
        </button> */}
      </div>
      
      <BackendDrivenDataTable
        data={chatbotFlowsData}
        totalRows={chatbotFlowsData.length}
        columns={columns}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    </div>
  );
};

export default ChatbotFlowsTab;