import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const WhatsAppAnalyticsPie = (props) => {
  // Pie chart data

  const pieData = props.series.map(item => ({
    name: item.name,
    y: item.y,
    label: item.label,
    
  }));

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 350,
      options3d: {
          enabled: true,
          alpha: 70,
          beta: 0,
          depth: 50,
          viewDistance: 0
      },
    },
    title: "    ",
    subtitle: null,
    tooltip: {
       pointFormat: '{point.name}: <b>{point.label} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 500,
        // colors: props.series.map(item => item.color), // Add this line if you want to enforce color order
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: 5,
          alignTo: 'toPlotEdges'
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Status',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={pieOptions}
      />
    </div>
  );
};

export default WhatsAppAnalyticsPie;
