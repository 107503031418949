import React from "react";
import { Modal } from "react-bootstrap";

const DeleteHealthTask = ({
  showDeleteModal,
  cancelDeleteTask,
  confirmDeleteTask,
  taskToDelete,
}) => {
  return (
    <Modal show={showDeleteModal} onHide={cancelDeleteTask} centered>
      <Modal.Header className="tw-flex-row" closeButton>
        <Modal.Title className="tw-text-lg tw-mt-0 tw-w-[90%] tw-text-center tw-pl-[10%]">
          Confirm Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-0">
        <p className="tw-text-center">
          Are you sure you want to delete task:
          <span className="fw-bold d-block">
            {taskToDelete.task?.description}
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer className="tw-border-0 tw-justify-center">
        <button className="btn btn-secondary" onClick={cancelDeleteTask}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={confirmDeleteTask}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteHealthTask;
