import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import moment from "moment";
import CalendarDatePicker from "../componenents/CalendarDatePicker";
import { decryptData } from "../utils/Utils";
import ChatbotFlowsTab from "./ChatbotFlowTab";

const AutomationChatbotDashboard = () => {
  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );
  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="container-fluid tw-mb-16">
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full tw-pb-3">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4 className="tw-text-gray-600 tw-m-0 tw-font- tw-font-semibold">
              Automation Chatbot
            </h4>
            <div className="tw-w-[40%] tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <select className="form-select input-field-ui tw-max-w-52">
                <option value="All">All</option>
                <option value="example1">Example 1</option>
                <option value="example2">Example 2</option>
              </select>
              <CalendarDatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center tw-bg-white tw-p-4 tw-mx-0.5 tw-rounded-xl tw-mb-4">
        <div class="col-md-auto">
          <img
            src="https://appcdn.goqii.com/storeimg/93171_1740565738.png"
            alt="Invited Members icon"
            style={{ height: "140px" }}
          />
        </div>
        <div class="col-md">
          <p class="mb-0">
          Automate WhatsApp interactions with predefined chatbot workflows. Set up auto-replies to handle customer queries and guide users through structured conversations. Ensure quick responses, efficient support, and seamless engagement. Streamline business communication without manual intervention.
          </p>
        </div>
      </div>
      <div className="whatsapp-templates">
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <TabList>
                <Tab>Chatbot Flows</Tab>
              </TabList>
              <TabPanel><ChatbotFlowsTab /></TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationChatbotDashboard;
