import React, { useState, useEffect } from "react";
import "./TopFilter.css";
import { FiRefreshCcw } from "react-icons/fi";
import { BsGrid } from "react-icons/bs";
import { TfiLayoutListThumb } from "react-icons/tfi";
import { MultiSelect } from "react-multi-select-component";
import { fetch_all_conditions, fetch_vital_alert_types } from "../../../services/rpm/Service";


function TopFilter({ onSwitchChange, onSearch, onRefresh, fetchAllUsers, getAlertStats, selected, setSelected }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [conditions, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState("");
  const [vitalAlertTypes, setVitalAlertTypes] = useState([]);
  // Handle the switch change and notify the parent component
  const handleSwitchChange = (event) => {
    onSwitchChange(event.target.checked); // Pass the switch state to parent
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    if (onSearch) {
      // onSearch(searchTerm);
      fetchAllUsers(1, 12, searchTerm, selectedCondition, selected);
      getAlertStats(searchTerm, selectedCondition, selected);
    }
  };

  // Handle refresh button click
  const handleRefresh = () => {
    setSearchTerm(""); // Clear search term
    if (onRefresh) {
      onRefresh();
    }
  };

  useEffect(() => {
    getConditions();
    getVitalAlertTypes();
  }, []);


  function getConditions() {
    fetch_all_conditions().then((response) => {
      setConditions(response.data.data?.conditions);
    }).catch((error) => {
      console.log(error);
    });
  }

  function getVitalAlertTypes() {
    fetch_vital_alert_types().then((response) => {
      setVitalAlertTypes(response.data.data?.data);
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-2 mb-3 pe-0">
          <label htmlFor="allInformation" className="form-label sub-title">
            Care Program State
          </label>
          <select
            className="form-select frame-select"
            id="allInformation"
            aria-label="Default select example"
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e.target.value)}
          >
            <option defaultValue={""}>All Selected</option>
            <option value="" disabled>
              Select Condition
            </option>
            {conditions.map((condition) => (
              <option value={condition.conditionId}>{condition.conditionName}</option>
            ))}
          </select>
        </div>

        <div className=" tw-w-1/3 tw-pr-0">
          <label htmlFor="allInformation" className="form-label sub-title">
            Filter Vital Alerts
          </label>
          <MultiSelect
            options={vitalAlertTypes}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
            hasSelectAll={true}
            overrideStrings={{
              selectSomeItems: "Filter by vital type...",
              allItemsAreSelected: "All vitals selected",
              selectAll: "Select All",
              search: "Search",
            }}
            className="tw-py-1 tw-px-2 form-select input-field-ui"
          />
        </div>

        <div className="col-md-3 mb-3 pe-0">
          <label htmlFor="allInformation" className="form-label sub-title">
            Search
          </label>
          <div className="form-group" style={{ width: "100%" }}>
            <input
              type="text"
              id="searchInput"
              placeholder="Search By Name, Member ID"
              className="form-control input-field-ui ps-5 tw-py-2.5"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={(e) => e.key === "Enter" && handleSearch()}
            />
            <img
              src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
              className="input-icon-seacrh"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-auto px-1 tw-ml-2" style={{ paddingTop: "32px" }}>
          <button
            className="btn btn-primary clear-search-btn"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <div className="col-md tw-mt-[10px] text-end tw-flex tw-place-content-end tw-items-center">
          <button
            type="button"
            className="btn icon-filters"
            onClick={handleRefresh}
          >
            <FiRefreshCcw />
            Refresh
          </button>

          <div className="form-switch ps-4">
            <div className="row">
              <label
                htmlFor="flexSwitchCheckChecked"
                className="col px-0 text-end"
              >
                <BsGrid />
              </label>
              <div className="col px-1">
                <input
                  className="form-check-input mx-0"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  onChange={handleSwitchChange} // Update the switch state on change
                />
              </div>
              <label
                htmlFor="flexSwitchCheckChecked"
                className="col px-0 text-start"
              >
                <TfiLayoutListThumb />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopFilter;
