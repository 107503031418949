import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const ViewHealthTaskModal = ({
  showViewModal,
  closeViewModal,
  selectedTask,
  currentMonth,
  setCurrentMonth,
}) => {
  const [calendarDays, setCalendarDays] = useState([]);

  const goToPreviousMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));
  };

  const goToNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, "month"));
  };

  const generateCalendar = (month) => {
    const startDay = month.clone().startOf("month").startOf("week");
    const endDay = month.clone().endOf("month").endOf("week");

    const calendar = [];
    const day = startDay.clone();

    while (day.isBefore(endDay, "day")) {
      calendar.push({
        date: day.clone(),
        isCurrentMonth: day.month() === month.month(),
        isToday: day.isSame(moment(), "day"),
        // Simulating followed days - in a real app, you'd check against actual data
        followed: Math.random() > 0.6, // Randomly mark 40% of days as followed
      });
      day.add(1, "day");
    }

    return calendar;
  };

  useEffect(() => {
    if (showViewModal) {
      setCalendarDays(generateCalendar(currentMonth));
    }
  }, [currentMonth, showViewModal]);

  return (
    <Modal show={showViewModal} onHide={closeViewModal} centered size="md">
      <Modal.Header className="tw-flex-row" closeButton>
        <Modal.Title className="tw-text-lg tw-mt-0 tw-w-[90%] tw-text-center tw-pl-[10%]">
          {selectedTask?.description}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pb-0 tw-pt-0">
        {/* Percentage Circle */}
        <div className="tw-flex tw-justify-center tw-mb-2">
          <div className="tw-relative tw-w-28 tw-h-28">
            <svg
              className="tw-w-28 tw-h-28 tw-transform tw-rotate-[-90deg] tw-scale-90"
              viewBox="0 0 100 100"
            >
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="#f3f3f3"
                strokeWidth="5"
              />
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="#f59e0b"
                strokeWidth="5"
                strokeDasharray="283"
                strokeDashoffset={283 - (283 * 35) / 100}
                strokeLinecap="round"
              />
            </svg>
            <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center">
              <span className="tw-text-2xl tw-font-bold">35%</span>
              <span className="tw-text-xs tw-text-gray-500">FOLLOWED</span>
            </div>
          </div>
        </div>

        {/* Stats */}
        <div className="tw-flex tw-justify-between tw-text-center tw-mb-3">
          <div className="tw-flex-1">
            <p className="tw-text-gray-500 tw-text-sm tw-mb-1">FOLLOWED</p>
            <p className="tw-text-lg tw-font-bold">
              {calendarDays.filter((day) => day.followed).length} days
            </p>
          </div>
          <div className="tw-flex-1">
            <p className="tw-text-gray-500 tw-text-sm tw-mb-1">NOT FOLLOWED</p>
            <p className="tw-text-lg tw-font-bold">
              {calendarDays.filter((day) => !day.followed).length} days
            </p>
          </div>
          <div className="tw-flex-1">
            <p className="tw-text-gray-500 tw-text-sm tw-mb-1">Total</p>
            <p className="tw-text-lg tw-font-bold">
              {calendarDays.length} days
            </p>
          </div>
        </div>

        {/* Calendar */}
        <div className="tw-mb-3">
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
            <button
              className="tw-p-2 tw-border-none tw-rounded-full"
              onClick={goToPreviousMonth}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <h5 className="tw-text-md tw-font-medium">
              {currentMonth.format("MMMM YYYY")}
            </h5>
            <button
              className="tw-p-2 tw-border-none tw-rounded-full"
              onClick={goToNextMonth}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>

          <table className="tw-w-full tw-text-center">
            <thead>
              <tr>
                <th className="tw-p-1">M</th>
                <th className="tw-p-1">T</th>
                <th className="tw-p-1">W</th>
                <th className="tw-p-1">T</th>
                <th className="tw-p-1">F</th>
                <th className="tw-p-1">S</th>
                <th className="tw-p-1">S</th>
              </tr>
            </thead>
            <tbody>
              {/* Create rows for each week */}
              {Array.from({ length: Math.ceil(calendarDays.length / 7) }).map(
                (_, weekIndex) => (
                  <tr key={weekIndex}>
                    {calendarDays
                      .slice(weekIndex * 7, weekIndex * 7 + 7)
                      .map((day, dayIndex) => (
                        <td
                          key={dayIndex}
                          className={`tw-p-1 ${
                            !day.isCurrentMonth ? "tw-text-gray-400" : ""
                          } ${
                            day.isToday
                              ? "tw-font-bold tw-bg-blue-100 tw-rounded"
                              : ""
                          } ${day.followed ? "tw-bg-green-100" : ""}`}
                        >
                          {day.date.date()}
                        </td>
                      ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        {/* Streak Info */}
        <div className="tw-flex tw-justify-between border-top tw-pt-4">
          <div className="tw-text-center">
            <p className="tw-text-gray-500 tw-text-sm tw-mb-0">
              CURRENT STREAK
            </p>
            <p className="tw-text-lg tw-font-bold tw-mb-0">0 days</p>
          </div>
          <div className="tw-text-center">
            <p className="tw-text-gray-500 tw-text-sm tw-mb-0">
              LONGEST STREAK
            </p>
            <p className="tw-text-lg tw-font-bold tw-mb-0">33 days</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-border-0 tw-justify-center">
        <button
          className="action-button blue-btn tw-py-3 tw-px-4"
          onClick={closeViewModal}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewHealthTaskModal;
