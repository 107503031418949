import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetch_vital_graphs } from '../../../../services/rpm/Service';
export const VitalsContext = createContext();


// create a custom hook to use the VitalsContext
export const useVitals = () => {
    const context = useContext(VitalsContext);
    if (!context) {
        throw new Error('useVitals must be used within a VitalsProvider');
    }
    return context;
};

// create a provider to wrap the app with the VitalsContext
export const VitalsProvider = ({ children }) => {
    const [memberName, setMemberName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [vitalsData, setVitalsData] = useState({
        heartRate: {
            current: "",
            history: []
        },
        bodyTemperature: {
            current: "",
            history: []
        },
        spO2: {
            current: "",
            history: []
        },
        bmi: {
            current: ""
        },
        bloodGlucose: {
            current: "",
            type: "Fasting",
            history: []
        },
        bloodPressure: {
            current: "",
            history: []
        }
    });

    const [memberId, setMemberId] = useState(null);


    // fetch the vital graphs data
    function fetchVitalGraphsData(memberId) {
        setIsLoading(true);
        fetch_vital_graphs({
            userId: memberId
        }).then(response => {
            if (response.data && response.data.data) {
                const apiData = response?.data?.data?.data;
                setMemberName(response?.data?.data?.memberName);
                // Build up new vitals data object
                const newVitalsData = {...vitalsData};  

                // SpO2
                if (apiData.spo2 && apiData.spo2.length > 0) {
                    newVitalsData.spO2 = {
                        current: apiData.spo2[0].paramVal.toString(),
                        history: apiData.spo2.map(item => ({
                            date: item.createdTime,
                            value: parseFloat(item.paramVal)
                        }))
                    };
                }

                // Blood Pressure
                if (apiData.bp && apiData.bp.length > 0) {
                    newVitalsData.bloodPressure = {
                        current: apiData.bp[0].paramVal,
                        history: apiData.bp.map(item => {
                            const [systolic, diastolic] = item.paramVal.split('/');
                            return {
                                date: item.createdTime,
                                systolic: parseInt(systolic),
                                diastolic: parseInt(diastolic)
                            };
                        })
                    };
                }

                // Body Temperature
                if (apiData.bt && apiData.bt.length > 0) {
                    newVitalsData.bodyTemperature = {
                        current: apiData.bt[0].paramVal,
                        history: apiData.bt.map(item => ({
                            date: item.createdTime,
                            value: parseFloat(item.paramVal)
                        }))
                    };
                }

                // Heart Rate
                if (apiData.pr && apiData.pr.length > 0) {
                    newVitalsData.heartRate = {
                        current: apiData.pr[0].paramVal,
                        history: apiData.pr.map(item => ({
                            date: item.createdTime,
                            value: parseInt(item.paramVal)
                        }))
                    };
                }

                // BMI
                if (apiData.bmi && apiData.bmi.length > 0) {
                    newVitalsData.bmi = {
                        current: apiData.bmi[0].paramVal ? apiData.bmi[0].paramVal.toString() : ""
                    };
                }

                // Blood Glucose
                if (apiData.bglf && apiData.bglf.length > 0) {
                    newVitalsData.bloodGlucose = {
                        current: apiData.bglf[0].paramVal,
                        type: "Fasting",
                        history: apiData.bglf.map(item => ({
                            date: item.createdTime,
                            value: parseInt(item.paramVal)
                        }))
                    };
                }

                // blood glucose random
                if (apiData.bgpp && apiData.bgpp.length > 0) {
                    newVitalsData.bloodGlucoseRandom = {
                        current: apiData.bgpp[0].paramVal,
                        type: "PP/Random",
                        history: apiData.bgpp.map(item => ({
                            date: item.createdTime,
                            value: parseInt(item.paramVal)
                        }))
                    };
                }
                
                console.log(newVitalsData, "newVitalsData");
                // Update state once with all changes
                setVitalsData(newVitalsData);
            }
        }).catch(error => {
            console.error('Error fetching vital graphs data:', error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (memberId) {
            fetchVitalGraphsData(memberId);
        }
    }, [memberId]);

    const updateVitalsData = (vitalType, newData) => {
        setVitalsData(prev => ({
            ...prev,
            [vitalType]: {
                ...prev[vitalType],
                ...newData
            }
        }));
    };

    const value = {
        vitalsData,
        updateVitalsData,
        memberId,
        setMemberId,
        memberName,
        isLoading
    };

    return (
        <VitalsContext.Provider value={value}>
            {children}
        </VitalsContext.Provider>
    );
}; 