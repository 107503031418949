import React, { useState, useEffect, useRef } from "react";
import BackendDrivenDataTable from "../../cn/components/common/BackendDrivenDataTable";

const AnalyticsTable = ({ userDataGraph, totalRowData, fetchUserChatData, startDate, endDate, setCurrentPage, currentPage, setPerPage, perPage }) => {
  console.log("userDataGraph:", userDataGraph);

  // State for sorting, pagination, and search
  const [sortColumn, setSortColumn] = useState("senderId");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  
  // Use a ref to track if the effect is running for the first time
  const isInitialMount = useRef(true);
  // Use a ref to track if a page change is in progress
  const isChangingPage = useRef(false);

  // Use effect to update the data when userDataGraph changes
  useEffect(() => {
    if (userDataGraph && Array.isArray(userDataGraph)) {
      setFilteredData(userDataGraph);
    }
  }, [userDataGraph]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // This effect handles the data fetching with proper page tracking
  useEffect(() => {
    // Skip the initial render to prevent double fetching
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const fetchData = () => {
      let start = formatDate(startDate);
      let end = formatDate(endDate);
      
      console.log(`Fetching data for page ${currentPage}, perPage ${perPage}`);
      
      // Use the closure values at the time of this function call
      fetchUserChatData(start, end);
    };

    // Only fetch if we're intentionally changing pages or other parameters
    if (isChangingPage.current || searchTerm === "") {
      fetchData();
      isChangingPage.current = false;
    }
  }, [currentPage, perPage, startDate, endDate]);

  // Search functionality
  useEffect(() => {
    if (userDataGraph && Array.isArray(userDataGraph)) {
      const filtered = userDataGraph.filter((item) =>
        String(item.senderId).toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      
      // Only reset to page 1 if we're actually searching (not on initial load)
      if (searchTerm !== "" && !isInitialMount.current) {
        setCurrentPage(1);
      }
    }
  }, [searchTerm, userDataGraph]);

  // Handle search input
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Clear search input
  const handleClear = () => {
    setSearchTerm("");
    setCurrentPage(1);
    setSortColumn("senderId");
    setSortDirection("asc");
    
    // Signal that we want to fetch data
    isChangingPage.current = true;
  };

  // Custom page change handler to mark that we're changing pages
  const handlePageChange = (page) => {
    if (page !== currentPage) {
      isChangingPage.current = true;
      setCurrentPage(page);
    }
  };

  // Custom perPage change handler
  const handlePerPageChange = (newPerPage) => {
    if (newPerPage !== perPage) {
      isChangingPage.current = true;
      setPerPage(newPerPage);
    }
  };

  // Export to CSV functionality
  const handleExport = () => {
    if (filteredData.length === 0) return;
    
    const headers = columns.map((col) => col.name).join(",");
    const rows = filteredData.map((row) =>
      columns
        .map((col) => {
          const value = col.selector(row);
          return typeof value === "string" && value.includes(",")
            ? `"${value}"`
            : value;
        })
        .join(",")
    ).join("\n");

    const csvContent = `${headers}\n${rows}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `analytics_report_${new Date().toISOString().split("T")[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Column definitions
  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName || "N/A",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.team || "N/A",
      sortable: true,
    },
    {
      name: "Messages Sent",
      selector: (row) => row.totalMessagesSent || 0,
      sortable: true,
    },
    {
      name: "Messages Delivered",
      selector: (row) => row.totalMessagesDelivered || 0,
      sortable: true,
    },
    {
      name: "Messages Read",
      selector: (row) => row.totalMessagesRead || 0,
      sortable: true,
    },
    {
      name: "Messages Failed",
      selector: (row) => row.totalMessagesUndelivered || 0,
      sortable: true,
    },
    {
      name: "Pending Responses",
      selector: (row) => row.pendingResponses || 0,
      sortable: true,
    },
    {
      name: "Avg Response Time",
      selector: (row) => row.totalAvgResponseTime || "N/A",
      sortable: true,
    },
  ];

  return (
    <div className="pt-4">
      {/* Search and Actions */}
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4 tw-mb-6">
        <div className="form-group" style={{ width: "50%" }}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by Sender ID"
            className="form-control input-field-ui ps-5"
          />
          <img
            src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
            className="input-icon-seacrh"
            alt="search"
          />
        </div>

        <div className="tw-flex tw-gap-4">
          <button className="add-new-program-btn tw-min-w-24" onClick={handleClear}>
            Clear
          </button>
          <button className="add-new-program-btn tw-min-w-24" onClick={handleExport}>
            <i className="bi bi-download tw-mr-2"></i> Export
          </button>
        </div>
      </div>

      {/* Data Table */}
      <BackendDrivenDataTable
        data={filteredData}
        columns={columns}
        totalRows={totalRowData}
        perPage={perPage}
        page={currentPage}
        setPage={handlePageChange}  // Use custom handler
        setPerPage={handlePerPageChange}  // Use custom handler
        fetchUserChatData={fetchUserChatData}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    </div>
  );
};

export default AnalyticsTable;