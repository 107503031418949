import React, { useState, useEffect, useRef } from "react";
import { Video } from 'lucide-react';
import {
  fetch_program_data,
  fetch_single_frequency_configuration_template,
  fetch_whatsapp_template_data,
  frequency_configuration_template_creation,
  single_whatsapp_template,
  whatsapp_template_details_save,
  fetch_category_and_services_data,
  fetchWhatsAppTemplateByServiceId,
  fetch_template_by_templateId,
  delete_template_by_templateId,
  fetch_configuration_static_list,
  serviceConfirmationTemplateDelete
} from "../../../services/mtp/Service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Commingsoon, LoaderLoader } from "../../utils/CommonLibrary";
import ContentSMSNew from "../ContentConfiguration/ContentSMSNew";
import { Link } from "react-router-dom";
import VariableMappingRow from "../VariableMappingRow.js"
import ReactPlayer from "react-player";


function ContentConfigurationList() {
  const [programData, setProgramData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [whatsAppTemplate, setWhatsAppTemplateData] = useState([]);
  const [singleWhatsAppTemplateData, setSingleWhatsAppTemplateData] =
    useState("");
  const [singleFeedbackTemplateData, setSingleFeedbackTemplateData] =
    useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedConfirmTemplate, setSelectedConfirmTemplate] = useState(null);
  const [selectedFeedBackTemplate, setSelectedFeedBackTemplate] = useState(null);
  const [selectedFeedBackValue, setSelectedFeedBackValue] = useState("");
  const [templateFrequency, setTemplateFrequency] = useState("");
  const [scheduleSend, setScheduleSend] = useState("Immediate");
  const [templateMessage, setTemplateMessage] = useState("");
  const [currentService, setCurrentService] = useState(null);
  
  const [followUpMessages, setFollowUpMessages] = useState([
    { message: "", frequency: "", fschedule: "", templateId: "", contentVariables:'' },
  ]);
  const [languageOption, setLanguageOption] = useState("english");

  const [errors, setErrors] = useState({});
  const [selectedService, setSelectedService] =useState();
  const [selectedFeedbackSchedule, setSelectedFeedbackSchedule] = useState('');
  const [delayMinutes, setDelayMinutes] = useState('0');
  const [confirmTemplate , setConfirmTemplate]=useState({});
  const [postFeedbackTemplate , setPostFeedbackTemplate]=useState({});
  const [followUpTemplates , setfollowUpTemplate]=useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const [variableMappings, setVariableMappings] = useState([
    { variable: "", value: "", fallback: "" },
  ]);

  const [variableMappingsFeedback, setVariableMappingsFeedback] = useState([
    { variable: "", value: "", fallback: "" },
  ]);
  
  const [variableMappingsFollowUp, setVariableMappingsFollowUp] = useState([]);

  const configruationPopupCloseBtn = useRef(null); 


  useEffect(() => {
    if (followUpTemplates.length > 0) {
      setVariableMappingsFollowUp((prevMappings) => {
        return followUpTemplates?.map((template, index) => {
          if (prevMappings[index] && prevMappings[index].length === template.variablesCount) {
            return prevMappings[index]; 
          }
          return Array.from({ length: template.variablesCount })?.map(() => ({
            variable: "",
            value: "",
            fallback: "",
          }));
        });
      });
    }
  }, [followUpTemplates,languageOption]);
 
  useEffect(() => {
    fetchWhatAppsTemplateData();
    fetch_configuration_static_list();
  }, [languageOption]);

  const fetchWhatAppsTemplateData = async () => {
    try {
      const response = await fetch_whatsapp_template_data(languageOption);
      setWhatsAppTemplateData(response.data.data || []);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleOptionChange = (e) => {
    const value = e.target.value;
    if (value == "1") {setDelayMinutes(1);} 
    if (!value) {setDelayMinutes(0);}
    setSelectedFeedbackSchedule(value); 
  };
  
  const handleChange = async(e) => {
    const selectedTemplateId = e.target.value;
    setSelectedValue(selectedTemplateId);
    const templateDetails = await fetchTemplateDetailsByTemplateId(selectedTemplateId);
    setConfirmTemplate(templateDetails);
    console.log(templateDetails)
    const selectedTemplate = whatsAppTemplate.find(
      (template) => template.templateId == selectedTemplateId
    );
    console.log(selectedTemplate);
    if (selectedTemplate) {
      setSingleWhatsAppTemplateData(selectedTemplate.templateBody);
    } else {
      setSingleWhatsAppTemplateData(''); 
    }
  };

  const handleChangeFeedBack = async (e) => {
    const selectedTemplateId = e.target.value;
    setSelectedFeedBackValue(selectedTemplateId);
    const templateDetails = await fetchTemplateDetailsByTemplateId(selectedTemplateId);
    setPostFeedbackTemplate(templateDetails);
    const selectedTemplate = whatsAppTemplate.find(
      (template) => template.templateId == selectedTemplateId
    );
    console.log(selectedTemplate);
    if (selectedTemplate) {
      setSingleFeedbackTemplateData(selectedTemplate.templateBody);
    } else {
      setSingleFeedbackTemplateData(''); 
    }
  };
  
  const transformAndAppendPayload = (existingArray, initialPayload, variableMappingsFollowUp) => {
  const newTemplates = existingArray?.map((item, index) => {
    const scheduleTime = item?.fschedule && item.fschedule.match(/\d+/)
      ? parseInt(item.fschedule.match(/\d+/)[0])
      : 0; 
    const contentVariables = variableMappingsFollowUp[index] || []; 

    return {
      scheduleStatus: item?.scheduleStatus ? item.scheduleStatus : 'before', 
      scheduleTime,
      templateId: item.templateId,
      contentVariables,
      templateDetailId :item.templateDetailId ? item.templateDetailId :0,
    };
  });

  return [...initialPayload, ...newTemplates];
  };
  
  const saveWhatsappTemplate = async (event) => {
    event.preventDefault();
    console.log("variableMappings",variableMappings);
   let temp = true;
    variableMappings?.forEach(element => {
        if(element.value ==='' || element.fallback ===''){
          temp = false; 
        }
    });
   
    // if(!temp){
    //   setShowErrorMessage("Fallback/Constant Value can not be empty"); 
      
    //   setTimeout(() => {    
    //     setShowErrorMessage(""); 
    //   }, 2000); 
    //   return;
    // }
    const payload=[
      {
        scheduleStatus: 'immediate',
        scheduleTime: 0,
        templateId:selectedValue,
        templateDetailId :selectedConfirmTemplate?.templateDetailId ? selectedConfirmTemplate?.templateDetailId : 0,
        contentVariables:variableMappings,
      },
      {
        scheduleStatus: 'after',
        scheduleTime: delayMinutes,
        templateId: selectedFeedBackValue,
        templateDetailId: selectedFeedBackTemplate?.templateDetailId ? selectedFeedBackTemplate?.templateDetailId : 0,
        contentVariables:variableMappingsFeedback,
      }
    ];
    const updatedPayload = transformAndAppendPayload(followUpMessages, payload,variableMappingsFollowUp);
    try {
      let response = await whatsapp_template_details_save(selectedService,updatedPayload,languageOption);
      if (response.data.code == 200) {
        setErrors({});
        fetchProgramData();
        setSelectedValue();
        setSelectedConfirmTemplate('');
        fetchWhatAppsTemplateData();
        configruationPopupCloseBtn?.current?.click(); 
      } else {
        setTemplateMessage([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
    
  };

  const handleFrequencyChange = (event) => {
    const value = event.target.value;
    setTemplateFrequency(value);
  };

  const handleScheduleSendChange = (event) => {
    const value = event.target.value;
    setScheduleSend(value);
  };

  const handleFollowUpMessageChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].message = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  const handleFollowUpFrequencyChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].frequency = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  const handleFollowUpScheduleChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].fschedule = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  
  // const handleFollowUpTemplateChange = async (index, event) => {
  //   const newFollowUpMessages = [...followUpMessages];
  //   const templateId = event.target.value;
  //   newFollowUpMessages[index].templateId = templateId;

  //   try {
  //     setIsLoading(true);
  //     const resp = await single_whatsapp_template(templateId);
  //     if (resp.data.code === 200) {
  //       newFollowUpMessages[index].message =
  //         resp.data.data[0].templateName || "";
  //     } else {
  //       newFollowUpMessages[index].message = "";
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   setFollowUpMessages(newFollowUpMessages);
  // };

  const handleFollowUpTemplateChange = async (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    const templateId = event.target.value;
    newFollowUpMessages[index].templateId = templateId;
  
    // Fetch the selected template from the list
    const selectedTemplate = whatsAppTemplate.find(
      (template) => template.templateId === templateId
    );
  
    // Fetch the template details from API
    const templateDetails = await fetchTemplateDetailsByTemplateId(templateId);
  
    // Update followUpTemplates with the fetched template details
    const updatedFollowUpTemplates = [...followUpTemplates];
    updatedFollowUpTemplates[index] = templateDetails;
  
    // Preserve the existing variableMappingsFollowUp for that index
    const updatedVariableMappings = [...variableMappingsFollowUp];
    
    // If mappings for the current index already exist, keep them,
    // otherwise, initialize them based on the new template's variables count
    if (!updatedVariableMappings[index]) {
      updatedVariableMappings[index] = Array.from(
        { length: templateDetails.variablesCount }
      )?.map(() => ({
        variable: "", 
        value: "", 
        fallback: ""
      }));
    }
  
    // Update message body
    if (selectedTemplate) {
      newFollowUpMessages[index].message = selectedTemplate.templateBody || "";
    } else {
      newFollowUpMessages[index].message = ""; 
    }
  
    console.log(updatedFollowUpTemplates)

    setFollowUpMessages(newFollowUpMessages);
    setfollowUpTemplate(updatedFollowUpTemplates);
    setVariableMappingsFollowUp(updatedVariableMappings);
  };

  const addFollowUpMessage = () => {
    setFollowUpMessages([
      ...followUpMessages,
      { message: "", frequency: "", fschedule: "", templateId: "" , contentVariables:""},
    ]);
  };

  const removeFollowUpMessage = (index) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages.splice(index, 1);
    setFollowUpMessages(newFollowUpMessages);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!selectedValue) {
      newErrors.selectedValue = "Template selection is required";
    }

    if (singleWhatsAppTemplateData.trim().length < 10) {
      newErrors.singleWhatsAppTemplateData =
        "WhatsApp message is required.";
    }

    // if (!templateFrequency) {
    //   newErrors.templateFrequency = "Template frequency is required";
    // }

    if (!scheduleSend) {
      newErrors.scheduleSend = "Schedule is required.";
    }

    followUpMessages.forEach((followUp, index) => {
      if (followUp.message.trim().length < 2) {
        newErrors[`followUpMessage${index}`] = `Follow-up message ${
          index + 1
        } is required.`;
      }
    //   if (!followUp.frequency) {
    //     newErrors[
    //       `followUpFrequency${index}`
    //     ] = `Frequency for follow-up message ${index + 1} is required`;
    //   }
      if (!followUp.fschedule) {
        newErrors[
          `followUpSchedule${index}`
        ] = `Schedule follow-up message ${index + 1} is required`;
      }
      if (!followUp.templateId) {
        newErrors[
          `followUpTemplate${index}`
        ] = `Template selection for follow-up message ${index + 1} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  const templateSelection = async (templateId) => {
    try {
      setIsLoading(true);
      const resp = await single_whatsapp_template(templateId);
      if (resp.data.code === 200) {
        setSingleWhatsAppTemplateData(resp.data.data[0].templateName || "");
      } else {
        setSingleWhatsAppTemplateData("");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const formData = {
      templateId: selectedValue,
      message: singleWhatsAppTemplateData,
      frequency: templateFrequency,
      scheduleSend: scheduleSend,
      followUpMessages: followUpMessages,
      labelValue: 1,
      labelDepth: 0,
      labelApoinment: 1,
      type: "whatsApp",
    };
  
    try {
      // Submit the formData to your backend service here
      let result = await frequency_configuration_template_creation(formData);
      if (result.data.code === 200) {
        setTemplateMessage(result.data.data.message || []);
        setSelectedValue();
        fetchProgramData();
        setSingleWhatsAppTemplateData("");
        setTemplateFrequency("");
        setScheduleSend("");
        setFollowUpMessages([
          { message: "", frequency: "", fschedule: "", templateId: "" },
        ]);
        setErrors({});
      } else {
        setTemplateMessage([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  useEffect(() => {
    fetchProgramData();
  }, [languageOption]);

  // fetching program data
  async function fetchProgramData() {
    try {
      let resp = await fetch_configuration_static_list();
      setIsLoading(true);
      if (resp.data.code === 200) {
        setIsLoading(false); 
        setProgramData(resp.data.data);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false); 
    }
  }

      // Function to transform content variables array
    const transformVariableMappings = (contentVariables) => {
      return contentVariables?.map((item, index) => ({
        variable: index,
        value: item.field,
        fallback: item.fallback, 
      }));
    };

    // fetching templateDetails data
    async function fetchTemplateDetailsBySId(serviceId) {
      try {
        let resp = await fetchWhatsAppTemplateByServiceId(serviceId,languageOption);
        setIsLoading(true);
        if (resp.data.code === 200) {
          setIsLoading(false); 
          const transformedData = resp?.data?.data?.reminders?.map((item) => ({
            frequency: "",
            fschedule: `before ${item.scheduleTime} mins`,
            message: item.templateBody,
            templateId: item.templateId,
            templateDetailId :  item.templateDetailId,
          }));

          const reminders = resp?.data?.data?.reminders || [];

          const contentVariablesMappings = reminders?.map((item) => {
            return transformVariableMappings(item.contentVariables);
          });

          const followUpTemplates = await Promise.all(
            reminders?.map(async (item) => {
              const templateDetails = await fetchTemplateDetailsByTemplateId(item.templateId);
              return {
                ...templateDetails,  
                frequency: "",
                fschedule: `before ${item.scheduleTime} mins`,
                message: item.templateBody,
                templateId: item.templateId,
                templateDetailId: item.templateDetailId,
              };
            })
          );
    
          setfollowUpTemplate(followUpTemplates);
          setVariableMappingsFollowUp(contentVariablesMappings);
          setFollowUpMessages(transformedData);

          // state of confirmation message
          setSelectedValue(resp?.data?.data?.serviceConfirmation?.templateId);
          setSingleWhatsAppTemplateData(resp?.data?.data?.serviceConfirmation?.templateBody);
          setSelectedConfirmTemplate(resp?.data?.data?.serviceConfirmation);
          const contentVariables = resp?.data?.data?.serviceConfirmation?.contentVariables;
          const transformedVariableMappings = transformVariableMappings(contentVariables);
          setVariableMappings(transformedVariableMappings);
          const templateDetails = await fetchTemplateDetailsByTemplateId(resp?.data?.data?.serviceConfirmation?.templateId);
          setConfirmTemplate(templateDetails);

          // States fr feedback value
          setSelectedFeedBackValue(resp?.data?.data?.postFeedback?.templateId);
          setSingleFeedbackTemplateData(resp?.data?.data?.postFeedback?.templateBody);
          setSelectedFeedBackTemplate(resp?.data?.data?.postFeedback);
          const contentfeedVariables = resp?.data?.data?.postFeedback?.contentVariables;
          const transformedFeedbackVariableMappings = transformVariableMappings(contentfeedVariables);
          setVariableMappingsFeedback(transformedFeedbackVariableMappings);
          const templateFeed = await fetchTemplateDetailsByTemplateId(resp?.data?.data?.postFeedback?.templateId);
          setPostFeedbackTemplate(templateFeed);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
 
    async function fetchTemplateDetailsByTemplateId(templateId) {
      try {
        setIsLoading(true);
        let resp = await fetch_template_by_templateId(templateId,languageOption);
        
        if (resp.data.code === 200) {
          setIsLoading(false);
          return resp?.data?.data; 
        } else {
          setIsLoading(false);
          return null; 
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        return null;
      }
    }
  
  async function getTemplates(templateType, programId) {
    setSelectedService(programId);
    try {
      setIsLoading(true);
      setTemplateMessage([]);
      setSelectedValue();
      setSingleWhatsAppTemplateData("");
      setTemplateFrequency("");
      setScheduleSend("");
      setFollowUpMessages([
        { message: "", frequency: "", fschedule: "", templateId: "" },
      ]);
      setErrors({});
      const response = await fetch_single_frequency_configuration_template({
        templateType,
        programId,
      });

      if (response.data.code === 200) {
        setIsLoading(false);
        setTemplates(response.data.data);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  const getAvailableScheduleOptions = (index) => {
    const selectedSchedules = followUpMessages?.map((fu, i) => 
      i !== index ? fu.fschedule : null);
    const allOptions = ["before 15 mins", "before 30 mins", "before 60 mins"];

    const currentSchedule = followUpMessages[index]?.fschedule;
    return allOptions.filter(option => 
      !selectedSchedules.includes(option) || option === currentSchedule
    );
  };
  
  const allTimesAssigned = getAvailableScheduleOptions().length === 0;
  const [expandedRow, setExpandedRow] = useState(0);

  const toggleRowExpansion = (index) => {
      setExpandedRow(prevState => (prevState === index ? null : index));
  };

  const handleDeletefollowup = async (index, templateId) => {
    try {
      setIsLoading(true);
      if(templateId != undefined){
          let resp = await delete_template_by_templateId(templateId);
          if (resp.data.code === 200) {
            setIsLoading(false);
            fetchProgramData();
            await fetchTemplateDetailsBySId(currentService.serviceId);
          } else {
            setIsLoading(false);
            setfollowUpTemplate([]);
            return null; 
          }
      }else{
        setFollowUpMessages((messages, ind) => { 
          return  messages.filter((item, ind) => ind !== index); 
      })
        setIsLoading(false);
      }
    } catch (error) {
      
      console.error(error);
      setIsLoading(false);
      return null;
    }
  };
  

  const mappings = [
    { variable: "{{name}}", value: "dynamic", fallbackValue: "John Doe" },
    { variable: "{{email}}", value: "constant", fallbackValue: "example@example.com" },
    { variable: "{{phone}}", value: "dynamic", fallbackValue: "123-456-7890" },
    { variable: "{{address}}", value: "", fallbackValue: "123 Main St" },
    { variable: "{{date}}", value: "constant", fallbackValue: "01-01-2024" },
  ];

  const handleLanguageChange = (e) => {
    setLanguageOption(e.target.value);
    console.log("languageOption",e.target.value);
  };
  
  const handleVariableMappingChange = (templateIndex, newMapping) => {
    const updatedMappings = [...variableMappingsFollowUp];
    updatedMappings[templateIndex] = newMapping;
    setVariableMappingsFollowUp(updatedMappings); 
  };

  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

    //  console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  const handleSingleTemplate =async () =>{
    try{
      let resp = await serviceConfirmationTemplateDelete();
      setIsLoading(true);
      if(resp.data.code === 200){
        setSelectedValue("");
        setSingleWhatsAppTemplateData("");
        setIsLoading(false);
      }
    }catch(error){
      console.log(error);
    }
  }

  
  return (
    <>
      <div className="row align-items-center pb-3 sticky-top-bar">
        <div className="col-md">
          <h4 className="mb-0 content-main-title">
            Configure Service Communications
          </h4>
        </div>
        {/* <div className="col-md text-end">
          <Link to="" className="btn btn-primary add-new-program-btn">
            <i className="bi bi-card-checklist me-2"></i>
          </Link>
        </div> */}
      </div>
      <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3jbh8/58g4l8s8ca.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Communication Configuration module allows you to easily manage all communications sent to members for various events through multiple channels like Email, SMS, WhatsApp, and App Push Notifications. This is crucial for engaging members effectively.</p>
                        </div>
                    </div>
      </div>
      
      <div className="container-fluid px-0 mb-3">
        <div className="content-ContentConfigurationLis mb-3">
          <div className="row">
            <div className="col-md-12">
              <div className="body-tree-view-configuration">
                <LoaderLoader isLoading={isLoading} />
                {/* Configuration List 1 */}
                {programData?.map((firstItem, index) => (
                  <details open key={index}>
                    <summary className="tw-flex tw-items-center tw-py-2 tw-px-4 tw-bg-gray-100 tw-rounded-lg">
            <span className="tw-w-2/5 tw-font-medium">{firstItem.categoryName}</span>
           
            <a
                                  className="btn btn-primary whatsApp-message-btn me-2"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ConfigurationListModal"
                                  onClick={(e) => {
                                    setCurrentService(firstItem);
                                    getTemplates(
                                      "whatsapp",
                                      firstItem.serviceId
                                    );
                                    if (firstItem.whatsAppCount > 0) {
                                      fetchTemplateDetailsBySId(
                                        firstItem.serviceId
                                      );
                                    } else {
                                      setSelectedFeedBackValue("");
                                      setSingleFeedbackTemplateData("");
                                      setSingleWhatsAppTemplateData("");
                                      setSelectedValue("");
                                    }
                                  }}
                                >
                                  <i className="bi bi-whatsapp me-1"></i>{" "}
                                  ({firstItem.whatsAppCount}) WhatsApp message
                                </a>
          </summary>
          </details>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Commingsoon />

      <div
        className="modal fade"
        id="ConfigurationListModal"
        tabIndex="-1"
        aria-labelledby="ConfigurationListModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-subtitle mb-0">
              {currentService?.categoryName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setExpandedRow(0)}
              ></button>
            </div>
            {templateMessage ? (
              <p style={{ color: "green", textAlign: "center" }}>
                {templateMessage}
              </p>
            ) : (
              ""
            )}
            {/* <div className=" tw-p-3 tw-mx-3 tw-rounded-xl tw-bg-gray-50">
              <h5 className=" tw-text-[#03335b] tw-text-lg">Select Template Language</h5>
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
             <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
        <label>
          <input
            type="radio"
            className="form-check-input"
            name="languageOption"
            value="english"
            checked={languageOption === "english"}
            onChange={handleLanguageChange}
           
          />
          English
        </label>
      </div>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
        <label>
          <input
            type="radio"
            className="form-check-input"
            name="languageOption"
            value="arabic"
            checked={languageOption === "arabic"}
            onChange={handleLanguageChange}
          
          />
          Arabic
        </label>
      </div>
      </div>
            </div> */}
            <div className="modal-body pt-0 px-1">
              <div className="row">
                <form onSubmit={handleSubmit}>
                  {/* confirmation Message */}
                  <div className="col-md-12 mb-3 px-2 mt-3">
                    <div className="modal-bg">
                      <div className="row  ">
                        <br />
                        <Link
                          className={`configurations-links-a ${
                            expandedRow === 0 ? "active" : ""
                          }`}
                          onClick={() => toggleRowExpansion(0)}
                        >
                          <span>
                            Service Confirmation Message
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  This message will be sent immediately once
                                  the service or appointment is booked.
                                </Tooltip>
                              }
                            >
                              <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                            </OverlayTrigger>
                          </span>
                          {expandedRow === 0 ? (
                            <i className="bi bi-caret-up-fill" />
                          ) : (
                            <i className="bi bi-caret-down-fill" />
                          )}
                        </Link>

                        {expandedRow === 0 && (
                          <>
                            <hr className="mt-2" />

                            {/* <div className="col-6 d-flex">
                            <i className="bi bi-bell me-2"></i>
                            <select
                              className="form-select input-field-ui-select"
                              id="templateFrequency"
                              name="templateFrequency"
                              value={templateFrequency}
                              aria-label="Default select example"
                              onChange={handleFrequencyChange}
                            >
                              <option value="">Select Frequency</option>
                              <option value="1">1 time</option>
                              <option value="2">2 time</option>
                              <option value="3">3 time</option>
                              <option value="4">4 time</option>
                              <option value="5">5 time</option>
                            </select>
                          </div> */}
                      <div className="text-muted mb-1 tw-ml-8" style={{ fontSize: '0.875rem' }}>
                      {/* Header */}
                      <p className="fw-semibold mb-0">When to send</p>

                      {/* Subheader */}
                      <p className="mb-0 tw-flex tw-w-[95%] tw-justify-between">
                      The message is sent immediately when the service is booked.
                        {/* {singleWhatsAppTemplateData && 
                        <span className="remove-template-btn" onClick={handleSingleTemplate} >
                            <i class="fa fa-trash tw-text-orange-600" aria-hidden="true" ></i>
                        </span>
                        } */}
                      </p>
                    </div>
                            <div className="col-12 d-flex align-items-center mt-1">
                              <i className="bi bi-clock me-2"></i>
                              <select
                                className="form-select input-field-ui"
                                value={scheduleSend}
                                aria-label="Default select example"
                                onChange={handleScheduleSendChange}
                              >
                                {/* <option value="">Select Schedule Time</option> */}
                                <option value="immediate" selected>
                                  Immediate
                                </option>
                                {/* <option value="after 15 mins">After 15 mins</option>
                              <option value="after 30 mins">After 30 mins</option>
                              <option value="after 1 hrs">After 1 hrs</option> */}
                              </select>
                              {/* <span>{template.duration}</span> */}
                            </div>

                            {/* <div className="col-6">
                            {errors.templateFrequency && (
                              <div className="text-danger">
                                {errors.templateFrequency}
                              </div>
                            )}
                          </div> */}
                            <div className="col-12">
                              {errors.scheduleSend && (
                                <div className="text-danger">
                                  {errors.scheduleSend}
                                </div>
                              )}
                            </div>

                            <div className="col-md-12 mt-3">
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-select input-field-ui"
                                  id="templateId"
                                  value={selectedValue}
                                  aria-label="Default select example"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Template</option>
                                  {whatsAppTemplate?.map((option) => (
                                    <option
                                      key={option.templateId}
                                      value={option.templateId}
                                    >
                                      {option.templateName}
                                    </option>
                                  ))}
                                </select>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top" placement="top">
                                      Choose a approved WhatsApp template
                                      for the confirmation message.
                                    </Tooltip>
                                  }
                                >
                                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                                </OverlayTrigger>
                              </div>
                              {errors.selectedValue && (
                                <div className="text-danger">
                                  {errors.selectedValue}
                                </div>
                              )}
                            </div>

                            <p className="mt-1"></p>
                            <div className="col-12">
                              {/* <textarea
                                className="form-control input-field-ui mb-1"
                                id="inputMessage2"
                                rows="3"
                                value={replaceVariables(singleWhatsAppTemplateData)}
                                onChange={(e) =>
                                  setSingleWhatsAppTemplateData(e.target.value)
                                }
                                readOnly="readOnly"
                                disabled
                                placeholder="Please select Template to see Whatsapp Message Text."
                              ></textarea> */}
                              {selectedValue !== "" ? (
                                <div 
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500"
                                >
                                  <div className="tw-rounded-lg tw-overflow-hidden tw-shadow-lg">

                                {/* Media Section */}
                                {(confirmTemplate?.type === 'Image' || confirmTemplate?.type === 'Video') && confirmTemplate?.mediaUrl && (
                                  <div className="tw-relative tw-aspect-video tw-bg-gray-200  tw-overflow-hidden">
                                    {confirmTemplate?.type === 'Image' ? (
                                      <img 
                                        src={confirmTemplate?.mediaUrl} 
                                        alt={confirmTemplate?.templateName}
                                        className="tw-w-full tw-object-cover"
                                      />
                                    ) : (
                                      <ReactPlayer
                                            url={confirmTemplate?.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                          />
                                    )}
                                  </div>
                                )}

                                {/* Content Section */}
                                <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2">
                                  {/* Document Header */}
                                  <div className="tw-flex tw-items-start tw-gap-3 tw-mb-2">
                                    <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded-lg">
                                      <svg className="tw-w-8 tw-h-8 tw-text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                      </svg>
                                    </div>
                                    <div className='tw-w-full tw-flex tw-justify-center tw-flex-col'>
                                      <h3 className="tw-font-medium tw-text-[#667781] tw-text-sm" dangerouslySetInnerHTML={{ __html: replaceVariables(confirmTemplate?.headerContent || "Message Preview") }}>
                                        
                                      </h3>
                                      <span className="tw-text-xs tw-text-gray-500">{confirmTemplate?.type}</span>
                                    </div>
                                  </div>

                                  {/* Message Content */}
                                  <div className="tw-flex tw-flex-col tw-gap-3">
                                    {/* Template Body */}
                                    <div className="tw-text-[15px] tw-leading-relaxed tw-text-[#111b21]"
                                      dangerouslySetInnerHTML={{ __html: replaceVariables(confirmTemplate?.templateBody) }}
                                    />

                                    {/* Footer Content */}
                                    {confirmTemplate?.footerContent && (
                                      <p className="tw-text-[13px] tw-text-[#8696a0] tw-mt-1" dangerouslySetInnerHTML={{ __html: replaceVariables(confirmTemplate?.footerContent) }}>
                                      </p>
                                    )}

                                    {/* Website URLs (Links and Phone Numbers) */}
                                    {confirmTemplate?.websiteUrls && confirmTemplate?.websiteUrls.length > 0 && (
                                      <div className="tw-mt-3 tw-space-y-2">
                                        {confirmTemplate?.websiteUrls.map((item, index) => (
                                          <div key={index}>
                                            {item.type === 'URL' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-link-45deg tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                            {item.type === 'PHONE_NUMBER' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-telephone tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {/* Quick Reply Buttons */}
                                    {confirmTemplate?.buttons && confirmTemplate?.buttons.length > 0 && (
                                      <div className="tw-mt-3 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-wrap tw-gap-2">
                                        {confirmTemplate?.buttons.map((button, index) => (
                                          <span
                                            key={index}
                                            className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center"
                                          >
                                            {button.title}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                </div>
                                </div>
                                
                              ) : (
                                <div
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500">
                                  Please select Template to see Whatsapp Message Text.
                                </div>
                              )}
                              {errors.singleWhatsAppTemplateData && (
                                <div className="text-danger">
                                  {errors.singleWhatsAppTemplateData}
                                </div>
                              )}
                            </div>

                            {confirmTemplate?.variablesCount > 0 && (
                              <div className="variable-mapping-table mt-2 mb-2">
                                <VariableMappingRow
                                  data={mappings}
                                  valueOptions={confirmTemplate.values}
                                  variableMappings={variableMappings}
                                  setVariableMappings={setVariableMappings}
                                  variableCount={confirmTemplate.variablesCount}
                                  
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* follow up messages */}
                  <div className="col-md-12 mb-3 px-2">
                    <div className="modal-bg">
                      <Link
                        className={`configurations-links-a ${
                          expandedRow === 1 ? "active" : ""
                        }`}
                        onClick={() => toggleRowExpansion(1)}
                      >
                        Reminder Messages
                        {expandedRow === 1 ? (
                          <i className="bi bi-caret-up-fill" />
                        ) : (
                          <i className="bi bi-caret-down-fill" />
                        )}
                      </Link>
                      {expandedRow === 1 && (
                        <>
                          {followUpMessages?.map((followUp, index) => (
                            <>
                              <div className="col-md-12 mb-3 px-2">
                                <div className="modal-bg">
                                  <div className="row ">
                                    <div className="d-flex justify-content-between ">
                                      <label
                                        htmlFor="inputMessage2"
                                        className="col-form-label pt-0"
                                      >
                                        Follow up message - {index + 1}
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id="tooltip-top"
                                              placement="top"
                                            >
                                              Set the timing for this reminder
                                              to be sent before the
                                              appointment.
                                            </Tooltip>
                                          }
                                        >
                                          <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                                        </OverlayTrigger>
                                      </label>
                                      <span
                                        className="remove-template-btn"
                                        onClick={() => {
                                          handleDeletefollowup(
                                            index,
                                            followUp.templateDetailId
                                          );
                                        }}
                                      >
                                        <i
                                          class="fa fa-trash tw-text-orange-600"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                    <hr className="mt-2" />
                                    <div className="col-12 d-flex align-items-center mt-1">
                                      <i className="bi bi-clock me-2"></i>
                                      <select
                                        className="form-select input-field-ui"
                                        value={followUp.fschedule}
                                        onChange={(e) =>
                                          handleFollowUpScheduleChange(index, e)
                                        }
                                        aria-label="Default select example"
                                      >
                                        {/* <option value="">Select Schedule Time</option> */}
                                        {getAvailableScheduleOptions(
                                          index
                                        )?.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id="tooltip-top"
                                            placement="top"
                                          >
                                            Select how long before the
                                            appointment the reminder message
                                            should be sent.
                                          </Tooltip>
                                        }
                                      >
                                        <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                                      </OverlayTrigger>
                                    </div>
                                    {/* <div className="col-6">
                                          {errors[`followUpFrequency${index}`] && (
                                            <div className="text-danger">
                                              {errors[`followUpFrequency${index}`]}
                                            </div>
                                          )}
                                        </div> */}
                                    <div className="col-12 d-flex">
                                      {errors[`followUpSchedule${index}`] && (
                                        <div className="text-danger">
                                          {errors[`followUpSchedule${index}`]}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-md-12 mt-3 d-flex align-items-center">
                                      {/* <label
                                            htmlFor="templateId"
                                            className="col-form-label pt-0"
                                          >
                                            Template
                                          </label> */}
                                      <select
                                        className="form-select input-field-ui"
                                        id={`followUpTemplate${index}`}
                                        value={followUp.templateId}
                                        onChange={(e) =>
                                          handleFollowUpTemplateChange(index, e)
                                        }
                                      >
                                        <option value="">
                                          Select Template
                                        </option>
                                        {whatsAppTemplate?.map((option) => (
                                          <option
                                            key={option.templateId}
                                            value={option.templateId}
                                          >
                                            {option.templateName}
                                          </option>
                                        ))}
                                      </select>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id="tooltip-top"
                                            placement="top"
                                          >
                                            Choose a pre-approved WhatsApp
                                            template for the reminder message.
                                          </Tooltip>
                                        }
                                      >
                                        <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                                      </OverlayTrigger>
                                      {errors[`followUpTemplate${index}`] && (
                                        <div className="text-danger">
                                          {errors[`followUpTemplate${index}`]}
                                        </div>
                                      )}
                                    </div>
                                    <p className="mt-1"></p>
                                    <div className="col-12">
                                      {/* <label
                                            htmlFor="inputMessage2"
                                            className="col-form-label pt-0">
                                            First Reminder
                                          </label> */}
                                      {/* <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Please select Template to see Whatsapp Message Text."
                                        rows="3"
                                        value={followUp.message}
                                        onChange={(e) =>
                                          handleFollowUpMessageChange(index, e)
                                        }
                                        readOnly="readOnly"
                                        disabled
                                      /> */}
                                     {followUp.templateId !== "" ? (
                                <div 
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500"
                                >
                                  <div className="tw-rounded-lg tw-overflow-hidden tw-shadow-lg">

                                {/* Media Section */}
                                {(followUpTemplates[0]?.type === 'Image' || followUpTemplates[0]?.type === 'Video') && followUpTemplates[0]?.mediaUrl && (
                                  <div className="tw-relative tw-aspect-video tw-bg-gray-200  tw-overflow-hidden">
                                    {followUpTemplates[0]?.type === 'Image' ? (
                                      <img 
                                        src={followUpTemplates[0]?.mediaUrl} 
                                        alt={followUpTemplates[0]?.templateName}
                                        className="tw-w-full tw-object-cover"
                                      />
                                    ) : (
                                      <ReactPlayer
                                            url={followUpTemplates[0]?.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                          />
                                    )}
                                  </div>
                                )}

                                {/* Content Section */}
                                <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2">
                                  {/* Document Header */}
                                  <div className="tw-flex tw-items-start tw-gap-3 tw-mb-2">
                                    <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded-lg">
                                      <svg className="tw-w-8 tw-h-8 tw-text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                      </svg>
                                    </div>
                                    <div className='tw-w-full tw-flex tw-justify-center tw-flex-col'>
                                      <h3 className="tw-font-medium tw-text-[#667781] tw-text-sm" dangerouslySetInnerHTML={{ __html: replaceVariables(followUpTemplates[0]?.headerContent || "Message Preview") }}>
                                        
                                      </h3>
                                      <span className="tw-text-xs tw-text-gray-500">{followUpTemplates[0]?.type}</span>
                                    </div>
                                  </div>

                                  {/* Message Content */}
                                  <div className="tw-flex tw-flex-col tw-gap-3">
                                    {/* Template Body */}
                                    <div className="tw-text-[15px] tw-leading-relaxed tw-text-[#111b21]"
                                      dangerouslySetInnerHTML={{ __html: replaceVariables(followUpTemplates[0]?.templateBody) }}
                                    />

                                    {/* Footer Content */}
                                    {followUpTemplates[0]?.footerContent && (
                                      <p className="tw-text-[13px] tw-text-[#8696a0] tw-mt-1" dangerouslySetInnerHTML={{ __html: replaceVariables(followUpTemplates[0]?.footerContent) }}>
                                      </p>
                                    )}

                                    {/* Website URLs (Links and Phone Numbers) */}
                                    {followUpTemplates[0]?.websiteUrls && followUpTemplates[0]?.websiteUrls.length > 0 && (
                                      <div className="tw-mt-3 tw-space-y-2">
                                        {followUpTemplates[0]?.websiteUrls.map((item, index) => (
                                          <div key={index}>
                                            {item.type === 'URL' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-link-45deg tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                            {item.type === 'PHONE_NUMBER' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-telephone tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {/* Quick Reply Buttons */}
                                    {followUpTemplates[0]?.buttons && followUpTemplates[0]?.buttons.length > 0 && (
                                      <div className="tw-mt-3 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-wrap tw-gap-2">
                                        {followUpTemplates[0]?.buttons.map((button, index) => (
                                          <span
                                            key={index}
                                            className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center"
                                          >
                                            {button.title}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                </div>
                                </div>
                                
                              ) : (
                                <div
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500">
                                  Please select Template to see Whatsapp Message Text.
                                </div>
                              )}
                                      {errors[`followUpMessage${index}`] && (
                                        <div className="text-danger">
                                          {errors[`followUpMessage${index}`]}
                                        </div>
                                      )}
                                    </div>

                                    {followUpTemplates[index]?.variablesCount >
                                      0 && (
                                      <div className="variable-mapping-table mt-2 mb-2">
                                        <VariableMappingRow
                                          data={mappings}
                                          valueOptions={
                                            followUpTemplates[index]?.values
                                          }
                                          variableMappings={
                                            variableMappingsFollowUp[index] ||
                                            []
                                          }
                                          setVariableMappings={(newMapping) =>
                                            handleVariableMappingChange(
                                              index,
                                              newMapping
                                            )
                                          }
                                          variableCount={
                                            followUpTemplates[index]
                                              ?.variablesCount
                                          }
                                          
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-text-center tw-mb-4">
                            <p
                              type="button"
                              className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                              onClick={
                                !allTimesAssigned
                                  ? addFollowUpMessage
                                  : () => {}
                              }
                              style={{
                                cursor: allTimesAssigned
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              <i className="bi bi-plus me-2"></i> Add More
                              Follow Up
                            </p>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Click here to add another reminder message
                                  before the appointment.
                                </Tooltip>
                              }
                            >
                              <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mb-3 tw-ml-2"></i>
                            </OverlayTrigger>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Post-Service Feedback */}
                  <div className="col-md-12 mb-3 px-2">
                    <div className="modal-bg">
                      <div className="row ">
                        <Link
                          className={`configurations-links-a ${
                            expandedRow === 2 ? "active" : ""
                          }`}
                          onClick={() => toggleRowExpansion(2)}
                        >
                          Send Feedback Request After Service
                          {expandedRow === 2 ? (
                            <i className="bi bi-caret-up-fill" />
                          ) : (
                            <i className="bi bi-caret-down-fill" />
                          )}
                        </Link>
                        {expandedRow === 2 && (
                          <>
                            <hr className="mt-2" />
                            <div className="col-12 d-flex align-items-center mt-1">
                              <i className="bi bi-clock me-2"></i>
                              <select
                                class="form-select tw-pr-8 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1"
                                value={selectedFeedbackSchedule}
                                onChange={handleOptionChange}
                              >
                                <option value="">Send Feedback Request</option>
                                <option value="1">
                                  Immediately on service completion (The
                                  feedback request will be sent as soon as the
                                  service is completed.)
                                </option>
                                <option value="2">
                                  Delay by [ ] mins (Set a delay in minutes for
                                  when the feedback request should be sent after
                                  service completion.)
                                </option>
                              </select>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top" placement="top">
                                    Send a feedback request to the user after
                                    the service is completed.
                                  </Tooltip>
                                }
                              >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                              </OverlayTrigger>
                            </div>

                            {selectedFeedbackSchedule === "2" && (
                              <div className="d-flex align-items-center mt-1">
                                &nbsp;&nbsp;&nbsp; &nbsp;
                                <input
                                  type="number"
                                  className="form-control ms-2 no-arrows"
                                  placeholder="Enter minutes"
                                  value={delayMinutes}
                                  onChange={(e) =>
                                    setDelayMinutes(e.target.value)
                                  }
                                />
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top" placement="top">
                                      Enter a custom time in minutes after which
                                      the feedback request will be sent.
                                    </Tooltip>
                                  }
                                >
                                  <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                                </OverlayTrigger>
                              </div>
                            )}

                            <div className="col-12 mt-2 d-flex align-items-center">
                              <select
                                className="form-select input-field-ui"
                                id="templateId"
                                value={selectedFeedBackValue}
                                aria-label="Default select example"
                                onChange={handleChangeFeedBack}
                              >
                                {/* <option value="">Send Feedback Request</option>
                            <option value="1">Immediately on service completion</option>
                            <option value="2">Delay by [ ] mins</option> */}
                                <option value="">Select Template</option>
                                {whatsAppTemplate?.map((option) => (
                                  <option
                                    key={option.templateId}
                                    value={option.templateId}
                                  >
                                    {option.templateName}
                                  </option>
                                ))}
                              </select>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top" placement="top">
                                    Choose a pre-approved WhatsApp template for
                                    the feedback request message.
                                  </Tooltip>
                                }
                              >
                                <i className="bi bi-info-circle tw-cursor-pointer tw-text-xs tw-mt-2 tw-ml-2"></i>
                              </OverlayTrigger>
                            </div>

                            <div className="col-12 mt-3">
                              {/* <textarea
                                className="form-control input-field-ui mb-1"
                                id="inputMessage2"
                                rows="3"
                                value={singleFeedbackTemplateData}
                                onChange={(e) =>
                                  setSingleFeedbackTemplateData(e.target.value)
                                }
                                readOnly="readOnly"
                                disabled
                                placeholder="Please select Template to see Whatsapp Message Text."
                              ></textarea> */}
                              {selectedFeedBackValue !== "" ? (
                                <div 
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500"
                                >
                                  <div className="tw-rounded-lg tw-overflow-hidden tw-shadow-lg">

                                {/* Media Section */}
                                {(postFeedbackTemplate?.type === 'Image' || postFeedbackTemplate?.type === 'Video') && postFeedbackTemplate?.mediaUrl && (
                                  <div className="tw-relative tw-aspect-video tw-bg-gray-200  tw-overflow-hidden">
                                    {postFeedbackTemplate?.type === 'Image' ? (
                                      <img 
                                        src={postFeedbackTemplate?.mediaUrl} 
                                        alt={postFeedbackTemplate?.templateName}
                                        className="tw-w-full tw-object-cover"
                                      />
                                    ) : (
                                      <ReactPlayer
                                            url={postFeedbackTemplate?.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            controls={true}
                                          />
                                    )}
                                  </div>
                                )}

                                {/* Content Section */}
                                <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2">
                                  {/* Document Header */}
                                  <div className="tw-flex tw-items-start tw-gap-3 tw-mb-2">
                                    <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded-lg">
                                      <svg className="tw-w-8 tw-h-8 tw-text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                      </svg>
                                    </div>
                                    <div className='tw-w-full tw-flex tw-justify-center tw-flex-col'>
                                      <h3 className="tw-font-medium tw-text-[#667781] tw-text-sm" dangerouslySetInnerHTML={{ __html: replaceVariables(postFeedbackTemplate?.headerContent || "Message Preview") }}>
                                        
                                      </h3>
                                      <span className="tw-text-xs tw-text-gray-500">{postFeedbackTemplate?.type}</span>
                                    </div>
                                  </div>

                                  {/* Message Content */}
                                  <div className="tw-flex tw-flex-col tw-gap-3">
                                    {/* Template Body */}
                                    <div className="tw-text-[15px] tw-leading-relaxed tw-text-[#111b21]"
                                      dangerouslySetInnerHTML={{ __html: replaceVariables(postFeedbackTemplate?.templateBody) }}
                                    />

                                    {/* Footer Content */}
                                    {postFeedbackTemplate?.footerContent && (
                                      <p className="tw-text-[13px] tw-text-[#8696a0] tw-mt-1" dangerouslySetInnerHTML={{ __html: replaceVariables(postFeedbackTemplate?.footerContent) }}>
                                      </p>
                                    )}

                                    {/* Website URLs (Links and Phone Numbers) */}
                                    {postFeedbackTemplate?.websiteUrls && postFeedbackTemplate?.websiteUrls.length > 0 && (
                                      <div className="tw-mt-3 tw-space-y-2">
                                        {postFeedbackTemplate?.websiteUrls.map((item, index) => (
                                          <div key={index}>
                                            {item.type === 'URL' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-link-45deg tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                            {item.type === 'PHONE_NUMBER' && (
                                              <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                                                <i className="bi bi-telephone tw-mr-2"></i>
                                                {item.title}
                                              </span>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {/* Quick Reply Buttons */}
                                    {postFeedbackTemplate?.buttons && postFeedbackTemplate?.buttons.length > 0 && (
                                      <div className="tw-mt-3 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-wrap tw-gap-2">
                                        {postFeedbackTemplate?.buttons.map((button, index) => (
                                          <span
                                            key={index}
                                            className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center"
                                          >
                                            {button.title}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                </div>
                                </div>
                                
                              ) : (
                                <div
                                className="form-control input-field-ui mb-1 tw-bg-gray-200 tw-text-gray-500">
                                  Please select Template to see Whatsapp Message Text.
                                </div>
                              )}
                              {errors.singleFeedbackTemplateData && (
                                <div className="text-danger">
                                  {errors.singleFeedbackTemplateData}
                                </div>
                              )}
                            </div>

                            {postFeedbackTemplate.variablesCount > 0 && (
                              <div className="variable-mapping-table mt-2 mb-2">
                                <VariableMappingRow
                                  data={mappings}
                                  valueOptions={postFeedbackTemplate.values}
                                  variableMappings={variableMappingsFeedback}
                                  setVariableMappings={
                                    setVariableMappingsFeedback
                                  }
                                  variableCount={
                                    postFeedbackTemplate.variablesCount
                                  }
                                  
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Actions button */}
                  {showErrorMessage && <p style={{textAlign:'center',color:'red'}}>{showErrorMessage}</p>}
                  <div className="col-md-12 my-2 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                      onClick={(e) => {
                        saveWhatsappTemplate(e);
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      ref={configruationPopupCloseBtn}
                      className="btn btn-secondary clear-btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="ConfigurationListSMSModal"
        tabIndex="-1"
        aria-labelledby="ConfigurationListSMSModalLabel"
        aria-hidden="true"
      >
        <ContentSMSNew />
      </div>
    </>
  );
}

export default ContentConfigurationList;

