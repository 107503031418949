import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { fetchApiData,checkValidUser } from '../../services/apihub/Service';
import { CustomLoader } from '../utils/CommonLibrary';

const ApiHubHome = () => {

  const [apiNameSelect, setApiNameSelect] = useState("");
  const [paramValue, setParamValue] = useState();
  const [showApiData, setShowApiData] = useState();
  const [loading, setLoading] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
      checkMemberValid();
  }, [])

  const checkMemberValid = async () => {
    await checkValidUser()
        .then((response) => {
            if (response.data.code != 200) {
              window.location.href = '/no-access';  
            }
        })
    }

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setApiNameSelect(value);
    setParamValue("");

    if (value === "enqPreHisInfo") {
      setParamValue("membershipNo=30658205&dateFrom=2025-01-29&dateTo=2025-01-29");
    } else if (value === "reqPreAuthByID") {
      setParamValue("preAuthId=44348249");
    }
  }

  const onTextBoxChange = (e) => {
    const value = e.target.value;
    setParamValue(value);
  }

  const searchApiData = async () => {
    setLoading(true);
    await fetchApiData(apiNameSelect, paramValue)
      .then((response) => {
        setShowApiData(JSON.stringify(response.data.data.apidata, null, 2));
        setLoading(false);
      }).catch(async function (error) {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(showApiData)
      .then(() => {
        setMessage("Copied to clipboard!");
        setTimeout(() => setMessage(false), 2000);
      }).catch((err) => {
        setError("Failed to copy:", err);
        setTimeout(() => setError(false), 2000);
      });
  };

  return (
    <>
      <section className="ReportsPrograms">
        <div className="container-fluid px-0 mb-3 ">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md " style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <h1 className="main-title mb-0">API</h1>
            </div>
          </div>
          <div className="reports-programs mb-3">
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/10/17/b4qag/6jm3kh8srs.png"
                  alt="Invited Members icon"
                  style={{ height: "140px" }}
                />
              </div>
              <div className="col-md">
                <p className="mb-0">

                </p>
              </div>
            </div>
          </div>

          <div className="reports-programs mb-3">
            <div className="row">
              <div className="col-md-12">
                <h1 className="dashboard-graph-title "><span></span></h1>
              </div>
              <div className="col-md-3">
                <select
                  value={apiNameSelect}
                  className="form-select input-field-ui"
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                >
                  <option value="">--Select--</option>
                  <option value="enqPreHisInfo">enqPreHisInfo</option>
                  <option value="reqPreAuthByID">reqPreAuthByID</option>
                </select>
              </div>
              <div className="col-md-5">
                {apiNameSelect !== "" && (
                  <div>
                    <textarea className="form-control input-field-ui" placeholder="Enter text here" value={paramValue} onChange={(e) => onTextBoxChange(e)} rows="3"></textarea>
                  </div>
                )}
              </div>
              <div className="col-md-2">
                {apiNameSelect !== "" && (
                  <Button variant="primary" type="submit" className="btn btn-primary save-btn me-2" style={{ background: "#03335b", border: "1px solid #03335b" }} onClick={searchApiData} >Search</Button>
                )}
              </div>
            </div>

            {showApiData && <div className="row">
              <div className="col-md-12">
                <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
                  <h1 className="dashboard-graph-title"><span>Response</span></h1>
                  <Button variant="primary" type="submit" className="btn btn-primary me-2" style={{ background: "#03335b", border: "1px solid #03335b" }} onClick={handleCopy} ><i class="bi bi-copy"></i> Copy</Button>
                </div>
                <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                <div className={`${message != "" ? "messageDivNew" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
              </div>
              <div className="col-md-12 p-2 mt-2" style={{ border: "1px solid grey", borderRadius: '5px' }}>
                <pre>{showApiData}</pre>
              </div>
            </div>}
          </div>
        </div>

      </section>
      {loading ? <CustomLoader /> : null}
    </>
  )
}

export default ApiHubHome;