import common_axios from "./commonAxios";
let baseURL = common_axios.defaults.baseURL + 'rpmweb/';

export const fetch_admin_user_data = () =>  common_axios.get(baseURL + 'fetch_admin_user_data'); 
export const fetch_role_data = () =>  common_axios.get(baseURL + 'fetch_role_data'); 
export const rpm_fetch_admin_user_data = (userId) =>  common_axios.get(baseURL + 'rpm_fetch_admin_user_data?userId=' + userId); 
export const rpm_admin_user_update_data = (userId, formData) =>  common_axios.post(baseURL + 'rpm_admin_user_update_data?userId=' + userId, formData); 
export const rpm_user_creation_data = (formData) =>  common_axios.post(baseURL + 'rpm_user_creation_data', formData); 
export const fetch_ad_users = (data) =>  common_axios.post(baseURL + 'fetch_ad_users', data); 
export const fetch_all_users = (data) =>  common_axios.post(baseURL + 'fetch_all_users', data); 
export const fetch_vital_graphs = (data) =>  common_axios.post(baseURL + 'fetch_vital_graphs', data); 
export const fetch_alert_stats = (data) =>  common_axios.post(baseURL + 'fetch_alert_stats', data); 
export const fetch_all_alerts = (data) =>  common_axios.post(baseURL + 'fetch_all_alerts', data); 
export const dismiss_alert = (data) =>  common_axios.post(baseURL + 'dismiss_alert', data); 
export const fetch_all_conditions = () =>  common_axios.get(baseURL + 'fetch_all_conditions'); 
export const fetch_vital_alert_types = () =>  common_axios.get(baseURL + 'fetch_vital_alert_types'); 
