import React from "react";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { IoMdInformationCircle, IoMdCloseCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";


// Define normal ranges for vital signs
const normalRanges = {
  spo2: { min: 95, max: 100 },
  bodyTemp: { min: 97, max: 99 },
  prBpm: { min: 60, max: 100 },
  hrv: { min: 20, max: 100 },
  rrp: { min: 12, max: 20 },
  bp: {
    systolic: { min: 90, max: 120 },
    diastolic: { min: 60, max: 80 },
  },
  blg: { min: 70, max: 100 },
};

// Custom CSS for the component
const customStyles = `
  .profile-card-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .profile-card-subtitle {
    font-size: 12px;
    color: #6B7280;
    font-weight: 400;
  }
  
  .member-profile-box-body-item {
    padding: 5px 0;
  }
  
  .member-profile-box {
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  /* Red/Danger alert styling */
  .abnormal-value-danger {
    background-color: #FEE2E2;
    color: #B91C1C;
    border-radius: 5px;
    padding: 2px 4px;
    animation: pulseBgDanger 2s infinite;
  }
  
  @keyframes pulseBgDanger {
    0% { background-color: #FEE2E2; }
    50% { background-color: #FECACA; }
    100% { background-color: #FEE2E2; }
  }

  /* Orange/Warning alert styling */
  .abnormal-value-warning {
    background-color: #FFF5E6;
    color: #9A6700;
    border-radius: 5px;
    padding: 2px 4px;
    animation: pulseBgWarning 2s infinite;
  }
  
  @keyframes pulseBgWarning {
    0% { background-color: #FFF5E6; }
    50% { background-color: #FFEDD5; }
    100% { background-color: #FFF5E6; }
  }

  /* Default abnormal (when level not specific) */
  .abnormal-value {
    background-color: #FEF3F2;
    color: #7A1921;
    border-radius: 5px;
    padding: 2px 4px;
    animation: pulseBg 2s infinite;
  }
  
  @keyframes pulseBg {
    0% { background-color: #FEF3F2; }
    50% { background-color: #FEE4E2; }
    100% { background-color: #FEF3F2; }
  }
`;

const headerStyles = `
  .member-profile-box-header-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  
  .member-profile-images {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .member-profile-images-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .member-profile-images-name h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  .member-profile-images-name small {
    font-size: 12px;
  }
`;

const ProfileCard = ({ member }) => {
  const navigate = useNavigate();

  const getStatusClass = (type) => {
    switch (type) {
      case "warning":
        return "profile-bg-orange";
      case "success":
        return "profile-bg-green";
      case "danger":
        return "profile-bg-red";
      default:
        return "profile-bg-orange";
    }
  };

  // Function to check if a value is abnormal based on either:
  // 1. The value compared to normal ranges
  // 2. The observation data from the backend
  const isAbnormal = (vitalKey, value) => {
    if (value === false) return false;

    // First check if we have an observation from backend data
    if (member.vitals.observations && member.vitals.observations[vitalKey]) {
      const observation = member.vitals.observations[vitalKey];
      // If observation includes these words, it's abnormal
      if (observation && (
        observation.includes("High") ||
        observation.includes("Low") ||
        observation.includes("Hypo") ||
        observation.includes("Hyper") ||
        observation.includes("Diabetic") ||
        observation.includes("Fever") ||
        observation.includes("AFib") ||
        observation.includes("Abnormal") ||
        observation.includes("Critical")
      )) {
        return true;
      }

      // If observation is Normal or Healthy, it's not abnormal
      if (observation && (observation === "Normal" || observation === "Healthy")) {
        return false;
      }
    }

    // If no observation data or it's not conclusive, fall back to range checking
    if (vitalKey === "bp" && value) {
      // Handle blood pressure which is formatted as "systolic/diastolic"
      try {
        const [systolic, diastolic] = value.split("/").map(Number);
        if (!isNaN(systolic) && !isNaN(diastolic)) {
          return (
            systolic > normalRanges.bp.systolic.max ||
            systolic < normalRanges.bp.systolic.min ||
            diastolic > normalRanges.bp.diastolic.max ||
            diastolic < normalRanges.bp.diastolic.min
          );
        }
      } catch (e) {
        // If we can't parse BP, don't mark it as abnormal
        return false;
      }
    }

    // For ECG, check for AFib
    if (vitalKey === "ecg") {
      return value && (
        value.includes("AFib") ||
        value.includes("abnormal") ||
        value.includes("Abnormal")
      );
    }

    // For numeric values, check against normal ranges
    if (normalRanges[vitalKey] && typeof value === 'number') {
      return (
        value > normalRanges[vitalKey].max ||
        value < normalRanges[vitalKey].min
      );
    }

    return false;
  };

  // Function to get background class based on value
  const getVitalBackgroundClass = (vitalKey, value) => {
    if (!isAbnormal(vitalKey, value)) return "";

    // Check priority level if available
    if (member.vitals.priorities && member.vitals.priorities[vitalKey]) {
      const priority = member.vitals.priorities[vitalKey];

      if (priority >= 8) {
        return "abnormal-value-danger";
      } else if (priority >= 3) {
        return "abnormal-value-warning";
      }
    }

    // Check observation text if available
    if (member.vitals.observations && member.vitals.observations[vitalKey]) {
      const observation = member.vitals.observations[vitalKey];

      if (observation && (
        observation.includes("Critical") ||
        observation.includes("Severe") ||
        observation.includes("AFib")
      )) {
        return "abnormal-value-danger";
      } else if (observation && (
        observation.includes("Moderate") ||
        observation.includes("Borderline") ||
        observation.includes("Mild")
      )) {
        return "abnormal-value-warning";
      }
    }

    // If we can't determine level, use default abnormal style
    return "abnormal-value";
  };

  // Check if any vital is abnormal
  const hasAnyAbnormalVitals = () => {
    const vitals = member.vitals;

    // First check if any vitals are abnormal
    const hasAbnormal =
      isAbnormal("spo2", vitals.spo2) ||
      isAbnormal("bodyTemp", vitals.bodyTemp) ||
      isAbnormal("prBpm", vitals.prBpm) ||
      isAbnormal("hrv", vitals.hrv) ||
      isAbnormal("rrp", vitals.rrp) ||
      isAbnormal("bp", vitals.bp) ||
      isAbnormal("blg", vitals.blg) ||
      isAbnormal("bgpp", vitals.bgpp) ||
      isAbnormal("ecg", vitals.ecg);

    if (!hasAbnormal) return "";

    // Check for any high priority abnormalities
    let highestPriority = 0;

    // If priorities are available, find the highest
    if (vitals.priorities) {
      Object.values(vitals.priorities).forEach(priority => {
        if (priority > highestPriority) highestPriority = priority;
      });
    }

    // Return appropriate border class based on highest priority
    if (highestPriority >= 8) {
      return "brd-color-red";
    } else if (highestPriority >= 3) {
      return "brd-color-orange";
    } else if (hasAbnormal) {
      return "brd-color-red"; // Default to red if abnormal but no priority info
    }

    return "";
  };

  // Helper to format vital values with proper units
  const formatVitalValue = (vitalKey, value) => {
    if (value === false || value === null || value === undefined) return '';

    switch (vitalKey) {
      case 'spo2': return `${value}%`;
      case 'bodyTemp': return `${value}°F`;
      case 'prBpm': return `${value} bpm`;
      case 'hrv': return `${value} ms`;
      case 'rrp': return `${value} breaths/min`;
      case 'bp': return `${value} mmHg`;
      case 'blg': return `${value} mg/dL`;
      case 'ecg': return value;
      default: return value;
    }
  };

  const borderStyles = `
  .brd-color-red {
    border: 2px solid #e61e39;
  }
  
  .brd-color-orange {
    border: 2px solid #ff9800;
  }
  
  .brd-color-green {
    border: 2px solid #28a745;
  }
`;

  // Add this function to your ProfileCard component
  const calculateAge = (dob) => {
    if (!dob) return null;

    try {
      // Handle different date formats
      let dateOfBirth;

      // Check if the date is already a Date object
      if (dob instanceof Date) {
        dateOfBirth = dob;
      } else {
        // Parse different string formats
        // Try standard date parsing first
        dateOfBirth = new Date(dob);

        // If date is invalid, try different formats
        if (isNaN(dateOfBirth.getTime())) {
          // Try DD-MM-YYYY format
          const parts = dob.split(/[-\/]/);
          if (parts.length === 3) {
            // Try different date formats
            if (parts[2].length === 4) {
              // Assuming DD-MM-YYYY or DD/MM/YYYY
              dateOfBirth = new Date(parts[2], parts[1] - 1, parts[0]);
            } else if (parts[0].length === 4) {
              // Assuming YYYY-MM-DD or YYYY/MM/DD
              dateOfBirth = new Date(parts[0], parts[1] - 1, parts[2]);
            }
          }
        }
      }

      // If date is still invalid, return null
      if (isNaN(dateOfBirth.getTime())) {
        return null;
      }

      // Calculate age
      const today = new Date();
      let age = today.getFullYear() - dateOfBirth.getFullYear();
      const monthDiff = today.getMonth() - dateOfBirth.getMonth();

      // Adjust age if birthday hasn't occurred yet this year
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
        age--;
      }

      return age;
    } catch (error) {
      console.error("Error calculating age:", error);
      return null;
    }
  };

  return (
    <>
      <style>{customStyles + borderStyles + headerStyles}</style>
      <div key={member.id} className="col-md-3 mb-4">
        <div
          className={`member-profile-box ${hasAnyAbnormalVitals()}`}
          style={{
            cursor: "pointer",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
          onClick={() => navigate(`/rpm-member-profile/${member.id}`)}
        >
          <div className="member-profile-box-header mb-2">
            <div className="member-profile-box-header-title">
              <div className="tw-flex tw-flex-col tw-items-start w-100">
                <div className="member-profile-images">
                  <img
                    src={member.image}
                    className="member-profile-images-img"
                    alt="profile"
                  />
                  <div className="member-profile-images-name tw-flex-col tw-items-start tw-gap-0">
                    <div className="tw-flex tw-items-center tw-flex-row tw-gap-2">
                      <h3>{member.name}</h3>
                      {member.membershipNo && (
                        <small className="text-muted">ID: {member.membershipNo}</small>
                      )}
                    </div>
                    {(member.dob || member.gender || member.city || member.mobile) && (
                      <div className="">
                        <small className="text-muted">
                          {[
                            `Age: ${member.dob ? `${calculateAge(member.dob)}` : null} / ${member.gender}`,
                            ``,
                            `City: ${member.city}`,
                            `M: ${member.mobileNo}`
                          ]
                            .filter(Boolean) // Remove undefined/null values
                            .join(", ")}
                        </small>
                      </div>
                    )}

                  </div>
                </div>

                {/* Member additional details - fix conditional logic */}
              </div>

              <div className="member-profile-box-header-actions">
                {member.deviceStatus !== "disconnected" &&
                  member.status && member.status.type && (
                    <div
                      className={`member-profile-box-header-actions-button-container ${getStatusClass(
                        member.status.type
                      )}`}
                    >
                      <i className="bi bi-bell-fill"></i>
                      {member.status.count}
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="member-profile-box-body">
            <div className="member-profile-box-body-item">
              <div className="row">
                {/* Spo2 */}
                {member.vitals.spo2 !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "spo2",
                        member.vitals.spo2
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <IoArrowUpCircleOutline
                          className="me-1"
                          style={{
                            color: isAbnormal("spo2", member.vitals.spo2)
                              ? "#B91C1C"
                              : "#006DED",
                          }}
                        />
                        <strong>{member.vitals.spo2}</strong>
                        <span>%</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">Spo2</h6>
                    </div>
                  </div>
                )}

                {/* Body Temp */}
                {member.vitals.bodyTemp !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "bodyTemp",
                        member.vitals.bodyTemp
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.bodyTemp}</strong>{" "}
                        <span className="ps-1">°F</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">Body Temp</h6>
                    </div>
                  </div>
                )}

                {/* Heart Rate */}
                {member.vitals.prBpm !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "prBpm",
                        member.vitals.prBpm
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.prBpm}</strong>
                        <span className="ps-1">bpm</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">Heart Rate</h6>
                    </div>
                  </div>
                )}

                {/* HRV */}
                {member.vitals.hrv !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "hrv",
                        member.vitals.hrv
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.hrv}</strong>{" "}
                        <span className="ps-1">ms</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">HRV</h6>
                    </div>
                  </div>
                )}

                {/* Respiratory Rate */}
                {member.vitals.rrp !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "rrp",
                        member.vitals.rrp
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.rrp}</strong>{" "}
                        <span className="ps-1">breaths/min</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">
                        Respiratory Rate
                      </h6>
                    </div>
                  </div>
                )}

                {/* Blood Glucose - Fasting */}
                {member.vitals.blg !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "blg",
                        member.vitals.blg
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.blg}</strong>{" "}
                        <span className="ps-1">mg/dL</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">
                        Blood Glucose (Fasting)
                      </h6>
                    </div>
                  </div>
                )}

                {/* Blood Glucose */}
                {/* {member.vitals.blg !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "blg",
                        member.vitals.blg
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.blg}</strong>{" "}
                        <span className="ps-1">mg/dL</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">
                        Blood Glucose
                      </h6>
                    </div>
                  </div>
                )} */}

                {/* Blood Glucose - Post Prandial */}
                {member.vitals.bgpp !== false && (
                  <div className="col-4 mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "bgpp",
                        member.vitals.bgpp
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.bgpp}</strong>{" "}
                        <span className="ps-1">mg/dL</span>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">
                        Blood Glucose (PP)
                      </h6>
                    </div>
                  </div>
                )}

                {/* ECG */}
                {member.vitals.ecg !== false && (
                  <div className="col mb-3">
                    <div
                      className={getVitalBackgroundClass(
                        "ecg",
                        member.vitals.ecg
                      )}
                    >
                      <h5 className="profile-card-title mb-0">
                        <strong>{member.vitals.ecg}</strong>
                      </h5>
                      <h6 className="profile-card-subtitle mb-0">ECG</h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="member-profile-box-footer">
            {member.deviceStatus === "disconnected" ? (
              <div className="member-profile-box-footer-item1">
                <h3>
                  <span>
                    <IoMdInformationCircle
                      style={{
                        fontSize: "22px",
                        color: "#F95721",
                        marginRight: "10px",
                      }}
                    />
                    Device is Disconnected
                  </span>
                  <IoMdCloseCircle
                    style={{
                      fontSize: "22px",
                      color: "#191D30",
                      opacity: "0.15",
                    }}
                  />
                </h3>
              </div>
            ) : (
              <>
                <div className="member-profile-box-footer-item d-flex align-items-center mb-2">
                  <h5>Last Checked:</h5>
                  <h6 className="ps-1">{member.lastChecked}</h6>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;