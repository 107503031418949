import React from 'react';
import HeartRate from "../Graphs/HeartRate";
import BodyTemperature from "../Graphs/BodyTemperature";
import SpO2 from "../Graphs/SpO2";
import BMI from "../Graphs/BMI";
import BloodGlucose from "../Graphs/BloodGlucose";
import BloodPressure from "../Graphs/BloodPressure";
import VitalStats from "../Graphs/VitalStats";
import NewBMI from '../Graphs/NewBMI';
import { LoaderLoader } from '../../../utils/CommonLibrary';


function VitalsDisplayPanel({ vitalsDataProp, isLoading }) {
    // Use props directly, with fallback default values
    const vitalsData = vitalsDataProp || {
        bmi: { current: "" },
        heartRate: {current: "", history: [] },
        bodyTemperature: {current: "", history: [] },
        spO2: {current: "", history: [] },
        bloodGlucose: {current: "", type: "", history: [] },
        bloodGlucoseRandom: {current: "", type: "", history: [] },
        bloodPressure: {current: "", systolic: "", diastolic: "", history: [] }
    };
    
    return (
        <div className='row'>
            {/* loader */}
            {isLoading && <LoaderLoader isLoading={isLoading} />}

            <div className='col-md-12 mb-3'>
                <VitalStats data={vitalsData} />
            </div>
            

            <div className='col-md-12 mb-3'>
                <div className='row'>
                    {vitalsData.bmi.current !== "" && (
                        <div className='col-md-6 mb-3'>
                            <NewBMI bmiValue={vitalsData.bmi.current} />
                        </div>
                    )}
                    {vitalsData.heartRate.history.length > 0 && (
                        <div className='col-md-6 mb-3'>
                            <HeartRate data={vitalsData.heartRate} />
                        </div>
                    )}
                    {vitalsData.bodyTemperature.history.length > 0 && (
                        <div className='col-md-6 mb-3'>
                            <BodyTemperature data={vitalsData.bodyTemperature} />
                        </div>
                    )}
                    {vitalsData.spO2.history.length > 0 && (
                        <div className='col-md-6 mb-3'>
                            <SpO2 data={vitalsData.spO2} />
                        </div>
                    )}
                    {vitalsData.bloodGlucose.history.length > 0 && (
                        <div className='col-md-6 mb-3'>
                            <BloodGlucose bloodGlucose={vitalsData.bloodGlucose} bloodGlucoseRandom={vitalsData.bloodGlucoseRandom} />
                        </div>
                    )}    
                    {vitalsData.bloodPressure.history.length > 0 && (
                        <div className='col-md-6 mb-3'>
                            <BloodPressure data={vitalsData.bloodPressure} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VitalsDisplayPanel; 