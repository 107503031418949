import React, { useState, useEffect } from "react";
import moment from "moment";
import CalendarDatePicker from "../CalendarDatePicker";
import BackendDrivenDataTable from "../../cn/components/common/BackendDrivenDataTable";
import { decryptData } from "../../utils/Utils";
import { MultiSelect } from "react-multi-select-component";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { exportTableData } from "../../utils/CommonLibrary";
import {
  carenavigatorWhatsAppOverview,
  carenavigatorWhatsappOverviewList,
  fetch_supervisors_list,
  fetchReaderGenderGraphAge,
  fetchTemplateDataList,
  getCareNavigators,
} from "../../../services/medengage/Service";
import DemographicsSection from "./Components/DemographicsSection";
import HourlyDataSection from "./Components/HourlyDataSection";
import WhatsAppAnalyticsPie from "./Components/WhatsAppAnalyticsPie";
import { fetch_admin_insights, fetchHourlyDatagraph, optout_users_download } from "../../../services/whatsapp/Service";

const sortData = (data, column, direction) => {
  return [...data].sort((a, b) => {
    const aValue = a[column];
    const bValue = b[column];

    // Handle numeric values
    if (!isNaN(aValue) && !isNaN(bValue)) {
      return direction === "asc"
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    }

    // Handle string values
    if (direction === "asc") {
      return aValue?.toString().localeCompare(bValue?.toString() || "");
    }
    return bValue?.toString().localeCompare(aValue?.toString() || "");
  });
};

const CareCoordinateAnalytics = () => {
  const [selectedDashboard, setSelectedDashboard] = useState("all");
  const [selectedSupervisor, setSelectedSupervisor] = useState("all");
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("navigatorName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [download, setDownload] = useState("N");
  const [totalRows, setTotalRows] = useState(0);
  const [readerGraph, setReaderGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [hourlyDataGraph, setHourlyDataGraph] = useState([]);
  

  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );
  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const [carenavigatorsList, setCarenavigatorsList] = useState([]);
  const [supervisorList, setSupervisorList] = useState([]);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [selectedOptionsTemplate, setSelectedOptionsTemplate] = useState("");
  const [selectedCN, setSelectedCN] = useState("");
  const [selectedCN2, setSelectedCN2] = useState("");
  const [whatsAppStats, setWhatsAppStats] = useState({
    totalMessagesSent: 0,
    totalMessagesDelivered: 0,
    totalMessagesRead: 0,
    totalMessagesUndelivered: 0,
    totalConversations: 0,
    totalAvgResponseTime: 0,
    pendingResponses: 0,
    optOutMembers: 0,
  });

  const [memberData, setMemberData] = useState([]);

  const [msgType, setMsgType] = useState("");

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleClear = () => {
    setSearchQuery("");
    setPage(1);
    setSortColumn("navigatorName");
    setSortDirection("asc");
    setSelectedDashboard("all");
    setSelectedSupervisor("all");
  };

  useEffect(() => {
    if (selectedSupervisor) {
      // Reset the CareNavigator selection when Supervisor changes
      setSelectedCN("");
      setSelectedOptions([]);
      getCareNavigatorsList();
    }
  }, [selectedSupervisor]);

  useEffect(() => {
    fetchSuperisorsList();
    fetchData();
    fetchMembersData();
    fetchTemplateData();
    fetch_admin_insights_data();
    fetchHourlyDatagraphlist();
  }, []);

  useEffect(() => {
    // Only fetch data if the component is mounted
    fetchData();
    fetchMembersData();
    fetchReaderDemograph();
    fetch_admin_insights_data();
    fetchHourlyDatagraphlist();
  }, [startDate, endDate, selectedCN, selectedOptionsTemplate, msgType, selectedSupervisor, page, perPage]);

  
  const fetchTemplateData = async () => {
    setOptions2([]);
    setSelectedOptions2([])
    await fetchTemplateDataList()
      .then((response) => {
        if (response.data.code === 200) {         
          let templatedata = response.data.data.template;
          //setCarenavigatorsList(carenavigators);
          const formattedOptions = templatedata.map((item) => ({
            label: item.templateName,
            value: item.id,
          }));
          setOptions2(formattedOptions);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchSuperisorsList = async () => {
    await fetch_supervisors_list().then((res) => {
      setSupervisorList(res.data.data.supervisors);
    });
  };

  const getCareNavigatorsList = async () => {
    setOptions([]);
    setSelectedOptions([]);
    setSelectedCN("");
    
    try {
      const response = await getCareNavigators(selectedSupervisor);
      if (response.data.code === 200) {
        const carenavigators = response.data.data.users;
        setCarenavigatorsList(carenavigators);
        const formattedOptions = carenavigators.map((item) => ({
          label: item.userName,
          value: item.id,
        }));
        setOptions(formattedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    const valueArray = selected.map((option) => option.value).join(",");
    setSelectedCN(valueArray);
    
    // Reset page when filters change
    setPage(1);
  };

  const handleTemplateChange = (selected) => {
    setSelectedOptions2(selected);
    const valueArray = selected.map((option) => option.value).join(",");
    setSelectedOptionsTemplate(valueArray);
  };

  const fetchReaderDemograph = async () => {
    try {
      let start = formatDate(startDate);
      let end = formatDate(endDate);
      const response = await fetchReaderGenderGraphAge({
        startDate:start,
        endDate:end,
        roleId:selectedSupervisor,
        agents:selectedCN,
        msgType:msgType,
        templateId: selectedOptionsTemplate,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setReaderGraph(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admin_insights_data = async () => {
   // setLoading(true);
    try {
      let start = formatDate(startDate);
      let end = formatDate(endDate);
      const response = await fetch_admin_insights({
        startDate:start,
        endDate:end,
        roleId:selectedSupervisor,
        agents:selectedCN,
        msgType:msgType,
        templateId: selectedOptionsTemplate,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    //setLoading(false);
  };

  const fetchHourlyDatagraphlist = async () => {
    try {
      let start = formatDate(startDate);
      let end = formatDate(endDate);
      const response = await fetchHourlyDatagraph({
        startDate:start,
        endDate:end,
        roleId:selectedSupervisor,
        agents:selectedCN,
        msgType:msgType,
        templateId: selectedOptionsTemplate,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setHourlyDataGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchData = async () => {
    await carenavigatorWhatsAppOverview(
      formatDate(startDate),
      formatDate(endDate),
      selectedCN,
      selectedSupervisor,
      msgType,
      selectedOptionsTemplate
    ).then((response) => {
      // const statsData = response.data.data.whatsAppData;
      setWhatsAppStats((prevState) => ({
        ...prevState,
        totalMessagesSent: response?.data?.data?.totalMessagesSent,
        totalMessagesDelivered: response?.data?.data?.totalMessagesDelivered,
        totalMessagesRead: response?.data?.data?.totalMessagesRead,
        totalMessagesUndelivered:
          response?.data?.data?.totalMessagesUndelivered,
        totalConversations: response?.data?.data?.totalConversations,
        pendingResponses: response?.data?.data?.pendingResponses,
        totalAvgResponseTime: response?.data?.data?.totalAvgResponseTime,
        optOutMembers: response?.data?.data?.optOutMembers,
      }));
    });
  };

  const fetchMembersData = async () => {
    await carenavigatorWhatsappOverviewList(
      formatDate(startDate),
      formatDate(endDate),
      selectedCN,
      selectedSupervisor,
      page,
      perPage,
      msgType,
      selectedOptionsTemplate
    ).then((response) => {
      if (response.data.code === 200) {
        setMemberData(response.data.data);
        setPerPage(response.data.pagination.pageSize || 10);
        setTotalRows(response.data.pagination.totalRecords);
      }
    });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const analyticsData = [
    {
      title: "Total Messages Sent",
      value: whatsAppStats.totalMessagesSent,
      tooltip: "Total number of messages sent.",
    },
    {
      title: "Total Messages Delivered",
      value: whatsAppStats.totalMessagesDelivered,
      tooltip: "Total number of messages delivered.",
    },
    {
      title: "Total Messages Failed",
      value: whatsAppStats.totalMessagesUndelivered,
      tooltip: "Total number of messages failed.",
    },
    {
      title: "Total Messages Read",
      value: whatsAppStats.totalMessagesRead,
      tooltip: "Total number of messages read.",
    },
    {
      title: "Total Conversations",
      value: whatsAppStats.totalConversations,
      tooltip: "Total number of conversations.",
    },
    {
      title: "Pending Responses",
      value: whatsAppStats.pendingResponses,
      tooltip: "Total number of pending responses.",
    },
    {
      title: "Average Response Time",
      value: whatsAppStats.totalAvgResponseTime,
      tooltip: "Average response time.",
    },
    {
      title: "Opt-Out Members",
      value: whatsAppStats.optOutMembers,
      tooltip: "Total number of opt-out members.",
    },
  ];

  const columns = [
    {
      name: "CareNavigator Name",
      selector: (row) => row.carenavigatorName,
      sortable: true,
    },
    {
      name: "Supervisor",
      selector: (row) => row.supervisorName,
      sortable: true,
    },
    {
      name: "Messages Sent",
      selector: (row) => row.totalMessagesSent,
      sortable: true,
    },
    {
      name: "Messages Delivered",
      selector: (row) => row.totalMessagesDelivered,
      sortable: true,
    },
    {
      name: "Messages Read",
      selector: (row) => row.totalMessagesRead,
      sortable: true,
    },
    {
      name: "Messages Failed",
      selector: (row) => row.totalMessagesUndelivered,
      sortable: true,
    },
    {
      name: "Pending Responses",
      selector: (row) => row.pendingResponses,
      sortable: true,
    },
    {
      name: "Average Response Time",
      selector: (row) => row.totalAvgResponseTime,
      sortable: true,
    },
  ];

  const getFilteredAndSortedData = () => {
    // First apply search filter
    let filtered = memberData.filter((user) => {
      if (!searchQuery) return true;

      return (
        (user.carenavigatorName?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        ) ||
        (user.supervisorName?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        )
      );
    });

    // Then apply sorting
    return sortData(filtered, sortColumn, sortDirection);
  };

  const processedData = getFilteredAndSortedData();

  const handleExport = () => {
    const modifiedData = processedData.map((item) => {
      return {
        "Care Navigator Name": item.carenavigatorName,
        "Supervisor Name ": item.supervisorName,
        "Messages Sent": item.totalMessagesSent,
        "Messages Delivered": item.totalMessagesDelivered,
        "Messages Read": item.totalMessagesRead,
        "Messages Failed": item.totalMessagesUndelivered,
        "Pending Responses": item.pendingResponses,
        "Average Response Time": item.totalAvgResponseTime,
      };
    });

    modifiedData.forEach(function (v) {
      delete v.id;
    });
    exportTableData("CareNavigatorDetails", modifiedData);
  };

  const handleDownloadOptOutMembers = async () => {
    try {
      // setLoading(true);
      // This is a dummy API call that you will replace with the actual one
      const response = await optout_users_download({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `OptOutMembers_${formatDate(startDate)}_to_${formatDate(endDate)}.csv`);

      // Append, trigger click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading opt-out members:", error);
    } 
  };

  return (
    <div>
      <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2 tw-mb-3">
        <select
          className="form-select input-field-ui tw-max-w-[17%]"
          id="selectedSupervisor"
          aria-label="Default select example"
          value={selectedSupervisor}
          onChange={(e) => setSelectedSupervisor(e.target.value)}
        >
          <option value="">Select Supervisor</option>
          {supervisorList.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>


        <MultiSelect
          options={options}
          value={selectedOptions}
          onChange={handleChange}
          labelledBy="Select"
          hasSelectAll={false}
          className="form-select select-input-ui select-input-ui-select  tw-max-w-[17%]"
          disableSearch={true}
          overrideStrings={{
            selectAll: "Select All",
            allItemsAreSelected: "All items are selected",
            selectSomeItems: "Select CareNavigators",
          }}
          style={{ fontSize: "1rem" }}
        />

        <select
                  value={msgType}
                  onChange={(e) => setMsgType(Number(e.target.value))}
                  className="form-select input-field-ui  tw-max-w-[17%]"
                  placeholder="Select Dashboard"
                >
                  {/* <option disabled value="">Select Message Type</option> */}
                  <option value="0">All</option>
                  <option value="1">Template</option>
                  <option value="2">Free-Form</option>
                </select>
          {msgType === 1 && (
        <MultiSelect
          options={options2}
          value={selectedOptions2}
          onChange={handleTemplateChange}
          labelledBy="Select"
          hasSelectAll={false}
          className="form-select select-input-ui select-input-ui-select  tw-max-w-[17%]"
          disableSearch={true}
          overrideStrings={{
            selectAll: "Select All",
            allItemsAreSelected: "All items are selected",
            selectSomeItems: "Select Templates",
          }}
          style={{ fontSize: "1rem" }}
        />)}
         

        <CalendarDatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />
      </div>

      {/* <div className="data-not-bg mb-3">
        <div className="data-not-box">
          <div className="row text-center px-2">
            <div className="col text-center">
              <h1 class="data-not-title">
                Last Updated: January 9, 2025. Today's data will be available
                tomorrow.
              </h1>
            </div>
          </div>
        </div>
      </div> */}

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mb-3">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
              <div className="tw-p-4 tw-relative">
            {(item.title === "Opt-Out Members" && item.value > 0) && (
              <span className=" tw-absolute tw-right-4 tw-top-2 tw-text-[#03335B] tw-cursor-pointer tw-px-1 tw-rounded-lg"
              onClick={handleDownloadOptOutMembers}
              >
                <i class="bi bi-download tw-font-bold"></i>
              </span>
                )}
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value || 0}
                    </h1>
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                    {/* <div className="tw-flex tw-flex-row tw-gap-1 tw-items-center tw-absolute tw-top-0 tw-right-0 tw-font-semibold">
                      <i
                        className={`tw-font-bold bi ${
                          graphScale
                            ? "bi-arrow-up-right tw-text-green-500"
                            : "bi-arrow-down-right tw-text-red-500"
                        }`}
                      ></i>
                      <p className="tw-mb-0 tw-text-gray-500">(Percentage)%</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {msgType !== 2 && (
        <div className="dashboard-graph-box">
      <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
      {adminGraph.templateResult &&
            adminGraph.templateResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-pie">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}  
            </div>
          )}  
      <DemographicsSection graphData={readerGraph} />
      <HourlyDataSection  graphData={hourlyDataGraph} />

      <div className="tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mb-3">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between mb-3">
          <div className=" tw-w-full">
            <div className="form-group" style={{ width: "40%" }}>
              <input
                type="text"
                placeholder="Search by CareNavigator or Supervisor Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control input-field-ui ps-5"
              />
              <img
                src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                className="input-icon-seacrh"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-auto">
            <button
              onClick={handleClear}
              className="btn btn-primary btn-primary clear-search-btn"
            >
              Clear
            </button>
          </div>
          <div className="col-md-auto px-1">
            {processedData && (
              <button
                onClick={() => handleExport()}
                className="btn btn-primary btn-primary clear-search-btn"
              >
                <i className="bi bi-download"></i> Export
              </button>
            )}
          </div>
        </div>


        <BackendDrivenDataTable
          data={processedData}
          columns={columns}
          totalRows={totalRows}
          perPage={perPage}
          page={page}
          setPage={setPage}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default CareCoordinateAnalytics;
