import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import firebase from './firebase/firebase';
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import './App.css';
import Protected from './Protected';

import LoginPage from './pages/LoginPage';
import Logout from './pages/Logout';
import ValidatePage from './pages/utils/ValidatePage';
import ProgramsPage from './pages/mtp/program/ProgramsPage';
import ConfigurationListPage from './pages/componenents/ConfigurationListPage';
import UploadMemberDetailsPage from './pages/rcm/UploadMemberDetailsPage';
import ReportsPage from './pages/rcm/ReportsPage';
import ConfigurationPage from './pages/mtp/configurationpage/ConfigurationPage';
import UserManagementPage from './pages/mtp/usermanagement/UserManagementPage';
import MemberCommunicationsPage from './pages/mtp/membercommunication/MemberCommunicationsPage';
import CareNavigatorPage from './pages/CareNavigatorPage';
import ProgramPerformancePage from './pages/ProgramPerformancePage';
import CareNavigatorPerformancePage from './pages/CareNavigatorPerformancePage';
import ContentManagementPage from './pages/ContentManagementPage';
import ChallengesPage from './pages/ChallengesPage';
import ContentAddUserManagement from './pages/componenents/ContentAddUserManagement';
import AddProgramsPage from './pages/mtp/program/AddProgramsPage';
import ContentEditUserManagement from './pages/componenents/ContentEditUserManagement';
import CareAddNewUser from './pages/medengage/ContentUserManagement/CareAddNewUser';
import NoAccess from './pages/NoAccess';
import PathNotResolved from './pages/PathNotResolved';
import ContentServiceList from './pages/componenents/ContentPrograms/ContentServiceList';
import MemberEngagementPage from './pages/met/engagementpage/MemberEngagementPage';
import MemberDeEnrollmentPage from './pages/MemberDeEnrollmentPage';
import AddNewCareNavigator from './pages/componenents/ContentCareNavigator/AddNewCareNavigator';
import ContentMemberEngagementDetails from './pages/componenents/ContentMemberEngagement/ContentMemberEngagementDetails';
import ContentMemberEngagementList from './pages/componenents/ContentMemberEngagement/ContentMemberEngagementList';
import ContentCallDispositionDetails from './pages/componenents/ContentMemberEngagement/ContentCallDispositionDetails';
import ContentCallHistory from './pages/componenents/ContentMemberEngagement/ContentCallHistory';
import ContentCallHistoryAgent from './pages/componenents/ContentMemberEngagement/ContentCallHistoryAgent';
import MemberEngagmentChallengePage from './pages/met/challenges/MemberEngagmentChallengePage';
import MemberConfigurationListPage from './pages/met/configuration/MemberConfigurationListPage';
import MemberConnectAgent from './pages/met/memberconnect/MemberConnectAgent';
import MemberCommunications from './pages/met/membercommunication/MemberCommunications';
//import MemberConnectAgent from './pages/MemberConnectAgent';
import ContentCareNavigatorPerformanceList from './pages/componenents/ContentCareNavigatorPerformance/ContentCareNavigatorPerformanceList';
import MemberTransferPage from './pages/MemberTransferPage';
import MemberManagement from './pages/met/usermanagement/UserManagementPage';
import ContentAddMemberManagement from './pages/met/components/ContentAddNewUser';
import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import RCMUserManagement from './pages/rcm/usermanagement/UserManagementPage';
import RCMContentMemberManagement from './pages/rcm/components/ContentAddNewUser';
import RCMContentEditMemberManagement from './pages/rcm/components/ContentEditUserManagement';
// import ContentEditMemberManagement from './pages/met/components/ContentEditUserMangement';
import AddNewServices from './pages/componenents/ContentPrograms/AddNewServices';
import MedEngageUerManagement from './pages/medengage/usermanagement/UserManagementPage';
import MedEngageContentMemberManagement from './pages/medengage/components/ContentAddNewUser';
import MedEngageEditMemberManagement from './pages/medengage/components/ContentEditUserManagement';
import ConfigurationSettingsAgentCalls from './pages/met/configurationsettings/ConfigurationSettingsAgentCalls';
import ConfigurationSettingsCareNavigatorCalls from './pages/met/configurationsettings/ConfigurationSettingsCareNavigatorCalls';
import DashobardGraphs from './pages/DashboardGraphs';
import CNGraphs from './pages/CNGraphs';
import CareNavigatorCommunications from './pages/medengage/CareNavigatorCommunications/CareNavigatorCommunications';
import MemberProgress from './pages/medengage/MemberProgress/MemberProgress';

import CareNavigatorReports from './pages/CareNavigatorReports';
import SettingDispositionAgent from './pages/met/components/SettingDispositionAgent';
import MemberRegisterLaterPage from './pages/met/registerLater/MemberRegisterLaterPage';
//cn 
import Dashboard from './pages/cn/dashboard/Dashboard';
import Members from './pages/cn/members/Members';
import MemberProfile from './pages/MemberProfile/MemberProfile';
import { UserTabsProvider } from './pages/cn/components/common/UserTabsContext';
import Draggable from 'react-draggable';
import IncommingAudioCall from './pages/cn/components/draggableCall/IncommingAudioCall';
import CoachCallAudio from './pages/cn/components/draggableCall/CoachCallAudio';
import { decruptCipherString } from "./pages/utils/CommonLibrary";
import RCMInsight from './pages/Insight/components/InsightHome/RCMInsight';
import EndToEndDaily from './pages/Insight/components/InsightHome/EndToEndDaily';
import InsightAddNewUser from './pages/Insight/components/InsightAddNewUser/InsightAddNewUser';
import InsightUserManagementDetails from './pages/Insight/components/InsightUserManagementDetails/InsightUserManagementDetails';
import InsightEditUser from './pages/Insight/components/InsightEditUser/InsightEditUser';

import HomeScreen from './pages/RewardCentral/HomeScreen';
import LoyaltyProgram from './pages/RewardCentral/LoyaltyProgram';
import RewardPoints from './pages/RewardCentral/RewardPoints';
import CategoryMaster from './pages/RewardCentral/CategoryMaster';
import ProductMaster from './pages/RewardCentral/ProductMaster';
import ActivityManagement from './pages/RewardCentral/ActivityManagement';

import SupportTeamDashboard from './pages/SupportTeamDashboard';
import ApproversDashboard from './pages/ApproversDashboard';
import ObservationReportersDashboard from './pages/ObservationReportersDashboard';

import WhatsAppTemplates from './pages/WhatsAppTemplates/WhatsAppTemplates';
import CreateNewTemplate from './pages/WhatsAppTemplates/CreateNewTemplate';
import ContentAgentReports from './pages/met/Reports/ContentAgentReports';
import CNCalendar from './pages/medengage/cncalendar/CNCalendar';

import UploadMemberFile from './pages/RPA/UploadMemberFile';

import Notification from './pages/Notification-dashboard/Notification';
import CampaignDetails from './pages/Notification-dashboard/CampaignDetails';
import CreateCampaign from './pages/Notification-dashboard/CreateCampaign';

import CreateChallenges from './pages/CorpWellness360/CreateChallenges';
import CorporateChallenges from './pages/CorpWellness360/CorporateChallenges';
import CreateLeaderboard from './pages/CorpWellness360/CreateLeaderboard';
import ChallengeReport from './pages/CorpWellness360/ChallengeReport';
import { useSelector } from 'react-redux';
import ViewNewTemplate from './pages/WhatsAppTemplates/ViewNewTemplate';
import EditNewTemplate from './pages/WhatsAppTemplates/EditNewTemplate';
import CnLayout from './pages/componenents/Layout/CnLayout';
import ProgramsDraftPage from './pages/mtp/program/ProgramsDraftPage';
import RCMFileChecker from './pages/componenents/ContentRCMTeamDashboard/RCMFileChecker';
import RCMFileInsight from './pages/componenents/ContentRCMTeamDashboard/RCMFileInsight';
import RemoveUntouchMembers from './pages/componenents/ContentRCMTeamDashboard/RemoveUntouchMembers';

import Inbox from './pages/whatsapp/Inbox/Inbox';
import InboxUR from './pages/whatsapp/Inbox-UR/InboxUR';

import ContentMemberReenrollDetails from './pages/met/reenroll/ContentMemberReenrollDetails';
import RCMRemoveUntouchList from './pages/componenents/ContentRCMTeamDashboard/RCMRemoveUntouchList';
import ProritizePool from './pages/met/prioritizepool/ProritizePool';
import ProritizePoolList from './pages/met/prioritizepool/ProritizePoolList';
import CreateTemplateMet from './pages/met/whatspp/CreateTemplateMet';
import ContentUserManagementDetailsWhatapp from './pages/whatsapp/ContentUserManagementDetailsWhatapp';
import ContentAddNewUserWhatapp from './pages/whatsapp/ContentAddNewUserWhatapp';
import AnalyticsDashboard from './pages/whatsapp/AnalyticsWhatsApp/AnalyticsDashboard';
import AllObservations from './pages/Observations/pages/AllObservations/AllObservations';
import AddObservation from './pages/Observations/pages/AddObservation/AddObservation';
import ViewObservation from './pages/Observations/pages/ViewObservation/ViewObservation';
import CareNavigatorAppointments from './pages/medengage/CareNavigatorAppointments/CareNavigatorAppointments';


import ContentEditUserWhatapp from './pages/whatsapp/ContentEditUserWhatapp';
import { useDispatch } from 'react-redux';
import { setMember } from './store/memberSlice';
import MemberProfileHCC from './pages/hcc/Pages/MemberProfile/MemberProfileHCC';
import MemberManagementHcc from './pages/hcc/Pages/UserManagement/MemberManagement/MemberManagement';
import AddMemberHcc from './pages/hcc/Pages/UserManagement/AddMember/AddMember';
import EditMemberHcc from './pages/hcc/Pages/UserManagement/EditMember/EditMember';
import ObservationLayout from './pages/componenents/Layout/ObservationLayout';
import ApiInsight from './pages/cn/dashboard/ApiInsight';
import CNCallDisposition from './pages/medengage/CareNavigatorSettings/CNCallDisposition';
import CareNavigatorCallsHistory from './pages/medengage/CareNavigatorCallsHistory/CareNavigatorCallsHistory';

import CnInbox from "./pages/cn/components/CNInbox/CnInbox"
import AllTickets from './pages/hcc/Pages/AllTickets/AllTickets';
import RaiseNewTicket from './pages/hcc/Pages/RaiseNewTicket/RaiseNewTicket';
import AddTicketType from './pages/hcc/Pages/AddTicketType/AddTicketType';
import ViewTicket from './pages/hcc/Pages/ViewTicket/ViewTicket';

// import ReactFlowMain from './pages/WhatsAppAutomation/ReactFlowMain';
import AutomationChatbotDashboard from './pages/WhatsAppAutomation/AutomationChatbotDashboard';
import MemberTeleHealth from './pages/met/EngageHealth/MemberTeleHealth';
import MemberProfileMC from './pages/met/memberconnect/MemberProfileMC';
import MemberConnectAgentLayout from './pages/componenents/Layout/MemberConnect/MemberConnectAgentLayout';
import TelehealthInsights from './pages/met/EngageHealth/TelehealthInsights';
import CareCoordinateWhatsAppTabs from './pages/componenents/CareCoordinateWhatsApp/CareCoordinateWhatsAppTabs';
import TemplatePreviewApproval from './pages/componenents/CareCoordinateWhatsApp/TemplatePreviewApproval';
import HccReports from './pages/hcc/Pages/Reports/HccReports';
import CNSettings from './pages/medengage/CareNavigatorSettings/CNSettings';
import DataUploadHistory from './pages/hih/DataUploadHistory';
import HIHReports from './pages/hih/HIHReports';
import HIHUserManagement from './pages/hih/HIHUserManagement';
import ProcessedDataInsights from './pages/hih/ProcessedDataInsights';
import MemberAdminWAAnalytics from './pages/met/AnalyticsWhatsAppMembers/MemberAdminWAAnalytics';
import MemberOnboarding from './pages/medengage/MemberOnboarding/MemberOnboarding';
import RecommendationHistory from './pages/hih/RecommendationHistory';

//start ApiHub dashboard
import ApiHubHome from './pages/apihub/ApiHubHome';
//end ApiHub dashboard


// import CNSettings from './pages/medengage/CareNavigatorSettings/CNSettings';
import ConnectInbox from './pages/met/MemberConnectInbox/ConnectInbox';
import FlowDetail from './pages/WhatsAppAutomation/FlowDetail';


import RPMDashboard from './pages/RPMDashboard/pages/Dashboard/MainPage';
import RPMMembeProfile from './pages/RPMDashboard/pages/Profile/RPMMembeProfile';
import ManageRPMUsers from './pages/RPMDashboard/pages/UserManagement/ManageRPMUsers';
import EditNewMemberRPM from './pages/RPMDashboard/pages/UserManagement/EditNewMemberRPM';
import AddNewMemberRPM from './pages/RPMDashboard/pages/UserManagement/AddNewMemberRPM';

function App() {

  const webex = useSelector(state => state.webex);
  let id = useCnID();
  const dispatch = useDispatch();

  // incomming call 
  const [shouldShowIncomming, setShouldShowIncomming] = useState(false);
  const [isAcceptedCall, setIsAcceptedCall] = useState(false);
  const [inConfNumber, setInconfNumber] = useState(null);
  const [cnID, setCnID] = useState(id);
  const [incommingMemberName, setIncommingMemberName] = useState("");
  const [incommingAgentName, setIncommingAgentName] = useState("");
  const [incommingMemberId, setIncommingMemberId] = useState("");

  // outgoing call 
  const [showCallBlock, setShowCallBlock] = useState(false);
  const [showDisposition, setShowDisposition] = useState(false);
  const [outgoingCallMemberInfo, setOutGoingCallMemberInfo] = useState({
    userId: "",
    memberName: "",
    mobile: "",
    callId: null
  });

  // open outoing call window
  function openOutGoingCallWindow(info) {
    console.log('info: ', info);
    if (showCallBlock) return;
    dispatch(setMember(info));
    const { mobileNo: mobile, memberName, userId, extension, callId } = info;
    setOutGoingCallMemberInfo({
      mobile, memberName, userId, extension, callId
    });
    setShowCallBlock(true);
  }

  // close the call window
  function changeCallBlockDisplayStatus(status) {
    setShowCallBlock(status);
  }

  // trigger disposition 
  function triggerDisposition() {
    setShowDisposition(true);
  }




  // useEffect(() => {

  //   const fetchData = async () => {  

  //     const database = getDatabase(firebase);
  //     // just read values from this database 
  //     const dbRef = ref(database);
  //     const realtimeRef = ref(database, `carenavigator/${cnID}`);

  //     // write a onchange 
  //     onValue(realtimeRef, (snapshot) => {
  //       const data = snapshot.val();

  //       if (data) {
  //         // console.log(data);
  //         setInconfNumber(data.conference.confNumber);
  //         setIncommingMemberName(data.conference.memberName);
  //         setIncommingAgentName(data.conference.agentName);
  //         setShouldShowIncomming(true);
  //         setIncommingMemberId(data.conference.memberId);
  //         // playRingtone(); 
  //       } else {
  //         setShouldShowIncomming(false);
  //       }

  //     });

  //     get(child(dbRef, `carenavigator/${cnID}`)).then((snapshot) => {
  //       if (snapshot.exists()) {
  //         // console.log(snapshot.val());
  //       } else {
  //         // console.log("No data available");
  //       }
  //     }).catch((error) => {
  //       console.error(error);
  //     });
  //   }

  //   if (cnID) fetchData();

  // }, []);





  return (
    <>

      {
        showCallBlock &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <CoachCallAudio userId={outgoingCallMemberInfo.userId} memberName={outgoingCallMemberInfo.memberName} changeCallBlockDisplayStatus={changeCallBlockDisplayStatus} triggerDisposition={triggerDisposition} mobileNo={outgoingCallMemberInfo.mobile} extensionNo={outgoingCallMemberInfo.extension} callId={outgoingCallMemberInfo.callId} />
          </div>
        </Draggable>
      }

      {
        webex.isIncommingCall &&
        <Draggable bounds="body">
          <div className="box-draggable">
            <IncommingAudioCall incommingConfNumber={inConfNumber}
              isAcceptedCall={isAcceptedCall} memberName={incommingMemberName} agentName={incommingAgentName}
              setIsAcceptedCall={setIsAcceptedCall} setShouldShowIncomming={setShouldShowIncomming} incommingMemberId={incommingMemberId}
              cnId={cnID}
              callerInfo={webex.callerInfo}
            />

          </div>
        </Draggable>
      }


      <UserTabsProvider>

        <Routes>
          <Route path="/">

            {/* Starts Common */}
            <Route exact path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/validate" element={<ValidatePage />} />
            <Route path="/no-access" element={<NoAccess />} title='Invalid Request' />
            <Route path="/logout" element={<Logout />} />
            <Route path="/api-insight" element={<ApiInsight />} />
            <Route path="*" element={<PathNotResolved />} />
            {/* Ends Common */}

            {/* Starts DataMigrate */}
            <Route path="/upload-member-details" element={<Protected Component={UploadMemberDetailsPage} title='Upload Member Details' pathNew="upload-member-details" />} />
            <Route path="/reports" element={<Protected Component={ReportsPage} title='Reports Overview' pathNew="reports" />} />
            <Route path="/approval-reports" element={<Protected Component={RCMFileChecker} title='Upload File Checker' pathNew="approval-reports" />} />
            <Route path='/member-management-rcm' element={<Protected Component={RCMUserManagement} title='User Management' pathNew="member-management-rcm" />} />
            <Route path="/add-new-user-rcm" element={<Protected Component={RCMContentMemberManagement} title='Add New Member' pathNew="add-new-user-rcm" />} />
            <Route path='/edit-new-member-rcm/:id' element={<Protected Component={RCMContentEditMemberManagement} title='Edit New Member' pathNew="edit-new-member-rcm" />} />
            <Route path="/file-insight/:id?" element={<Protected Component={RCMFileInsight} title='Member Transfer' pathNew="file-insight" />} />
            <Route path="/remove-untouch" element={<Protected Component={RemoveUntouchMembers} title='Remove Untouch Members' pathNew="remove-untouch" />} />
            <Route path="/remove-untouch-list" element={<Protected Component={RCMRemoveUntouchList} title='Upload File Checker' pathNew="remove-untouch-list" />} />

            <Route element={<ObservationLayout />}>
              <Route path='/rcm-observations' element={<Protected Component={AllObservations} title='Observations' childrenProps={{ paths: { add: '/add-rcm-observation', view: '/rcm-observations' }, role: 'user' }} pathNew="rcm-observations" />} />
              <Route path='/add-rcm-observation' element={<Protected Component={AddObservation} title='Add Observation' pathNew="add-rcm-observation" childrenProps={{ paths: { observations: '/rcm-observations' }, role: 'user' }} />} />
              <Route path='/rcm-observations/:observationId' element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/rcm-observations' }, role: 'user' }} title='View Observation' pathNew="rcm-observations" />} />
            </Route>
            {/* Ends DataMigrate */}

            {/* Starts MediCareManage */}
            <Route path="/programs" element={<Protected Component={ProgramsPage} title='Programs' pathNew="programs" />} />
            <Route path="/draft-programs" element={<Protected Component={ProgramsDraftPage} title='Programs' />} />
            <Route path="/configuration-list" element={<Protected Component={ConfigurationListPage} title='Configuration List' pathNew="configuration-list" />} />
            <Route path="/configuration" element={<Protected Component={ConfigurationPage} title='Configuration' pathNew="configuration" />} />
            <Route path="/user-management" element={<Protected Component={UserManagementPage} title='User Management' pathNew="user-management" />} />
            <Route path="/member-communications" element={<Protected Component={MemberCommunicationsPage} title='Member Communications' pathNew="member-communications" />} />
            <Route path="/add-new-user" element={<Protected Component={ContentAddUserManagement} title='Add New user' pathNew="add-new-user" />} />
            <Route path="/add-program" element={<Protected Component={AddProgramsPage} title='Add Programs' pathNew="add-program" />} />
            <Route path="/add-service/:pathwayId" element={<Protected Component={ContentServiceList} title='Add Programs' pathNew="add-service" />} />
            <Route path="/edit-new-user/:userId" element={<Protected Component={ContentEditUserManagement} title='Edit New user' pathNew="edit-new-user" />} />
            <Route path="/add-new-service" element={<Protected Component={AddNewServices} title='Add New Service' pathNew="add-new-service" />} />

            <Route element={<ObservationLayout />}>
              <Route path='/mcm-observations' element={<Protected Component={AllObservations} title='Observations' childrenProps={{ paths: { add: '/add-mcm-observation', view: '/mcm-observations' }, role: 'user' }} pathNew="mcm-observations" />} />
              <Route path='/add-mcm-observation' element={<Protected Component={AddObservation} title='Add Observation' pathNew="add-mcm-observation" childrenProps={{ paths: { observations: '/mcm-observations' }, role: 'user' }} />} />
              <Route path='/mcm-observations/:observationId' element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/mcm-observations' }, role: 'user' }} title='View Observation' pathNew="mcm-observations" />} />
            </Route>

            {/* Ends MediCareManage */}

            {/* Starts CareCoordinate */}
            <Route path="/carenavigator-call-history" element={<Protected Component={CareNavigatorCallsHistory} title="CareNavigator Call History" pathNew="carenavigator-call-history" />} />
            <Route path="/member-profile" element={<Protected Component={MemberProfile} title="Member Profile" pathNew="member-profile" />} />
            {/*<Route path="/carenavigator-call-dispositions" element={<Protected Component={CNCallDisposition} title='CareNavigator Call Dispositions' pathNew="carenavigator-call-dispositions" />} />*/}
            <Route path="/carenavigator-call-dispositions" element={<Protected Component={CNSettings} title='CareNavigator Settings' pathNew="carenavigator-call-dispositions" />} />
            <Route path="/member-onboarding" element={<Protected Component={MemberOnboarding} title='Member Onboarding' pathNew="member-onboarding" />} />


            <Route path="/care-navigator" element={<Protected Component={CareNavigatorPage} title='Care Navigator Management' pathNew="care-navigator" />} />
            <Route path="/add-care-navigator/:id?" element={<Protected Component={AddNewCareNavigator} title='Add New Navigator Management' pathNew="add-care-navigator" />} />
            <Route path="/edit-care-navigator/edit/:id?" element={<Protected Component={AddNewCareNavigator} title='Edit Navigator Management' pathNew="edit-care-navigator" />} />
            <Route path="/program-performance" element={<Protected Component={ProgramPerformancePage} title='Population Compliance & Success' pathNew="program-performance" />} />
            <Route path="/care-navigator-performance" element={<Protected Component={CareNavigatorPerformancePage} title='Care Navigator Performance' pathNew="care-navigator-performance" />} />
            <Route path="/care-navigator-communications" element={<Protected Component={CareNavigatorCommunications} title='Care Navigator Communications' pathNew="care-navigator-communications" />} />
            <Route path="/care-call-details" element={<Protected Component={ContentCareNavigatorPerformanceList} title='Care Call Details' pathNew="care-call-details" />} />
            <Route path="/content-management" element={<Protected Component={ContentManagementPage} title='Content Management' pathNew="content-management" />} />
            <Route path='/cn-graphs' element={<Protected Component={CNGraphs} title='cn Graphs' pathNew="cn-graphs" />} />
            <Route path='/member-management-medengage' element={<Protected Component={MedEngageUerManagement} title='User Management' pathNew="member-management-medengage" />} />
            <Route path="/add-new-user-medengage" element={<Protected Component={MedEngageContentMemberManagement} title='Add New Member' pathNew="add-new-user-medengage" />} />
            <Route path='/edit-new-member-medengage/:id' element={<Protected Component={MedEngageEditMemberManagement} title='Edit New Member' pathNew="edit-new-member-medengage" />} />
            <Route path="/member-de-enrollment" element={<Protected Component={MemberDeEnrollmentPage} title='Member De-Enrollment' pathNew="member-de-enrollment" />} />
            <Route path="/member-transfer/:id?" element={<Protected Component={MemberTransferPage} title='Member Transfer' pathNew="member-transfer" />} />
            <Route path='/care-navigator-reports' element={<Protected Component={CareNavigatorReports} title='Care Navigator Reports' pathNew="care-navigator-reports" />} />
            <Route path='/member-data' element={<Protected Component={MemberProgress} title='Care Navigator Reports' pathNew="member-data" />} />
            <Route path='/care-navigator-appointments' element={<Protected Component={CareNavigatorAppointments} title='Care Navigator Appointments' pathNew="care-navigator-appointments" />} />
            <Route element={<ObservationLayout />}>
              <Route path='/cc-observations' element={<Protected Component={AllObservations} title='Care Navigator Observations' childrenProps={{ paths: { add: '/add-cc-observation', view: '/cc-observations' }, role: 'user' }} pathNew="cc-observations" />} />
              <Route path='/add-cc-observation' element={<Protected Component={AddObservation} title='Add Care Navigator Observation' childrenProps={{ paths: { observations: '/cc-observations' }, role: 'user' }} pathNew="add-cc-observation" />} />
              <Route path='/cc-observations/:observationId' element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/cc-observations' }, role: 'user' }} title='View Care Navigator Observation' pathNew="cc-observations" />} />
            </Route>
            <Route path='/care-coordinate-whatsapp' element={<Protected Component={CareCoordinateWhatsAppTabs} title='CareCoordinate WhatsApp Management' pathNew="care-coordinate-whatsapp" />} />
            <Route path='/care-coordinate-review-template/:id' element={<Protected Component={TemplatePreviewApproval} title='CareCoordinate WhatsApp Management' pathNew="care-coordinate-whatsapp" />} />

            {/* Ends CareCoordinate */}


            {/* Starts MemberConnect */}
            <Route path="/member-insight" element={<Protected Component={ContentCallHistory} title='Member Engagement (Phase 1)' pathNew="member-insight" />} />
            <Route path="/member-insight-agent" element={<Protected Component={ContentCallHistoryAgent} title='Member Engagement (Phase 1)' pathNew="member-insight-agent" />} />
            <Route path="/member-inbox-agent" element={<Protected Component={ConnectInbox} title='Member Engagement (Phase 1)' pathNew="member-inbox-agent" />} />
            <Route path="/challenges" element={<Protected Component={ChallengesPage} title='Challenges' pathNew="challenges" />} />
            <Route path="/member-engagement1" element={<Protected Component={ContentMemberEngagementDetails} title='Member Engagement Mannual Phase 1' pathNew="member-engagement1" />} />
            <Route path="/member-engagement-agent" element={<Protected Component={ContentMemberEngagementList} title='Member Engagement Mannual Phase 1' pathNew="member-engagement-agent" />} />
            <Route path="/member-engagement" element={<Protected Component={MemberEngagementPage} title='Member Engagement' pathNew="member-engagement" />} />
            <Route path="/member-configuration" element={<Protected Component={MemberConfigurationListPage} title='Member Engagement' pathNew="member-configuration" />} />
            <Route path="/membercommunications" element={<Protected Component={MemberCommunications} title='Member Communications' pathNew="membercommunications" />} />
            <Route path='/call-setting' element={<Protected Component={ConfigurationSettingsAgentCalls} title='Configuration Settings Agent Calls' pathNew="call-setting" />} />
            <Route path="/member-challenges" element={<Protected Component={MemberEngagmentChallengePage} title='Member Challenges' pathNew="member-challenges" />} />
            <Route path="/member-call-disposition" element={<Protected Component={ContentCallDispositionDetails} title='Member Call Disposition' pathNew="member-call-disposition" />} />
            <Route path="/member-call-history" element={<Protected Component={ContentCallHistory} title='Member Call History' pathNew="member-call-history" />} />
            <Route path='/member-management' element={<Protected Component={MemberManagement} title='Member Management' pathNew="member-management" />} />
            <Route path="/add-new-member" element={<Protected Component={ContentAddMemberManagement} title='Add New Member' pathNew="add-new-member" />} />
            <Route path='/edit-new-member/:userId' element={<Protected Component={ContentEditMemberManagement} title='Edit New Member' pathNew="edit-new-member" />} />


            <Route path="/mc-member-profile" element={<Protected Component={MemberProfileMC} title="Member Journey" pathNew="mc-member-profile" />} />

            <Route path='/call-settings-care-navigator-calls' element={<Protected Component={ConfigurationSettingsCareNavigatorCalls} title='Configuration Settings for Care Navigator Calls' pathNew="call-settings-care-navigator-calls" />} />
            <Route path='/setting-disposition-agent' element={<Protected Component={SettingDispositionAgent} title='Setting Disposition Agent' pathNew="setting-disposition-agent" />} />
            <Route path="/register-later" element={<Protected Component={MemberRegisterLaterPage} title='Member De-Enrollment' pathNew="register-later" />} />
            <Route path='/agent-reports' element={<Protected Component={ContentAgentReports} title='Agent Reports' pathNew="agent-reports" />} />
            <Route path='/reenroll-details' element={<Protected Component={ContentMemberReenrollDetails} title='Member Re-enroll' pathNew="reenroll-details" />} />
            <Route path='/proritize-pool' element={<Protected Component={ProritizePool} title='Proritize Pool' pathNew="proritize-pool" />} />
            <Route path="/proritize-pool-list" element={<Protected Component={ProritizePoolList} title='Upload File Checker' pathNew="proritize-pool-list" />} />
            <Route path="/whatsapp-admin" element={<Protected Component={CreateTemplateMet} title='Upload File Checker' pathNew="whatsapp-admin" />} />
            <Route path="/proritize-pool-list" element={<Protected Component={ProritizePoolList} title='Upload File Checker' pathNew="proritize-pool-list" />} />
            <Route path="/telehealth-insights" element={<Protected Component={TelehealthInsights} title='TeleHealth Insights' pathNew="telehealth-insights" />} />

            <Route element={<MemberConnectAgentLayout />} >
              <Route path="/member-connect" element={<Protected Component={MemberConnectAgent} title='Member Connect Agent' pathNew="member-connect" />} />
              <Route path="/member-telehealth" element={<Protected Component={MemberTeleHealth} title='Member TeleHealth' pathNew="member-telehealth" />} />
              <Route path="/member-insight-agent" element={<Protected Component={ContentCallHistoryAgent} title='Member Engagement (Phase 1)' pathNew="member-insight-agent" />} />
              <Route path="/member-whatsapp-analytics" element={<Protected Component={MemberAdminWAAnalytics} title='Member WhatsApp Analytics' pathNew="member-whatsapp-analytics" />} />
            </Route>

            <Route element={<ObservationLayout />}>
              <Route path="/memberconnect-observations" element={<Protected Component={AllObservations} title="Observations" pathNew="memberconnect-observations" childrenProps={{ paths: { add: '/add-memberconnect-observation', view: '/memberconnect-observations' }, role: 'user' }} />} />
              <Route path="/add-memberconnect-observation" element={<Protected Component={AddObservation} title="Add Observation" childrenProps={{ paths: { observations: '/memberconnect-observations' }, role: 'user' }} pathNew={"add-memberconnect-observation"} />} />
              <Route path="/memberconnect-observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/memberconnect-observations' }, role: 'user' }} title="View Observation" pathNew={"memberconnect-observations"} />} />
            </Route>

            {/* Ends MemberConnect */}

            {/* Starts carenavigator */}
            <Route element={<CnLayout />}>
              <Route path="/dashboard" element={<Protected Component={Dashboard} title="cnDashboard" pathNew="dashboard" />} />
              <Route path="/carenavigator-whatsapp-inbox" element={<Protected Component={CnInbox} title="cnDashboard" pathNew="carenavigator-whatsapp-inbox" />} />
              <Route path="/members/:memberId" element={<Protected Component={Members} title="cnDashboard" childrenProps={{ openOutGoingCallWindow, showDisposition, setShowDisposition, showCallBlock }} pathNew="members" />} />

            </Route>
            {/* Ends carenavigator */}


            {/* Starts Insight */}
            <Route path="/end-to-end-dashboard" element={<Protected Component={RCMInsight} title="Insight Home" pathNew="end-to-end-dashboard" />} />
            <Route path="/end-to-end-daily" element={<Protected Component={EndToEndDaily} title="Insight Home" pathNew="end-to-end-daily" />} />
            <Route path='/member-management-end' element={<Protected Component={InsightUserManagementDetails} title='User Management' pathNew="member-management-end" />} />
            <Route path="/add-new-user-end" element={<Protected Component={InsightAddNewUser} title='Add New Member' pathNew="add-new-user-end" />} />
            <Route path='/edit-new-member-end/:id' element={<Protected Component={InsightEditUser} title='Edit New Member' pathNew="edit-new-member-end" />} />

            <Route element={<ObservationLayout />}>
              <Route path="/end-to-end-observations" element={<Protected Component={AllObservations} title="End to End Observations" childrenProps={{ paths: { add: '/add-end-to-end-observation', view: '/end-to-end-observations' }, role: 'user' }} pathNew="end-to-end-observations" />} />
              <Route path="/add-end-to-end-observation" element={<Protected Component={AddObservation} title="Add End to End Observation" childrenProps={{ paths: { observations: '/end-to-end-observations' }, role: 'user' }} pathNew={"add-end-to-end-observation"} />} />
              <Route path="/end-to-end-observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/end-to-end-observations' }, role: 'user' }} title="View End to End Observation" pathNew={"end-to-end-observations"} />} />
            </Route>
            {/* Ends Insight */}

            {/* Starts WhatsApp Templates */}
            <Route path="/whatsApp-templates" element={<Protected Component={WhatsAppTemplates} title="WhatsApp Templates" pathNew="whatsApp-templates" />} />
            <Route path="/create-new-template" element={<Protected Component={CreateNewTemplate} title="Create New Template" pathNew="create-new-template" />} />
            <Route path="/view-template/:id" element={<Protected Component={ViewNewTemplate} title="View Template" pathNew="view-template" />} />
            <Route path="/review-template/:id" element={<Protected Component={ViewNewTemplate} title="Review Template" pathNew="review-template" />} />
            <Route path="/edit-template/:id" element={<Protected Component={EditNewTemplate} title="Edit Template" pathNew="edit-template" />} />

            {/* Starts WhatsApp Templates */}

            {/* Starts Reward Central System */}
            <Route path="/home-screen" element={<Protected Component={HomeScreen} title="Home Screen" />} />
            <Route path="/loyalty-program" element={<Protected Component={LoyaltyProgram} title="Loyalty Program" />} />
            <Route path="/reward-points" element={<Protected Component={RewardPoints} title="Reward Points" />} />
            <Route path="/category-master" element={<Protected Component={CategoryMaster} title="Category Master" />} />
            <Route path="/product-master" element={<Protected Component={ProductMaster} title="Product Master" />} />
            <Route path="/activity-management" element={<Protected Component={ActivityManagement} title="Activity Management" />} />
            {/* Ends Reward Central System */}

            {/* Starts Support Team Dashboard */}
            <Route path="/support-team-dashboard" element={<Protected Component={SupportTeamDashboard} title="Support Team Dashboard" />} />
            <Route path="/approvers-dashboard" element={<Protected Component={ApproversDashboard} title="Approvers Dashboard" />} />
            <Route path="/observation-reporters-dashboard" element={<Protected Component={ObservationReportersDashboard} title="Observation Reporters Dashboard" />} />
            {/* Starts Support Team Dashboard */}

            {/* Starts RPA Dashboard */}
            <Route path="/upload-member-file" element={<Protected Component={UploadMemberFile} title="Upload Member File" pathNew="upload-member-file" />} />
            {/* End RPA Dashboard */}

            {/* Starts RPM Dashboard */}
            <Route path="/rpm-dashboard" element={<Protected Component={RPMDashboard} title="RPM Dashboard" pathNew="rpm-dashboard" />} />
            <Route path="/rpm-member-profile/:id" element={<Protected Component={RPMMembeProfile} title="RPM Membe Profile" pathNew="rpm-member-profile" />} />
            <Route path="/rpm-member-management" element={<Protected Component={ManageRPMUsers} title="RPM Membe Management" pathNew="rpm-member-management" />} />
            <Route path="/add-new-member-rpm" element={<Protected Component={AddNewMemberRPM} title="Add New Member" pathNew="add-new-member-rpm" />} />
              <Route path="/edit-new-member-rpm/:id" element={<Protected Component={EditNewMemberRPM} title="Edit New Member" pathNew="edit-new-member-rpm" />} />
            {/* End RPM Dashboard */} 

            {/* Starts CorpWellness360 Dashboard */}
            <Route path="/create-challenges" element={<Protected Component={CreateChallenges} title="Create Challenges" />} />
            <Route path="/corporate-challenges" element={<Protected Component={CorporateChallenges} title="Corporate Challenges" />} />
            <Route path="/create-leaderboard" element={<Protected Component={CreateLeaderboard} title="Create Leaderboard" />} />
            <Route path="/challenge-report" element={<Protected Component={ChallengeReport} title="Challenge Report" />} />
            {/* End CorpWellness360 Dashboard */}


            <Route path='/dashobard-graphs' element={<Protected Component={DashobardGraphs} title='DashobardGraphs' />} />
            {/* <Route path="/member-connect" element={<MemberConnectAgent />} /> */}
            {/* <Route path="/care-add-user" element={<Protected Component={CareAddNewUser} title='User Management'/>} /> */}
            <Route path="/notification-dashboard" element={<Protected Component={Notification} title="Notification Dashboard" pathNew="notification-dashboard" />} />
            <Route path="/campaign-details/:campaignId" element={<Protected Component={CampaignDetails} title="Campaign Details" pathNew="campaign-details" />} />
            <Route path="/create-campaign-whatsapp" element={<Protected Component={CreateCampaign} title="Create Campaign" pathNew="create-campaign-whatsapp" />} />

            {/*==Health Intelligence Hub==*/}
            <Route path="/data-upload-history" element={<Protected Component={DataUploadHistory} title="Data Upload History" pathNew="data-upload-history" />} />
            <Route path="/hih-reports" element={<Protected Component={HIHReports} title="Reports" pathNew="hih-reports" />} />
            <Route path="/hih-user-management" element={<Protected Component={HIHUserManagement} title="Reports" pathNew="hih-reports" />} />
            <Route path="/processed-data-insights/:id?" element={<Protected Component={ProcessedDataInsights} title='Data Review' pathNew="processed-data-insights" />} />
            <Route path="/hih-recommendation-history" element={<Protected Component={RecommendationHistory} title="Service Recommendations History" pathNew="hih-recommendation-history" />} />
            {/*==Health Intelligence Hub Ends==*/}


            {/* WhatsAPP Chat Dashboard */}
            <Route path='/whatsapp-inbox' element={<Protected Component={Inbox} title='Inbox' pathNew="whatsapp-inbox" />} />
            <Route path='/whatsapp-inbox-unreg-user' element={<Protected Component={InboxUR} title='Inbox' pathNew="whatsapp-inbox-unreg-user" />} />
            <Route path='/automation-chatbot-dashboard' element={<Protected Component={AutomationChatbotDashboard} title='Automation Chatbot Dashboard' pathNew="automation-chatbot-dashboard" />} />
            <Route path='/automation-chatbot-dashboard/flow/:flowId' element={<Protected Component={FlowDetail} title='Chatbot Flow Detail' pathNew="automation-chatbot-flow" />} />
            <Route path='/member-management-whatsapp' element={<Protected Component={ContentUserManagementDetailsWhatapp} title='User Management' pathNew="member-management-whatsapp" />} />
            <Route path="/add-new-user-whatsapp" element={<Protected Component={ContentAddNewUserWhatapp} title='Add New Member' pathNew="add-new-user-whatsapp" />} />
            <Route path='/edit-new-member-whatsapp/:id' element={<Protected Component={ContentEditUserWhatapp} title='Edit New Member' pathNew="edit-new-member-whatsapp" />} />

            <Route path='/analytics-dashboard-whatsapp' element={<Protected Component={AnalyticsDashboard} title='Edit New Member' pathNew="analytics-dashboard-whatsapp" />} />

            {/* WhatsApp Chat Dashboard - Observations */}
            <Route element={<ObservationLayout />}>
              <Route path="/whatsapp-observations" element={<Protected Component={AllObservations} childrenProps={{ paths: { add: '/whatsapp-add-observation', view: '/whatsapp-observations' }, role: 'user' }} title="Observations" pathNew={"whatsapp-observations"} />} />
              <Route path="/whatsapp-add-observation" element={<Protected Component={AddObservation} title="Add Observation" childrenProps={{ paths: { observations: '/whatsapp-observations' }, role: 'user' }} pathNew={"whatsapp-add-observation"} />} />
              <Route path="/whatsapp-observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/whatsapp-observations' }, role: 'user' }} title="View Observation" pathNew={"whatsapp-observations"} />} />
            </Route>
            {/* END of WhatsAPP Chat Dashboard */}


            {/* ------------- observations dashboard starts -------------------------- */}
            <Route element={<ObservationLayout />}>
              <Route path="/observations" element={<Protected Component={AllObservations} childrenProps={{ paths: { add: '/add-observation', view: '/observations' }, role: 'moderator' }} title="Observations" pathNew={"observations"} />} />
              <Route path="/add-observation" element={<Protected Component={AddObservation} title="Add Observation" childrenProps={{ paths: { observations: '/observations' }, role: 'moderator' }} pathNew={"add-observation"} />} />
              <Route path="/observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/observations' }, role: 'moderator' }} title="View Observation" pathNew={"observations"} />} />

              {/* Goqii Dashboard */}
              <Route path="/goqii/observations" element={<Protected Component={AllObservations} childrenProps={{ paths: { view: '/goqii/observations' }, role: 'goqii' }} title="All Observations" pathNew={"goqii/observations"} />} />
              <Route path="/goqii/observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/goqii/observations' }, role: 'goqii' }} title="View Observation" pathNew={"goqii/observations"} />} />
            </Route>

            {/* ------------- observations dashboard ends  -------------------------- */}


            {/*Health Care Center*/}
            <Route path="/hc-member-profile" element={<Protected Component={MemberProfileHCC} title="Member Journey" pathNew="hc-member-profile" />} />
            <Route path="/hc-member-management" element={<Protected Component={MemberManagementHcc} title="Member Management" pathNew="hc-member-management" />} />
            <Route path="/add-new-member-hcc" element={<Protected Component={AddMemberHcc} title="Add New Member" pathNew="add-new-member-hcc" />} />
            <Route path='/edit-new-member-hcc/:id' element={<Protected Component={EditMemberHcc} title='Edit New Member' pathNew="edit-new-member-hcc" />} />

            <Route path="/all-tickets" element={<Protected Component={AllTickets} title="All Tickets" pathNew="all-tickets" />} />
            <Route path="/view-ticket/:ticketId" element={<Protected Component={ViewTicket} title="View Ticket" pathNew="view-ticket" />} />
            <Route path="/add-ticket-type" element={<Protected Component={AddTicketType} title="Add Ticket Type" pathNew="add-ticket-type" />}  />
            <Route path="/raise-new-ticket" element={<Protected Component={RaiseNewTicket} title="Raise New Ticket" pathNew="raise-new-ticket" />} />
            <Route path='/hcc-reports' element={<Protected Component={HccReports} title="Reports" pathNew="hcc-reports" />} />

            <Route element={<ObservationLayout />}>
              <Route path="/hcc-observations" element={<Protected Component={AllObservations} title="HCC Observations" childrenProps={{ paths: { add: '/add-hcc-observation', view: '/hcc-observations' }, role: 'user' }} pathNew="hcc-observations" />} />
              <Route path="/add-hcc-observation" element={<Protected Component={AddObservation} title="Add HCC Observation" childrenProps={{ paths: { observations: '/hcc-observations' }, role: 'user' }} pathNew={"add-hcc-observation"} />} />
              <Route path="/hcc-observations/:observationId" element={<Protected Component={ViewObservation} childrenProps={{ paths: { view: '/hcc-observations' }, role: 'user' }} title="View HCC Observation" pathNew={"hcc-observations"} />} />
            </Route>

            {/*==Health Care Center Ends==*/}

            {/* ------------- ApiHubHome dashboard starts  -------------------------- */}
              <Route path="/apihub-home" element={<Protected Component={ApiHubHome} title="ApiHub Home" pathNew="apihub-home" />} />
            {/* ------------- ApiHubHome dashboard ends  -------------------------- */}
          </Route>
        </Routes>
      </UserTabsProvider>
    </>
  );
}

export default App;


// custom hook for getting cnid  
export function useCnID() {
  const careNavigatorID = localStorage.getItem('cnId');
  return careNavigatorID;
}