import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import WhatsAppAnalyticsPie from "./McWhatsAppAnalyticsPie";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { decryptData } from "../../utils/Utils";
import {
  fetch_count,
  // fetch_carenavigator,
  fetch_admin_insights,
  fetch_admins,
  fetchDashboardList,
  fetch_agent_insights,
  fetch_unregistered_insights,
  fetchAgentList,
  fetchReaderGenderGraphAge,
  fetchHourlyDatagraph,
  fetchUserChatList,
  optout_users_download,
} from "../../../services/whatsapp/Service";
import { LoaderLoader } from "../../utils/CommonLibrary";
import AnalyticsTable from "./McAnalyticsTable";
import DemographicsSection from "./McDemographicsSection";
import HourlyDataSection from "./McHourlyDataSection";

const MemberAdminWAAnalytics = () => {
  const [loading, setLoading ] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  // const [seriesGraph, setSeriesGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [agentGraph, setAgentGraph] = useState([]);
  const [readerGraph, setReaderGraph] = useState([]);
  const [hourlyDataGraph, setHourlyDataGraph] = useState([]);
  const [userDataGraph, setUserDataGraph] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowData, setTotalRowData] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const [adminsUsers, setAdminusers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Optional if provided by API

  const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';
  
      const [startDate, setStartDate] = useState(
          storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
      );
      const [endDate, setEndDate] = useState(
          storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
      );

  const [selectedDashboard, setSelectedDashboard] = useState('all');
  const [agentList, setAgentList] = useState([]);
  const [agents, setAgents] = useState("");
  const [role, setRole] = useState("");
  const [msgType, setMsgType] = useState("");
  
  const [memberData, setMemberData] = useState([]);
  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async()=>{
    setLoading(true);
    try {
      let roleId = 'memberconnect agent';
      const resp = await fetchAgentList({roleId:roleId}); // Pass the current page to the API
      if (resp.data.code === 200) {
        setAgentList(resp.data.data);       
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_count({roleId:'memberconnect agent',agents:agents, startDate: start, endDate: end, msgType:msgType, dashboardId :selectedDashboard }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data.insights.length > 0) {
        setAnalyticsData(response.data.data.insights);
      }
      // Assuming the API returns an array of data objects that you need for your dashboard
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };


  const fetch_admin_insights_data = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_admin_insights({
        startDate: start,
        endDate: end,
        roleId:'memberconnect agent',
        agents:agents,
        msgType:msgType,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      console.log("admin response",response.data.data);
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
        console.log("admin response",response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetch_reader_demo_graph = async (start, end) => {
    try {
      const response = await fetchReaderGenderGraphAge({
        startDate: start,
        endDate: end,
        roleId:'memberconnect agent',
        agents:agents,
        msgType:msgType,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setReaderGraph(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_hourly_datagraph_list = async (start,end) => {
    try {
      const response = await fetchHourlyDatagraph({
        startDate: start,
        endDate: end,
        roleId:'memberconnect agent',
        agents:agents,
        msgType:msgType,
        dashboardId :selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setHourlyDataGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchUserChatData = async (start, end) => {
    try {
      const response = await fetchUserChatList({
        startDate: start,
        endDate: end,
        roleId:'memberconnect agent',
        agents:agents,
        msgType:msgType,
        dashboardId :selectedDashboard,
        pageNumber:currentPage,
        perPage:perPage
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setUserDataGraph(response.data.data);
        setTotalRowData(response.data.totalRows);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admins_data = async (start, end, page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await fetch_admins({
        startDate: start,
        endDate: end,
        page: page,
        limit: limit,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminusers(response.data.data.list);
        setTotalRecords(response.data.data.total); // Assuming response includes `total`
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  
  useEffect(() => {
    // Reset to page 1 when filters change but not when only pagination changes
    if (currentPage !== 1) {
      setCurrentPage(1);
      return; // Return to avoid double API calls
    }
    
    fetchData(formatDate(startDate), formatDate(endDate));
    fetch_admin_insights_data(formatDate(startDate), formatDate(endDate));
    fetch_reader_demo_graph(formatDate(startDate), formatDate(endDate));
    fetch_hourly_datagraph_list(formatDate(startDate), formatDate(endDate));
    
    // Don't call fetchUserChatData here - it will be called in the pagination useEffect
  }, [startDate, endDate, agents, msgType, selectedDashboard]); 

  useEffect(() => {
    fetchUserChatData(formatDate(startDate), formatDate(endDate));
  }, [startDate, endDate, agents, msgType, selectedDashboard, currentPage, perPage])

  const handleAgentChange = (e) => {
    setAgents(e.target.value);
    // No need to reset page here as the main useEffect will handle it
  };
  
  const handleMsgTypeChange = (e) => {
    setMsgType(e.target.value);
    // No need to reset page here as the main useEffect will handle it
  };

  // useEffect(() => {
  //   fetch_admins_data(
  //     formatDate(startDate),
  //     formatDate(endDate),
  //     currentPage,
  //     pageLimit
  //   );
  // }, [startDate, endDate, currentPage, pageLimit, selectedDashboard]); // Trigger when either startDate or endDate changes

const handleDownloadOptOutMembers = async () => {
      try {
         setLoading(true);
        // This is a dummy API call that you will replace with the actual one
        const response = await optout_users_download({
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
  
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `OptOutMembers_${formatDate(startDate)}_to_${formatDate(endDate)}.csv`);
  
        // Append, trigger click, and remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading opt-out members:", error);
      } finally {
        setLoading(false);
      }
    };
  
  

  return (
    <div className="container-fluid tw-mb-16">
      <LoaderLoader isLoading={loading} />
      <div className="tw-sticky tw-top-14 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full  tw-pb-3">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-w-full">
            <h4 className="tw-text-gray-600 tw-m-0">Member Connect Admin WhatsApp Analytics</h4>
            <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4">
              <select
                value={agents}
                onChange={handleAgentChange}
                className="form-select tw-rounded-xl"
                style={{
                  width: "50%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                  <option value="">All</option>
                {agentList?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.fullName}
                  </option>
                ))}
              </select>
              <select
                value={msgType}
                onChange={handleMsgTypeChange}
                className="form-select tw-rounded-xl"
                placeholder="Select Dashboard"
                style={{
                  width: "50%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                <option value="0">All</option>
                <option value="1">Template</option>
                <option value="2">Free-Form</option>
              </select>
              <div className="form-group" style={{ width: "50%" }}>
              <CalendarDatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and Agents, including total
              conversations, responses, pending responses, and opt-outs. It
              features visualizations like wait time, average response time,
              opt-out trends, and template usage. A performance table with
              detailed metrics and tooltips helps team leads monitor efficiency
              and member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px] tw-h-full">
              <div className="tw-p-2 tw-relative">
             
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    {(item.title === "Opt-Out Members" && item.value > 0) && (
              <span className=" tw-absolute tw-right-1 tw-top-1 tw-text-[#03335B] tw-cursor-pointer tw-px-1 tw-rounded-lg"
              onClick={handleDownloadOptOutMembers}
              >
                <i class="bi bi-download tw-font-bold"></i>
              </span>
                )}
                    <p className="tw-text-base tw-text-gray-500 tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Template Breakdown Pie Chart */}
      {msgType !== 2 && (
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {adminGraph.templateResult &&
              adminGraph.templateResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-pie">
              <h5 className="emty-title-graph">There are no data to display</h5>
          </div>
            )}
          </div>
        </div>
      </div> )}


      {/* Reader Demographics Section */}
<div className="row tw-my-4">
  <div className="col-md-12 mb-3">
    <div className="dashboard-graph-box mb-3">
      <h1 className="dashboard-graph-title">
        <span>
          Reader Demographics
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="custom-tooltip">
                Distribution of readers by gender and age
              </Tooltip>
            }
          >
            <i className="bx bx-info-circle ms-1"></i>
          </OverlayTrigger>
        </span>
      </h1>
      <DemographicsSection graphData={readerGraph} />
    </div>
  </div>
</div>

<HourlyDataSection  graphData={hourlyDataGraph} />

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                All Data
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      All Data
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
      {/* <AnalyticsTable /> */}
      <AnalyticsTable userDataGraph={userDataGraph} totalRowData={totalRowData} fetchUserChatData={fetchUserChatData} startDate={startDate} endDate={endDate} setCurrentPage={setCurrentPage} currentPage={currentPage} setPerPage={setPerPage} perPage={perPage} />
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default MemberAdminWAAnalytics;
