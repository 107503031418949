import React, {useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function BloodGlucose({ bloodGlucose, bloodGlucoseRandom }) {
    const { current, type, history } = bloodGlucose;
    const [currentType, setCurrentType] = useState('fasting');

    const options = {
        chart: {
            type: 'line',
            height: 260,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: currentType === 'fasting' ? history.map(item => item.date) : bloodGlucoseRandom.history.map(item => item.date),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Blood Glucose'
            },
            min: null,
            max: null,
            plotLines: [{
                value: 150,
                color: '#1279be',
                dashStyle: 'Dash',
                width: 2,
                label: {
                    text: 'Pre-diabetic',
                    align: 'right',
                    style: {
                        color: '#1279be'
                    }
                }
            }, {
                value: 200,
                color: '#03335b',
                dashStyle: 'Dash',
                width: 2,
                label: {
                    text: 'Diabetic',
                    align: 'right',
                    style: {
                        color: '#03335b'
                    }
                }
            }]
        },
        series: [{
            name: 'Days of the Month',
            data: currentType === 'fasting' ? history.map(item => item.value) : bloodGlucoseRandom.history.map(item => item.value),
            color: '#1279be',
            marker: {
                enabled: true,
                radius: 5,
                fillColor: '#1279be'
            }
        }],
        tooltip: {
            valueSuffix: ' mg/dL'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
    };

    return (
        <div className="col-md-12">
            <div className="member-engagement mb-3">
                <div className='row'>
                    <div className='col'>
                        <h1 className='dashboard-graph-title mb-2'>
                            <span>Blood Glucose</span>
                        </h1>
                        <h1 className="card-title1">{current} <span>mg/dl</span></h1>
                    </div>
                    <div className='col-auto'>
                        <div className="tabs">
                            {bloodGlucose?.history?.length > 0 && (
                                <a className={`tab ${currentType === 'fasting' ? 'active-tab' : ''}`} onClick={() => setCurrentType('fasting')}>Fasting</a>
                            )}
                            {bloodGlucoseRandom?.history?.length > 0 && (
                                <a className={`tab ${currentType === 'pp/random' ? 'active-tab' : ''}`} onClick={() => setCurrentType('pp/random')}>PP/Random</a>
                            )}
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

export default BloodGlucose; 