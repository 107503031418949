import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactFlowMain from './ReactFlowMain';
import { chatbotFlowsData } from './dummyData';

const FlowDetail = () => {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const [flowData, setFlowData] = useState(null);

  useEffect(() => {
    if (flowId) {
      const flow = chatbotFlowsData.find(flow => flow.id === flowId);
      if (flow) {
        setFlowData(flow);
      } else {
        // Flow not found, redirect back to the dashboard
        navigate('/automation-chatbot-dashboard');
      }
    }
  }, [flowId, navigate]);

  const handleBackToList = () => {
    navigate('/automation-chatbot-dashboard');
  };

  if (!flowData) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-[80vh]">
        <div className="tw-text-gray-500">Loading flow...</div>
      </div>
    );
  }

  return (
    <div className="container-fluid tw-mb-16">
      <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full tw-pb-3">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4 className="tw-text-gray-600 tw-m-0 tw-font-semibold">Flow Details</h4>
          </div>
        </div>
      </div>

      <div className="tw-bg-white tw-p-4 tw-rounded-md tw-shadow-sm">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <div className="tw-flex tw-items-center tw-gap-2">
            <button
                className="btn btn-primary back-btn mb-0 me-3"
                onClick={handleBackToList}
              >
                <i className="bi bi-chevron-left me-1"></i> Back to Flows
              </button>
            <h5 className="tw-text-gray-700 tw-font-medium tw-m-0">{flowData.name}</h5>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className={`tw-px-3 tw-py-1 tw-rounded-md tw-text-sm tw-inline-block ${
              flowData.status.type === 'sent' ? 'tw-bg-green-100 tw-text-green-800' : 'tw-bg-gray-100 tw-text-gray-600'
            }`}>
              {flowData.status.text}
            </span>
          </div>
        </div>
        
        <p className="tw-text-gray-600 tw-mb-4">{flowData.description}</p>
        
        <ReactFlowMain flowData={flowData} />
      </div>
    </div>
  );
};

export default FlowDetail;