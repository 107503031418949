import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



function SpO2({ data }) {
    
    
    // Use data.history for your chart rendering
    const history = data?.history || [];
    const current = data.current; 
    
    const options = {   
        chart: {
            type: 'line',
            height: 260,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: history.map(item => item.date),
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'SpO2'
            },
            min: null,
            max: null
        },
        series: [{
            name: 'Time (Minutes)',
            data: history.map(item => item.value),
            color: '#4A90E2',
        }],
        tooltip: {
            valueSuffix: ' %'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
    };

    return (
        <div className="col-md-12">
            <div className="member-engagement mb-3">
                <h1 className='dashboard-graph-title mb-2'>
                    <span>SpO2</span>
                </h1>
                <h1 className="card-title1">{current} <span>% (Avg)</span></h1>
                <div className='mt-3'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

export default SpO2; 