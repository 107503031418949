import React, { useState, useEffect } from 'react';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from '../CalendarDatePicker';
import { fetch_download_care_coordinator, fetch_reports_care_coordinator_v2, getFileLink } from '../../../services/medengage/Service';
import { LoaderLoader, formatDate } from '../../utils/CommonLibrary';
import { decryptData } from '../../utils/Utils';
import { toast } from "react-toastify";
import { NotificationToast } from '../ContentCareNavigator/NotificationToast';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';

function ContentCNReportsTableV2() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedReport, setSelectedReport] = useState('All');
    const [criteriaField, setCriteriaField] = useState('membercreatedtime');
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [reportType, setReportType] = useState([]);

    const [filterText, setFilterText] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdTime");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    const today = new Date();
    const sevenDaysAgo = new Date();
    // sevenDaysAgo.setDate(today.getDate() - 7);

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };


    useEffect(() => {

        fetchCareCoordinatorData();

    }, [page, perPage, sortColumn, sortDirection, download]);

    const downloadLink = async (reportId) => {
        try {
            setIsLoading(true);
            let resp = await getFileLink(reportId);
            let downloadUrl = resp.data.data.url;
            if (downloadUrl) {
                const link = document.createElement('a');
                const filename = `report_${reportId}.xls`;
                link.href = downloadUrl;
                link.setAttribute('download', filename); // Specify the filename here if necessary
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            } else {
                console.error('Download URL not found in response');
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.creatorName,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },

        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Generated On',
            selector: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Download Reports',
            cell: row => (
                row.status == "Completed" ?
                    <div>
                        <span onClick={() => downloadLink(row.reportId)} style={{ cursor: 'pointer' }}> <i className="bi bi-download"></i></span>
                    </div> :
                    <span>{row.status}</span>
            ),
        },
    ];


    const fetchCareCoordinatorData = async () => {
        try {
            setIsLoading(true);
            let response = await fetch_reports_care_coordinator_v2(page, perPage, sortColumn, sortDirection);
            if (response.data.code === 200) {
                if (response.data.data.reportType) {
                    setReportType(response.data.data.reportType);
                }
                setReportData(response.data.data.records);
                setTotalRows(response.data.data.totalRows);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }
    const data = reportData;


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };
    /*const filterData = data.filter(user => {

        const matchesSearchQuery =
            (typeof user.generatedBy === 'string' && user.generatedBy.toLowerCase().includes(searchQuery.toLowerCase()));

        return matchesSearchQuery;
    });*/

    const genrateReport = async () => {
        setErrorMsg('');
        if (selectedReport == 'All') {
            setErrorMsg('Select Report type');
            return false;
        }
        try {
            setIsLoading(true); // show loader
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let extraParams = { "filterField": criteriaField }
            let response = await fetch_download_care_coordinator(sdate, edate, selectedReport, extraParams);
            if (response.data.code === 200) {
                if (response.data.data.status == 1) {
                    fetchCareCoordinatorData();
                    //setMessage(response.data.data.msg);
                    //setTimeout(() => setMessage(false), 3000);
                    toast.success(response.data.data.msg, {
                        position: 'top-right'
                    })
                } else {
                    //setError(response.data.data.msg);
                    //setTimeout(() => setError(false), 3000);
                    toast.error(response.data.data.msg, {
                        position: 'top-right'
                    })
                }

            }

        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }


    return (
        <>
            <NotificationToast />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 p-2' style={{ backgroundColor: "#f0f4ff", borderRadius: "14px  0 0 14px" }}>
                        <select
                            value={selectedReport}
                            onChange={e => {
                                setSelectedReport(e.target.value);
                                if (selectedReport != "memberfulldataextract") {
                                    setCriteriaField("");
                                } else {
                                    setCriteriaField("membercreatedtime");
                                }
                            }}
                            className='form-select input-field-ui'
                        >
                            <option value="">--Select Report:--</option>
                            {reportType?.map(option => (
                                <option key={option.shortName} value={option.shortName}>{option.displayName}</option>
                            ))}
                            {/* <option value="careNavigatorbreaksplan">CareNavigator Breaks Plan</option>
                            <option value="callhistory">Members Call History</option>
                            <option value="servicesreport">Service Report</option>
                            <option value="hhc">Home Care Request Report</option>
                            <option value="hbv">Home Based Vaccine Report</option>
                            <option value="medication">Medication Request Report</option>
                            <option value="lab">Lab Request Report</option>
                            <option value="memberdata">Member Data</option>
                            <option value="memberhealthengage">Member Health Engage Report</option>
                            <option value="carenavigatorperformance">CareNavigator Performance Report</option>
                            <option value="telehealthconsultationreport">Telehealth Consultation Report</option>
                            <option value="memberfulldataextract">Member Full Data Extract</option> */}

                        </select>
                        {errorMsg && <div className="text-danger">{errorMsg}</div>}
                    </div>
                    {
                        selectedReport == "memberfulldataextract" &&
                        <div className='col-md-3  py-2 px-0' style={{ backgroundColor: "#f0f4ff", borderRadius: "0px" }}>
                            <select
                                value={criteriaField}
                                onChange={e => setCriteriaField(e.target.value)}
                                className='form-select input-field-ui'
                            >
                                <option value="membercreatedtime">Member Created Date</option>
                                <option value="memberassignedtocn">Member Assigned to CN</option>
                                <option value="calldateorservicedate">Call Date or Service Request Date</option>
                            </select>
                        </div>
                    }

                    <div className='col-md-3  py-2 px-0' style={{ backgroundColor: "#f0f4ff", borderRadius: "0px" }}>
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} updateDates={false} />
                    </div>
                    <div className='col-md-auto p-2' style={{ backgroundColor: "#f0f4ff", borderRadius: "0 14px 14px 0" }}>
                        <button type='button' className='btn btn-primary add-new-program-btn' onClick={genrateReport}>Generate
                        </button>
                    </div>
                    <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                    <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                </div>
            </div>
            {
                isLoading ? <LoaderLoader isLoading={isLoading} /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={filterText}
                        columns={columns}
                        fetchTableData={fetchCareCoordinatorData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </>
    );
}

export default ContentCNReportsTableV2;