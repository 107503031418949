// Healthcare-related WhatsApp chatbot flows
export const chatbotFlowsData = [
    { 
      id: "flow-6",
      name: "Home Labs Service", 
      status: { text: "Active", type: "active" }, 
      createdBy: "Deepika", 
      createdOn: "10 Mar 2025",
      description: "A flow to help members schedule home-based lab tests through Bupa Arabia chatbot.",
      // Flow data for ReactFlow
      nodes: [
        { 
          id: 'flowStart', 
          type: 'default',
          position: { x: -450, y: 80 }, 
          data: { 
            label: 'Start The Flow',
            content: "Start the flow by sending 'Hello'."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'welcome', 
          type: 'default',
          position: { x: 0, y: 0 }, 
          data: { 
            label: 'Welcome Message',
            content: "Hello, I'm Salma, Bupa Arabia's Chatbot, when you can chat and learn about Bupa Arabia services.\n\nTebtom is a unique set of healthcare services that go beyond medical insurance coverage to provide you and your family a set of unique healthcare services. You can benefit from the program by choosing one of our services customized for your specific healthcare needs."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'serviceOptions', 
          type: 'default',
          position: { x: 350, y: 0 }, 
          data: { 
            label: 'Service Options',
            content: "1) Medication Refill (Pickup/Delivery)\n2) Home Vaccination\n3) Home Labs\n4) Contact Doctor\n5) Contact CareNavigator"
          },
          sourcePosition: 'right',
          targetPosition: 'left'
        },
        { 
          id: 'homeLabs', 
          type: 'default',
          position: { x: 700, y: 0 }, 
          data: { 
            label: 'Home Labs',
            content: "Get your labs done at your convenience and in the comfort of your home at your preferred time and location. Receive your results through the app within 24hrs.\n\nAre you a chronic disease patient?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'chronicYes', 
          type: 'default',
          position: { x: 1050, y: -150 }, 
          data: { 
            label: 'Chronic - Yes',
            content: "Have you had the previous test done during the past 3 months?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'chronicNo', 
          type: 'default',
          position: { x: 1050, y: 150 }, 
          data: { 
            label: 'Chronic - No',
            content: "This service is eligible for chronic patients\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main"
          },
          targetPosition: 'left' 
        },
        { 
          id: 'previousTestYes', 
          type: 'default',
          position: { x: 1400, y: -600 }, 
          data: { 
            label: 'Previous Test - Yes',
            content: "A lab test of the same medical condition should be conducted 3 months apart.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu"
          },
          targetPosition: 'left' 
        },
        { 
          id: 'previousTestNo', 
          type: 'default',
          position: { x: 1400, y: -70 }, 
          data: { 
            label: 'Previous Test - No',
            content: "Choose a member"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'memberSelect', 
          type: 'default',
          position: { x: 1750, y: -300 }, 
          data: { 
            label: 'Member Selection',
            content: "1) Sayyed Jafar \n35390274\n2) Fathima Badariya Haribamanzil\n35390275\n3) Ayisha Hisma Sayyed Jafar Maliyakkal\n35390276\n4) Sayed Abdul Basit Kala\n35390277"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'citySelect', 
          type: 'default',
          position: { x: 2100, y: -300 }, 
          data: { 
            label: 'City Selection',
            content: "To continue, please provide the following details:\n\nSelect the city for the service:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'cities', 
          type: 'default',
          position: { x: 2450, y: -300 }, 
          data: { 
            label: 'Cities',
            content: "1) Riyadh\n2) Jeddah\n3) Mecca"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'medicalCondition', 
          type: 'default',
          position: { x: 2800, y: -300 }, 
          data: { 
            label: 'Medical Condition',
            content: "Great! Now select your Medical condition:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'conditions', 
          type: 'default',
          position: { x: 3150, y: -300 }, 
          data: { 
            label: 'Conditions List',
            content: "1) Anemia of chronic disease\n2) Benign Prostate disorders (Male Only)\n3) Diabetes Mellitus"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'additionalInfo', 
          type: 'default',
          position: { x: 3500, y: -300 }, 
          data: { 
            label: 'Additional Info',
            content: "Lab test information\n\nFasting Blood sugar, HBA1C, Creatinine.\n\nPlease write if any additional info else write NA"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'continueOrAdd', 
          type: 'default',
          position: { x: 3850, y: -300 }, 
          data: { 
            label: 'Continue or Add',
            content: "Medical Condition : Diabetes Mellitus Lab test information : Fasting Blood sugar, HBaA1c, Creatinine"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'continueOptions', 
          type: 'default',
          position: { x: 4200, y: -300 }, 
          data: { 
            label: 'Continue Options',
            content: "1) Continue\n2) Add more conditions"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addressSelection', 
          type: 'default',
          position: { x: 4550, y: -750 }, 
          data: { 
            label: 'Address Selection',
            content: "Please select an existing address from your saved list or add a new one: \n\nHome : Satyam Tower, govandi East 400088. 19.0491° N, 72.9169° E \n\nWork: Sky Tower, bandra East 400069. 14.1791° N, 52.9689° E"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addressOptions', 
          type: 'default',
          position: { x: 4900, y: -750 }, 
          data: { 
            label: 'Address Options',
            content: "1) Home\n2) Work\n3) Add New"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addNewAddress', 
          type: 'default',
          position: { x: 4900, y: 200 }, 
          data: { 
            label: 'Add New Address',
            content: "Please Share your Location and Enter Complete address"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addressType', 
          type: 'default',
          position: { x: 5250, y: 200 }, 
          data: { 
            label: 'Address Type',
            content: "Please select address type:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'typeOptions', 
          type: 'default',
          position: { x: 5600, y: 200 }, 
          data: { 
            label: 'Type Options',
            content: "1) Home\n2) Work\n3) Other"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'callbackConfirm', 
          type: 'default',
          position: { x: 5250, y: -150 }, 
          data: { 
            label: 'Callback Confirmation',
            content: "A Bupa Arabia Doctor will contact you, you will receive a call from 012 615 5000 to confirm the Home-Based Lab request"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'timeSelection', 
          type: 'default',
          position: { x: 5600, y: -150 }, 
          data: { 
            label: 'Time Selection',
            content: "Select Time : \n1) Morning (9AM-12PM)\n2) Afternoon (1PM- 5PM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'reviewRequest', 
          type: 'default',
          position: { x: 5950, y: -150 }, 
          data: { 
            label: 'Review Request',
            content: "Please review your Home-based lab request details : \n\nMember Info : Sayyed Jafar 35390274\n\nRequest details: Medical Conditions : Diabetes Mellitus of chronic diseases \n\nCallback time : Morning (9AM-12PM)\n\nAddress : Home : Satyam Tower, govandi East 400088. 19.0491° N, 72.9169° E"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'changeSubmit', 
          type: 'default',
          position: { x: 6300, y: -150 }, 
          data: { 
            label: 'Change or Submit',
            content: "1) Change\n2) Submit"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'changeFlow', 
          type: 'default',
          position: { x: 6650, y: -300 }, 
          data: { 
            label: 'Change Flow',
            content: "Have you had the previous test done during the past 3 months?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'confirmation', 
          type: 'default',
          position: { x: 6650, y: -50 }, 
          data: { 
            label: 'Confirmation',
            content: "Home-based lab confirmed. Dear Sayyed Jafar, your request has been submitted and we will call you within 24 hours. Track your request in the timeline by the following reference number. Request reference number is : 156597"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'finalOptions', 
          type: 'default',
          position: { x: 7000, y: -50 }, 
          data: { 
            label: 'Final Options',
            content: "1) New lab request\n2) Done"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'endFlow', 
          type: 'default',
          position: { x: 7450, y: -50 }, 
          data: { 
            label: 'Flow End',
            content: "The flow ends here."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'addMoreCondition', 
          type: 'default',
          position: { x: 4550, y: 100 }, 
          data: { 
            label: 'Add More Condition',
            content: "Great! Now select your Medical condition:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'moreConditionList', 
          type: 'default',
          position: { x: 4900, y: 100 }, 
          data: { 
            label: 'More Condition List',
            content: "1) Anemia of chronic disease\n2) Benign Prostate disorders (Male Only)\n3) Diabetes Mellitus"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'conditionSummary', 
          type: 'default',
          position: { x: 5250, y: 100 }, 
          data: { 
            label: 'Condition Summary',
            content: "Medical Condition : Diabetes Mellitus Lab test information : Fasting Blood sugar, HBaA1c, Creatinine\n\nAnemia of chronic disease:\nLab test information:\nFerritine"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'conditionOptions', 
          type: 'default',
          position: { x: 5600, y: 100 }, 
          data: { 
            label: 'Condition Options',
            content: "1) Continue\n2) Add more conditions"
          },
          targetPosition: 'left' 
        }
      ],
      edges: [
        { id: 'e-flow-start', source: 'flowStart', target: 'welcome', animated: true },
        { id: 'e-welcome-options', source: 'welcome', target: 'serviceOptions', animated: true },
        { id: 'e-options-homelabs', source: 'serviceOptions', target: 'homeLabs', label: 'Option 3', animated: true },
        { id: 'e-homelabs-yes', source: 'homeLabs', target: 'chronicYes', label: 'Yes', animated: true },
        { id: 'e-homelabs-no', source: 'homeLabs', target: 'chronicNo', label: 'No', animated: true },
        { id: 'e-chronicYes-previousYes', source: 'chronicYes', target: 'previousTestYes', label: 'Yes', animated: true },
        { id: 'e-chronicYes-previousNo', source: 'chronicYes', target: 'previousTestNo', label: 'No', animated: true },
        { id: 'e-previousTestNo-member', source: 'previousTestNo', target: 'memberSelect', animated: true },
        { id: 'e-member-city', source: 'memberSelect', target: 'citySelect', label: 'Select Any', animated: true },
        { id: 'e-city-cityList', source: 'citySelect', target: 'cities', animated: true },
        { id: 'e-cities-condition', source: 'cities', target: 'medicalCondition', label: 'Select Any', animated: true },
        { id: 'e-condition-conditionsList', source: 'medicalCondition', target: 'conditions', animated: true },
        { id: 'e-conditions-additionalInfo', source: 'conditions', target: 'additionalInfo', label: 'Select Any', animated: true },
        { id: 'e-additionalInfo-continue', source: 'additionalInfo', target: 'continueOrAdd', label: 'Continue', animated: true },
        { id: 'e-continue-options', source: 'continueOrAdd', target: 'continueOptions', animated: true },
        { id: 'e-options-address', source: 'continueOptions', target: 'addressSelection', label: 'Continue', animated: true },
        { id: 'e-options-addMore', source: 'continueOptions', target: 'addMoreCondition', label: 'Add more', animated: true },
        { id: 'e-address-addressOptions', source: 'addressSelection', target: 'addressOptions', animated: true },
        { id: 'e-addressOptions-callback', source: 'addressOptions', target: 'callbackConfirm', label: 'Home/Work', animated: true },
        { id: 'e-addressOptions-addNew', source: 'addressOptions', target: 'addNewAddress', label: 'Add New', animated: true },
        { id: 'e-addNew-type', source: 'addNewAddress', target: 'addressType', animated: true },
        { id: 'e-type-options', source: 'addressType', target: 'typeOptions', animated: true },
        { id: 'e-typeOptions-callback', source: 'typeOptions', target: 'callbackConfirm', animated: true },
        { id: 'e-callback-time', source: 'callbackConfirm', target: 'timeSelection', animated: true },
        { id: 'e-time-review', source: 'timeSelection', target: 'reviewRequest', label: 'Select Time', animated: true },
        { id: 'e-review-options', source: 'reviewRequest', target: 'changeSubmit', animated: true },
        { id: 'e-options-change', source: 'changeSubmit', target: 'changeFlow', label: 'Change', animated: true },
        { id: 'e-options-submit', source: 'changeSubmit', target: 'confirmation', label: 'Submit', animated: true },
        { id: 'e-confirmation-final', source: 'confirmation', target: 'finalOptions', animated: true },
        { id: 'e-end-flow', source: 'finalOptions', target: 'endFlow', label: 'Done', animated: true },
        { id: 'e-restart-flow', source: 'finalOptions', target: 'homeLabs', label: 'New Lab Request', animated: true },
        { id: 'e-addMore-conditionList', source: 'addMoreCondition', target: 'moreConditionList', animated: true },
        { id: 'e-moreList-summary', source: 'moreConditionList', target: 'conditionSummary', label: 'Select Any', animated: true },
        { id: 'e-summary-options', source: 'conditionSummary', target: 'conditionOptions', animated: true },
        { id: 'e-conditionOptions-address', source: 'conditionOptions', target: 'addressSelection', label: 'Continue', animated: true }
      ]
    },
    { 
      id: "flow-7",
      name: "Medication Refill Service", 
      status: { text: "Active", type: "active" }, 
      createdBy: "Deepika", 
      createdOn: "10 Mar 2025",
      description: "A flow to help patients refill their medications through pickup or delivery options",
      // Flow data for ReactFlow
      nodes: [
        { 
          id: 'flowStart', 
          type: 'default',
          position: { x: -1550, y: 30 }, 
          data: { 
            label: 'Start The Flow',
            content: "Start the flow by sending 'Hello'."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'welcome', 
          type: 'default',
          position: { x: -1200, y: -50 }, 
          data: { 
            label: 'Welcome Message',
            content: "Hello, I'm Salma, Bupa Arabia's Chatbot, where you can chat and learn about Bupa Arabia services.\n\nYou can benefit from the program by choosing one of our services customized for your specific healthcare needs."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'serviceOptions', 
          type: 'default',
          position: { x: -850, y: -20 }, 
          data: { 
            label: 'Service Options',
            content: "1) Medication Refill (Pickup/Delivery)\n2) Home Vaccination\n3) Home Labs\n4) Contact Doctor\n5) Contact CareNavigator"
          },
          sourcePosition: 'right',
          targetPosition: 'left'
        },
        { 
          id: 'medicationRefill', 
          type: 'default',
          position: { x: -450, y: -30 }, 
          data: { 
            label: 'Medication Refill',
            content: "For your convenience, we will refill your medication for pick up or delivery at your preferred time and location.\n\nDid you visit your treating doctor within the last 9 months?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'visitedYes', 
          type: 'default',
          position: { x: -150, y: -300 }, 
          data: { 
            label: 'Doctor Visit - Yes',
            content: "Is your chronic health condition stable?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'visitedNo', 
          type: 'default',
          position: { x: 220, y: -100 }, 
          data: { 
            label: 'Doctor Visit - No',
            content: "We highly recommend to visit the treating doctor and update the prescription or choose one of the below services.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'stableYes', 
          type: 'default',
          position: { x: 300, y: -290 }, 
          data: { 
            label: 'Stable Condition - Yes',
            content: "Choose a member"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'stableNo', 
          type: 'default',
          position: { x: 220, y: 190 }, 
          data: { 
            label: 'Stable Condition - No',
            content: "We recommend you to consult with your treating doctor to evaluate your health condition. If you need assistance, please select from the below options."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'memberSelect', 
          type: 'default',
          position: { x: 660, y: -100 }, 
          data: { 
            label: 'Member Selection',
            content: "1) Sayyed Jafar \n35390274\n2) Fathima Badariya Haribamanzil\n35390275\n3) Ayisha Hisma Sayyed Jafar Maliyakkal\n35390276\n4) Sayed Abdul Basit Kala\n35390277"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'noMedications', 
          type: 'default',
          position: { x: 1050, y: -100 }, 
          data: { 
            label: 'No Medications',
            content: "Based on your approval history, you do not have any medications \n\nIf you want you can Add new medication or Upload a prescription for your new medications\n (File must be of following types: \".pdf, .png, .jpg, .gif, .bmp\" with 5 MB limit)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'noMedicationsOptions', 
          type: 'default',
          position: { x: 1440, y: -20 }, 
          data: { 
            label: 'No Medications Options',
            content: "1) Add New Medication\n2) Upload Document"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addNewMedication', 
          type: 'default',
          position: { x: 1880, y: -100 }, 
          data: { 
            label: 'Add New Medication',
            content: "Please select Disease/Medical condition from the below list"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'uploadDocument', 
          type: 'default',
          position: { x: 1880, y: 100 }, 
          data: { 
            label: 'Upload Document',
            content: "Please Upload a prescription for your new medications\nFile must be of following types: \".pdf, .png, .jpg\" with 5 MB limit"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'medicalConditions', 
          type: 'default',
          position: { x: 2400, y: -200 }, 
          data: { 
            label: 'Medical Conditions',
            content: "Select Medical Condition\n\n1) Anemia\n2) Asthma\n3) COPD\n4) Diabetes\n5) Dyslipidemia\n6) Epilepsy\n7) GERD\n8) Glaucoma\n9) HyperTension\n10) More"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'selectMedication', 
          type: 'default',
          position: { x: 2800, y: -100 }, 
          data: { 
            label: 'Select Medication',
            content: "Please select medication from the available list:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'medicationList', 
          type: 'default',
          position: { x: 3200, y: -180 }, 
          data: { 
            label: 'Medication List',
            content: "1) Acarose 100 Mg Tablets\n2) Acarose 25 Mg Tablets\n3) Actos 15 Mg Tablet\n4) Actos 30 Mg Tab\n5) Actosmet 15/850 Mg Tablet\n6) Actrapid 100IU/ml Solution for injection\n7) Actrapid Penfill 100IU/ml Suspension for injection in cartridge\n8) Amaglime 1 Mg Tablet\n9) Amaglime 2 Mg Tablet\n10) More"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'medicationSummary', 
          type: 'default',
          position: { x: 3600, y: -100 }, 
          data: { 
            label: 'Medication Summary',
            content: "Medication List:\n\nAcarose 100 Mg Tablets\n\n\n"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'continueOptions', 
          type: 'default',
          position: { x: 3950, y: -100 }, 
          data: { 
            label: 'Continue Options',
            content: "1) Add more\n2) Continue"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        // Paths converge here regardless of add new or upload document
        { 
          id: 'refillType', 
          type: 'default',
          position: { x: 4300, y: 130 }, 
          data: { 
            label: 'Refill Type',
            content: "Select Refill Type:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'refillOptions', 
          type: 'default',
          position: { x: 4600, y: 120 }, 
          data: { 
            label: 'Refill Options',
            content: "1) Delivery\n2) PickUp"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'deliveryOption', 
          type: 'default',
          position: { x: 4900, y: -160 }, 
          data: { 
            label: 'Delivery Option',
            content: "Please select an existing address from your saved list or add a new one: \n\nHome : Satyam Tower, govandi East 400088. 19.0491° N, 72.9169° E \n\nWork: Sky Tower, bandra East 400069. 14.1791° N, 52.9689° E"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addressOptions', 
          type: 'default',
          position: { x: 5300, y: -80 }, 
          data: { 
            label: 'Address Options',
            content: "1) Home\n2) Work\n3) Add New"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'pickupOption', 
          type: 'default',
          position: { x: 5300, y: 130 }, 
          data: { 
            label: 'Pickup Option',
            content: "Select preferred Pharmacy:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'pharmacyList', 
          type: 'default',
          position: { x: 5700, y: 10 }, 
          data: { 
            label: 'Pharmacy List',
            content: "1) Al Nahdi, Daan, Sudais, Saudi Pharmacy\n2) Al Dawa Pharmacy\n3) Middle East Pharmacy (Riyadh only)\n4) United Pharmacy\n5) Mujtamaa Pharmacy (Jeddah Only)\n6) Saudi Innova pharmacy\n7) Zahrat Al Rawdah pharmacy\n8) Lemon pharmacy\n9) Hamad Town Pharmacy (Bahrain)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        // Handle selection for either path
        // { 
        //   id: 'addressSelected', 
        //   type: 'default',
        //   position: { x: 4200, y: 110 }, 
        //   data: { 
        //     label: 'Address Selected',
        //     content: "1) Home"
        //   },
        //   sourcePosition: 'right',
        //   targetPosition: 'left' 
        // },
        { 
          id: 'addNewAddress', 
          type: 'default',
          position: { x: 5700, y: -190 }, 
          data: { 
            label: 'Add New Address',
            content: "Please Share your Location and Enter Complete address"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'addressType', 
          type: 'default',
          position: { x: 6100, y: -180 }, 
          data: { 
            label: 'Address Type',
            content: "Please select address type:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'typeOptions', 
          type: 'default',
          position: { x: 6500, y: -200 }, 
          data: { 
            label: 'Type Options',
            content: "1) Home\n2) Work\n3) Other"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'pharmacySelected', 
          type: 'default',
          position: { x: 6100, y: 120 }, 
          data: { 
            label: 'Pharmacy Selected',
            content: "1) Al Nahdi, Daan, Sudais, Saudi Pharmacy"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        // Both delivery and pickup paths converge here
        { 
          id: 'contactConfirmation', 
          type: 'default',
          position: { x: 6850, y: -90 }, 
          data: { 
            label: 'Contact Confirmation',
            content: "A Bupa Arabia Doctor will contact you, you will receive a call from 012 615 5000 to confirm the Medication Refill"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'comments', 
          type: 'default',
          position: { x: 6500, y: 110 }, 
          data: { 
            label: 'Comments',
            content: "Do you want to add comments?\n\nWrite NA to skip"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'callTime', 
          type: 'default',
          position: { x: 7300, y: -60 }, 
          data: { 
            label: 'Call Time',
            content: "Select Call Time:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'timeOptions', 
          type: 'default',
          position: { x: 7700, y: -70 }, 
          data: { 
            label: 'Time Options',
            content: "1) Morning (9AM-12PM)\n2) Afternoon (1PM- 5PM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'reviewRequest', 
          type: 'default',
          position: { x: 8350, y: -200 }, 
          data: { 
            label: 'Review Request',
            content: "Review and Confirm medication refill request\n\nMember name: Sayyed Jafar 35390274\n\nMedication selected: Acarose 100 Mg Tablets\n\nRefill type: PickUp\n\nDestination and time: Al Nahdi, Daan, Sudais, Saudi Pharmacy\n\nCall time: Morning (9AM-12PM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'uploadReviewRequest', 
          type: 'default',
          position: { x: 8200, y: -700 }, 
          data: { 
            label: 'Upload Review Request',
            content: "Please review your Medication request details : \n\nMember Info: Sayyed Jafar 35390274\n\nRequest details: \nRefill Type: Delivery\nDestination selected: Home\n\nUploaded document: [attachment]\n\nCallback time: Morning (9AM-12PM)\n\nAddress: Home: Satyam Tower, govandi East 400088. 19.0491° N, 72.9169° E"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'submitOptions', 
          type: 'default',
          position: { x: 8750, y: -60 }, 
          data: { 
            label: 'Submit Options',
            content: "1) Submit"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'uploadSubmitOptions', 
          type: 'default',
          position: { x: 8650, y: -530 }, 
          data: { 
            label: 'Upload Submit Options',
            content: "1) Change\n2) Submit"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'confirmation', 
          type: 'default',
          position: { x: 9150, y: -100 }, 
          data: { 
            label: 'Confirmation',
            content: "Dear Sayyed, your request have been submitted and we can call you within 24 hours. Request reference number: 156605"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'uploadConfirmation', 
          type: 'default',
          position: { x: 9150, y: -570 }, 
          data: { 
            label: 'Upload Confirmation',
            content: "Medication Refill Confirmed.\nDear Sayed, your request has been submitted and we will call you within 24 hours.\nRequest reference number is: 156609"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'finalOptions', 
          type: 'default',
          position: { x: 9560, y: -70 }, 
          data: { 
            label: 'Final Options',
            content: "Done"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'uploadFinalOptions', 
          type: 'default',
          position: { x: 9600, y: -550 }, 
          data: { 
            label: 'Upload Final Options',
            content: "1) New Refill\n2) Done"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'endFlow', 
          type: 'default',
          position: { x: 10100, y: -255 }, 
          data: { 
            label: 'Flow End',
            content: "The flow ends here."
          },
          targetPosition: 'left' 
        }
      ],
      edges: [
        { id: 'e-flow-start', source: 'flowStart', target: 'welcome', animated: true },
        { id: 'e-welcome-options', source: 'welcome', target: 'serviceOptions', animated: true },
        { id: 'e-options-medication', source: 'serviceOptions', target: 'medicationRefill', label: 'Option 1', animated: true },
        { id: 'e-medication-yes', source: 'medicationRefill', target: 'visitedYes', label: 'Yes', animated: true },
        { id: 'e-medication-no', source: 'medicationRefill', target: 'visitedNo', label: 'No', animated: true },
        { id: 'e-visited-stable', source: 'visitedYes', target: 'stableYes', label: 'Yes', animated: true },
        { id: 'e-visited-unstable', source: 'visitedYes', target: 'stableNo', label: 'No', animated: true },
        { id: 'e-stable-member', source: 'stableYes', target: 'memberSelect', animated: true },
        { id: 'e-member-noMeds', source: 'memberSelect', target: 'noMedications', label: 'Select Any', animated: true },
        { id: 'e-member-noMeds-options', source: 'noMedications', target: 'noMedicationsOptions', animated: true },
        { id: 'e-noMeds-add', source: 'noMedicationsOptions', target: 'addNewMedication', label: 'Add new medication', animated: true },
        { id: 'e-noMeds-upload', source: 'noMedicationsOptions', target: 'uploadDocument', label: 'Upload Document', animated: true },
        { id: 'e-add-conditions', source: 'addNewMedication', target: 'medicalConditions', animated: true },
        { id: 'e-conditions-select', source: 'medicalConditions', target: 'selectMedication', label: 'Select Any', animated: true },
        { id: 'e-select-list', source: 'selectMedication', target: 'medicationList', animated: true },
        { id: 'e-list-summary', source: 'medicationList', target: 'medicationSummary', label: 'Select Any', animated: true },
        { id: 'e-summary-options', source: 'medicationSummary', target: 'continueOptions', animated: true },
        { id: 'e-options-refill', source: 'continueOptions', target: 'refillType', label: 'Continue', animated: true },
        { id: 'e-upload-refill', source: 'uploadDocument', target: 'refillType', label: 'Upload File', animated: true },
        { id: 'e-refill-options', source: 'refillType', target: 'refillOptions', animated: true },
        { id: 'e-options-delivery', source: 'refillOptions', target: 'deliveryOption', label: 'Delivery', animated: true },
        { id: 'e-options-pickup', source: 'refillOptions', target: 'pickupOption', label: 'PickUp', animated: true },
        { id: 'e-options-list', source: 'pickupOption', target: 'pharmacyList', animated: true },
        { id: 'e-delivery-address', source: 'deliveryOption', target: 'addressOptions', animated: true },
        { id: 'e-address-home-work', source: 'addressOptions', target: 'contactConfirmation', label: 'Home/Work', animated: true },
        { id: 'e-address-add-new', source: 'addressOptions', target: 'addNewAddress', label: 'Add New',  animated: true },
        { id: 'e-address-add-new-2', source: 'addNewAddress', target: 'addressType',  animated: true },
        { id: 'e-address-add-new-3', source: 'addNewAddress', target: 'addressType',  animated: true },
        { id: 'e-pickup-add-new-4', source: 'addressType', target: 'typeOptions', animated: true },
        { id: 'e-pickup-add-new-confirm', source: 'typeOptions', target: 'contactConfirmation', animated: true },
        { id: 'e-pharmacy-selected', source: 'pharmacyList', target: 'pharmacySelected', label: 'Select Any', animated: true },
        
        // Two paths for comments and direct callback
        // { id: 'e-address-comments', source: 'addressSelected', target: 'contactConfirmation', animated: true },
        { id: 'e-pharmacy-comments', source: 'pharmacySelected', target: 'comments', animated: true },
        
        // Continue paths after comments
        { id: 'e-comments-contact', source: 'comments', target: 'contactConfirmation', animated: true },
        { id: 'e-contact-call', source: 'contactConfirmation', target: 'callTime', animated: true },
        { id: 'e-call-time', source: 'callTime', target: 'timeOptions', animated: true },
        
        // Review paths - separate for upload and add new
        { id: 'e-time-review', source: 'timeOptions', target: 'reviewRequest', label: 'Select Time', animated: true },
        { id: 'e-time-uploadReview', source: 'timeOptions', target: 'uploadReviewRequest', label: 'Select Time (Upload Flow)', animated: true },
        
        // Submit paths
        { id: 'e-review-submit', source: 'reviewRequest', target: 'submitOptions', animated: true },
        { id: 'e-uploadReview-submit', source: 'uploadReviewRequest', target: 'uploadSubmitOptions', animated: true },
        
        // Confirmation paths
        { id: 'e-submit-confirmation', source: 'submitOptions', target: 'confirmation', animated: true },
        { id: 'e-uploadSubmit-confirmation', source: 'uploadSubmitOptions', target: 'uploadConfirmation', label: 'Submit', animated: true },
        
        // Final options
        { id: 'e-confirmation-final', source: 'confirmation', target: 'finalOptions', animated: true },
        { id: 'e-uploadConfirmation-final', source: 'uploadConfirmation', target: 'uploadFinalOptions', animated: true },
        
        // End paths
        { id: 'e-final-end', source: 'finalOptions', target: 'endFlow', animated: true },
        { id: 'e-uploadFinal-end', source: 'uploadFinalOptions', target: 'endFlow', label: 'Done', animated: true },
        { id: 'e-uploadFinal-restart', source: 'uploadFinalOptions', target: 'medicationRefill', label: 'New Refill', animated: true }
      ]
    },
    { 
      id: "flow-8",
      name: "Contact CareNavigator Service", 
      status: { text: "Active", type: "active" }, 
      createdBy: "Deepika", 
      createdOn: "10 Mar 2025",
      description: "A flow to help patients connect with a CareNavigator through chat or tele appointment",
      // Flow data for ReactFlow
      nodes: [
        { 
          id: 'flowStart', 
          type: 'default',
          position: { x: -450, y: 80 }, 
          data: { 
            label: 'Start The Flow',
            content: "Start the flow by sending 'Hello'."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'welcome', 
          type: 'default',
          position: { x: 0, y: 0 }, 
          data: { 
            label: 'Welcome Message',
            content: "Hello, I'm Salma, Bupa Arabia's Chatbot, where you can chat and learn about Bupa Arabia services.\n\nYou can benefit from the program by choosing one of our services customized for your specific healthcare needs."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'serviceOptions', 
          type: 'default',
          position: { x: 350, y: 0 }, 
          data: { 
            label: 'Service Options',
            content: "1) Medication Refill (Pickup/Delivery)\n2) Home Vaccination\n3) Home Labs\n4) Contact Doctor\n5) Contact CareNavigator"
          },
          sourcePosition: 'right',
          targetPosition: 'left'
        },
        { 
          id: 'careNavigator', 
          type: 'default',
          position: { x: 700, y: 0 }, 
          data: { 
            label: 'Contact CareNavigator',
            content: "Please choose one of the below options?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'contactOptions', 
          type: 'default',
          position: { x: 1050, y: 0 }, 
          data: { 
            label: 'Contact Options',
            content: "1) Chat\n2) Tele Appointment"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'chatOption', 
          type: 'default',
          position: { x: 1400, y: -150 }, 
          data: { 
            label: 'Chat Option',
            content: "Please be Patient.\nIt will take a few minutes for a CareNavigator to contact you..."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'teleAppointment', 
          type: 'default',
          position: { x: 1400, y: 150 }, 
          data: { 
            label: 'Tele Appointment',
            content: "Please Select Preferred Date of Tele Appointment"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'dateOptions', 
          type: 'default',
          position: { x: 1750, y: 150 }, 
          data: { 
            label: 'Date Options',
            content: "Select Date:\n1) Today\n2) Tomorrow\n3) 12th May"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'selectedDate', 
          type: 'default',
          position: { x: 2100, y: 150 }, 
          data: { 
            label: 'Selected Date',
            content: "Today"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'timeRequest', 
          type: 'default',
          position: { x: 2450, y: 150 }, 
          data: { 
            label: 'Time Request',
            content: "Please select preferred time"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'timeOptions', 
          type: 'default',
          position: { x: 2800, y: 150 }, 
          data: { 
            label: 'Time Options',
            content: "1) Morning (9AM-12PM)\n2) Afternoon (1PM- 5PM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'morningSelected', 
          type: 'default',
          position: { x: 3150, y: 150 }, 
          data: { 
            label: 'Morning Selected',
            content: "1) Morning (9AM-12PM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'slotsAvailable', 
          type: 'default',
          position: { x: 3500, y: 150 }, 
          data: { 
            label: 'Slots Available',
            content: "We found below Slots based on your preference, please select preferred slot."
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'slotOptions', 
          type: 'default',
          position: { x: 3850, y: 150 }, 
          data: { 
            label: 'Slot Options',
            content: "1) 9 AM - 09:30 AM\n2) 9:30 AM - 10 AM\n3) 10 AM - 10:30 AM\n4) 10:30 AM - 11 AM\n5) 11 AM - 11:30 AM\n6) 11:30 AM - 12 PM\n7) More"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'slotSelected', 
          type: 'default',
          position: { x: 4200, y: 150 }, 
          data: { 
            label: 'Slot Selected',
            content: "2) 9:30 AM - 10 AM"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'confirmation', 
          type: 'default',
          position: { x: 4550, y: 150 }, 
          data: { 
            label: 'Confirmation',
            content: "Congratulations!\nYour Booking is confirmed with CareNavigator for Today at 9:30 AM."
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'mainMenu', 
          type: 'default',
          position: { x: 4900, y: 150 }, 
          data: { 
            label: 'Main Menu',
            content: "Main Menu"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'endFlow', 
          type: 'default',
          position: { x: 5250, y: 0 }, 
          data: { 
            label: 'Flow End',
            content: "The flow ends here."
          },
          targetPosition: 'left' 
        }
      ],
      edges: [
        { id: 'e-flow-start', source: 'flowStart', target: 'welcome', animated: true },
        { id: 'e-welcome-options', source: 'welcome', target: 'serviceOptions', animated: true },
        { id: 'e-options-careNavigator', source: 'serviceOptions', target: 'careNavigator', label: 'Option 5', animated: true },
        { id: 'e-careNavigator-options', source: 'careNavigator', target: 'contactOptions', animated: true },
        { id: 'e-options-chat', source: 'contactOptions', target: 'chatOption', label: 'Chat', animated: true },
        { id: 'e-options-teleAppointment', source: 'contactOptions', target: 'teleAppointment', label: 'Tele Appointment', animated: true },
        { id: 'e-teleAppointment-dates', source: 'teleAppointment', target: 'dateOptions', animated: true },
        { id: 'e-dates-selectedDate', source: 'dateOptions', target: 'selectedDate', label: 'Today', animated: true },
        { id: 'e-selectedDate-timeRequest', source: 'selectedDate', target: 'timeRequest', animated: true },
        { id: 'e-timeRequest-timeOptions', source: 'timeRequest', target: 'timeOptions', animated: true },
        { id: 'e-timeOptions-morningSelected', source: 'timeOptions', target: 'morningSelected', label: 'Morning', animated: true },
        { id: 'e-morningSelected-slotsAvailable', source: 'morningSelected', target: 'slotsAvailable', animated: true },
        { id: 'e-slotsAvailable-slotOptions', source: 'slotsAvailable', target: 'slotOptions', animated: true },
        { id: 'e-slotOptions-slotSelected', source: 'slotOptions', target: 'slotSelected', label: '9:30 AM', animated: true },
        { id: 'e-slotSelected-confirmation', source: 'slotSelected', target: 'confirmation', animated: true },
        { id: 'e-confirmation-mainMenu', source: 'confirmation', target: 'mainMenu', animated: true },
        { id: 'e-mainMenu-endFlow', source: 'mainMenu', target: 'endFlow', animated: true },
        { id: 'e-chat-endFlow', source: 'chatOption', target: 'endFlow', animated: true }
      ]
    },
    { 
      id: "flow-9",
      name: "Contact Doctor Service", 
      status: { text: "Active", type: "active" }, 
      createdBy: "Deepika", 
      createdOn: "10 Mar 2025",
      description: "A flow to help patients contact doctors through various methods including chat, telehealth and appointment booking",
      // Flow data for ReactFlow
      nodes: [
        { 
          id: 'flowStart', 
          type: 'default',
          position: { x: -1550, y: 30 }, 
          data: { 
            label: 'Start The Flow',
            content: "Start the flow by sending 'Hello'."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'welcome', 
          type: 'default',
          position: { x: -1200, y: 0 }, 
          data: { 
            label: 'Welcome Message',
            content: "Hello, I'm Salma, Bupa Arabia's Chatbot, where you can chat and learn about Bupa Arabia services.\n\nYou can benefit from the program by choosing one of our services customized for your specific healthcare needs."
          },
          sourcePosition: 'right',
        },
        { 
          id: 'serviceOptions', 
          type: 'default',
          position: { x: -850, y: 0 }, 
          data: { 
            label: 'Service Options',
            content: "1) Medication Refill (Pickup/Delivery)\n2) Home Vaccination\n3) Home Labs\n4) Contact Doctor\n5) Contact CareNavigator\n6) Contact CareNavigator (Non CCM)\n7) Contact Doctor (Non CCM)"
          },
          sourcePosition: 'right',
          targetPosition: 'left'
        },
        { 
          id: 'contactDoctor', 
          type: 'default',
          position: { x: -450, y: 0 }, 
          data: { 
            label: 'Contact Doctor',
            content: "Please choose one of the below options?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'doctorOptions', 
          type: 'default',
          position: { x: -100, y: 0 }, 
          data: { 
            label: 'Doctor Options',
            content: "1) Contact Tebtom Doctor\n2) Telehealth\n3) International Telemedicine\n4) Appointment Booking"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        // Contact Tebtom Doctor path
        { 
          id: 'tebtomDoctor', 
          type: 'default',
          position: { x: 250, y: -300 }, 
          data: { 
            label: 'Tebtom Doctor',
            content: "How would you like to contact a Tebtom Doctor?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'contactMethods', 
          type: 'default',
          position: { x: 600, y: -300 }, 
          data: { 
            label: 'Contact Methods',
            content: "1) Chat\n2) Call"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'chatOption', 
          type: 'default',
          position: { x: 950, y: -400 }, 
          data: { 
            label: 'Chat Option',
            content: "Is your disease controlled?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'callOption', 
          type: 'default',
          position: { x: 950, y: -200 }, 
          data: { 
            label: 'Call Option',
            content: "Directs to call: 8004404040"
          },
          targetPosition: 'left' 
        },
        { 
          id: 'diseaseControlledYes', 
          type: 'default',
          position: { x: 1300, y: -450 }, 
          data: { 
            label: 'Disease Controlled - Yes',
            content: "It will take a few minutes for a doctor to contact you..."
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'diseaseControlledNo', 
          type: 'default',
          position: { x: 1300, y: -350 }, 
          data: { 
            label: 'Disease Controlled - No',
            content: "We highly recommend to visit the treating doctor and update the prescription.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'agentsBusy', 
          type: 'default',
          position: { x: 1650, y: -450 }, 
          data: { 
            label: 'Agents Busy',
            content: "All of our agents are currently engaged.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
    
        // Telehealth path
        { 
          id: 'telehealth', 
          type: 'default',
          position: { x: 250, y: 0 }, 
          data: { 
            label: 'Telehealth',
            content: "Is your chronic health condition stable?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthOptions', 
          type: 'default',
          position: { x: 600, y: 0 }, 
          data: { 
            label: 'Telehealth Options',
            content: "1) Yes\n2) No"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthStableNo', 
          type: 'default',
          position: { x: 950, y: 100 }, 
          data: { 
            label: 'Telehealth Stable - No',
            content: "We highly recommend to visit the treating doctor and update the prescription or choose one of the below services.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthStableYes', 
          type: 'default',
          position: { x: 950, y: -100 }, 
          data: { 
            label: 'Telehealth Stable - Yes',
            content: "Please read and confirm the following to determine the eligibility and suitability of the Telehealth service and confirm the patient is free of any emergency that requires an immediate direct emergency visit to receive the necessary health care.\n\nI declare that I do not have any of the following medical problems:\nwww.bupa.com/terms\n\nNOTE: This medical service is provided by licensed healthcare providers. If this is an emergency please visit nearest ER"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthConfirm', 
          type: 'default',
          position: { x: 1300, y: -100 }, 
          data: { 
            label: 'Telehealth Confirm',
            content: "1) Yes! proceed"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthMemberSelect', 
          type: 'default',
          position: { x: 1650, y: -100 }, 
          data: { 
            label: 'Telehealth Member Selection',
            content: "Choose a member"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthMembers', 
          type: 'default',
          position: { x: 2000, y: -100 }, 
          data: { 
            label: 'Telehealth Members',
            content: "1) Sayyed Jafar \n35390274\n2) Fathima Badariya Haribamanzil\n35390275\n3) Ayisha Hisma Sayyed Jafar Maliyakkal\n35390276\n4) Sayed Abdul Basit Kala\n35390277"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthSpeciality', 
          type: 'default',
          position: { x: 2350, y: -100 }, 
          data: { 
            label: 'Telehealth Speciality',
            content: "Please select the speciality from the list below:\n\n1) Family Doctor\n2) Internist\n3) Pediatrician\n4) Gynaecology & Obstetrics\n5) Nutritionist\n6) General physician\n7) Psychiatrist\n8) Rheumatologist\n9) Endocrinologist"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthMonth', 
          type: 'default',
          position: { x: 2700, y: -100 }, 
          data: { 
            label: 'Telehealth Month',
            content: "Select Month:\n\n1) Jan \n2) Feb\n3) Mar\n4) Apr"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthDay', 
          type: 'default',
          position: { x: 3050, y: -100 }, 
          data: { 
            label: 'Telehealth Day',
            content: "Select Day:\n\n1) 7th Friday\n2) 8th Saturday\n3) 9th Sunday"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthDoctor', 
          type: 'default',
          position: { x: 3400, y: -100 }, 
          data: { 
            label: 'Telehealth Doctor',
            content: "Select Doctor:\n\n1) Dr. Marwan Alkhattabi\nFamily Doctor\n4 years experience\n\n2) Dr. Bahaa Abulaban\nFamily Doctor\n5 years experience"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthConsent', 
          type: 'default',
          position: { x: 3750, y: -100 }, 
          data: { 
            label: 'Telehealth Consent',
            content: "Patient Telemedicine Consent\n\nPlease review and agree to the following terms before receiving Telehealth services.\n\nI hereby authorize Bupa CareConnect to use the telehealth practice platform for telecommunication for evaluating, testing, and diagnosing my medical condition.\n\nwww.bupa.com/terms"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthConsentOptions', 
          type: 'default',
          position: { x: 4100, y: -100 }, 
          data: { 
            label: 'Consent Options',
            content: "1) I Don't Agree\n2) I Agree"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthSlots', 
          type: 'default',
          position: { x: 4450, y: -100 }, 
          data: { 
            label: 'Telehealth Slots',
            content: "Select Slots:\n\nAfternoon:\n1) 12:00 PM\n2) 12:15 PM\n3) 12:30 PM\n4) 12:45 PM\n5) 01:00 PM"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthMedicalCondition', 
          type: 'default',
          position: { x: 4800, y: -100 }, 
          data: { 
            label: 'Medical Condition',
            content: "Describe your medical condition:\n\nShare with the doctor, if you have any medical conditions, allergies and if you're taking any medication.\n\nAdd Voice Note (Optional)\nUpload Medical Report (Optional)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthBookingOptions', 
          type: 'default',
          position: { x: 5150, y: -100 }, 
          data: { 
            label: 'Booking Options',
            content: "1) Confirm Booking\n2) Add Voice Note \n3) Upload Medical Report"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthVoiceNote', 
          type: 'default',
          position: { x: 5500, y: 0 }, 
          data: { 
            label: 'Add Voice Note',
            content: "User Input"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthUploadReport', 
          type: 'default',
          position: { x: 5500, y: 100 }, 
          data: { 
            label: 'Upload Medical Report',
            content: "User Input"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthPayment', 
          type: 'default',
          position: { x: 5500, y: -200 }, 
          data: { 
            label: 'Payment Information',
            content: "Review Payment Information\n\nPlease make the payment within 15 minutes to confirm your booking.\nYou may proceed to payment options below\n\nBooking reference number: 4124317718\nDoctor Name: Dr. Marwan Alkhattabi\nVideo Consultation fee: SAR 18\nVAT: SAR 2.70\nAmount Decuctible: SAR 20.7\n\nNOTE: Any other services or medicines will be charged"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'telehealthPaymentGateway', 
          type: 'default',
          position: { x: 5850, y: -200 }, 
          data: { 
            label: 'Payment Gateway',
            content: "* Redirects to Payment Gateway *"
          },
          targetPosition: 'left' 
        },

        // International Telemedicine path nodes
        // Note: These should be inserted into the nodes array of the contact doctor flow
        { 
          id: 'internationalTelemedicine', 
          type: 'default',
          position: { x: 250, y: -150 }, 
          data: { 
            label: 'International Telemedicine',
            content: "Is your chronic health condition stable?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalOptions', 
          type: 'default',
          position: { x: 600, y: -150 }, 
          data: { 
            label: 'International Options',
            content: "1) Yes\n2) No"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalStableNo', 
          type: 'default',
          position: { x: 950, y: -50 }, 
          data: { 
            label: 'International Stable - No',
            content: "We highly recommend to visit the treating doctor and update the prescription or choose one of the below services.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'internationalStableYes', 
          type: 'default',
          position: { x: 950, y: -180 }, 
          data: { 
            label: 'International Stable - Yes',
            content: "Please read and confirm the following to determine the eligibility and suitability of the International Telemedicine service and confirm the patient is free of any emergency that requires an immediate direct emergency visit to receive the necessary health care.\n\nI declare that I do not have any of the following medical problems:\nwww.bupa.com/terms\n\nNOTE: This medical service is provided by licensed healthcare providers. If this is an emergency please visit nearest ER"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalConfirm', 
          type: 'default',
          position: { x: 1300, y: -180 }, 
          data: { 
            label: 'International Confirm',
            content: "1) Yes! proceed"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalMemberSelect', 
          type: 'default',
          position: { x: 1650, y: -180 }, 
          data: { 
            label: 'International Member Selection',
            content: "Choose a member"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalMembers', 
          type: 'default',
          position: { x: 2000, y: -180 }, 
          data: { 
            label: 'International Members',
            content: "1) Sayyed Jafar \n35390274\n2) Fathima Badariya Haribamanzil\n35390275\n3) Ayisha Hisma Sayyed Jafar Maliyakkal\n35390276\n4) Sayed Abdul Basit Kala\n35390277"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalSpeciality', 
          type: 'default',
          position: { x: 2350, y: -180 }, 
          data: { 
            label: 'International Speciality',
            content: "Please select the speciality from the list below:\n\n1) Family Doctor\n2) Internist\n3) Pediatrician\n4) Gynaecology & Obstetrics\n5) Nutritionist\n6) General physician\n7) Psychiatrist\n8) Rheumatologist\n9) Endocrinologist"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalMonth', 
          type: 'default',
          position: { x: 2700, y: -180 }, 
          data: { 
            label: 'International Month',
            content: "Select Month:\n\n1) Jan \n2) Feb\n3) Mar\n4) Apr"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalDay', 
          type: 'default',
          position: { x: 3050, y: -180 }, 
          data: { 
            label: 'International Day',
            content: "Select Day:\n\n1) 7th Friday\n2) 8th Saturday\n3) 9th Sunday"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalDoctor', 
          type: 'default',
          position: { x: 3400, y: -180 }, 
          data: { 
            label: 'International Doctor',
            content: "Select Doctor:\n\n1) Dr. John Smith\nFamily Doctor\n10 years experience\n\n2) Dr. Emily Johnson\nFamily Doctor\n8 years experience"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalConsent', 
          type: 'default',
          position: { x: 3750, y: -180 }, 
          data: { 
            label: 'International Consent',
            content: "Patient Telemedicine Consent\n\nPlease review and agree to the following terms before receiving International Telemedicine services.\n\nI hereby authorize Bupa International to use the telehealth practice platform for telecommunication for evaluating, testing, and diagnosing my medical condition.\n\nwww.bupa.com/terms"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalConsentOptions', 
          type: 'default',
          position: { x: 4100, y: -180 }, 
          data: { 
            label: 'International Consent Options',
            content: "1) I Don't Agree\n2) I Agree"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalSlots', 
          type: 'default',
          position: { x: 4450, y: -180 }, 
          data: { 
            label: 'International Slots',
            content: "Select Slots:\n\nMorning:\n1) 9:00 AM\n2) 9:15 AM\n3) 9:30 AM\n4) 9:45 AM\n5) 10:00 AM"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalMedicalCondition', 
          type: 'default',
          position: { x: 4800, y: -180 }, 
          data: { 
            label: 'International Medical Condition',
            content: "Describe your medical condition:\n\nShare with the doctor, if you have any medical conditions, allergies and if you're taking any medication.\n\nAdd Voice Note (Optional)\nUpload Medical Report (Optional)"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalBookingOptions', 
          type: 'default',
          position: { x: 5150, y: -180 }, 
          data: { 
            label: 'International Booking Options',
            content: "1) Confirm Booking\n2) Add Voice Note \n3) Upload Medical Report"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalVoiceNote', 
          type: 'default',
          position: { x: 5500, y: -230 }, 
          data: { 
            label: 'International Voice Note',
            content: "User Input"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalUploadReport', 
          type: 'default',
          position: { x: 5500, y: -130 }, 
          data: { 
            label: 'International Upload Report',
            content: "User Input"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalPayment', 
          type: 'default',
          position: { x: 5500, y: -300 }, 
          data: { 
            label: 'International Payment',
            content: "Review Payment Information\n\nPlease make the payment within 15 minutes to confirm your booking.\nYou may proceed to payment options below\n\nBooking reference number: 4125318719\nDoctor Name: Dr. John Smith\nVideo Consultation fee: SAR 75\nVAT: SAR 11.25\nAmount Decuctible: SAR 86.25\n\nNOTE: Any other services or medicines will be charged"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'internationalPaymentGateway', 
          type: 'default',
          position: { x: 5850, y: -300 }, 
          data: { 
            label: 'International Payment Gateway',
            content: "* Redirects to Payment Gateway *"
          },
          targetPosition: 'left' 
        },
    
        // Appointment Booking path
        { 
          id: 'appointmentBooking', 
          type: 'default',
          position: { x: 250, y: 300 }, 
          data: { 
            label: 'Appointment Booking',
            content: "Is your chronic health condition stable?"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentOptions', 
          type: 'default',
          position: { x: 600, y: 300 }, 
          data: { 
            label: 'Appointment Options',
            content: "1) Yes\n2) No"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentStableNo', 
          type: 'default',
          position: { x: 950, y: 400 }, 
          data: { 
            label: 'Appointment Stable - No',
            content: "We highly recommend to visit the treating doctor and update the prescription or choose one of the below services.\n\nIf you need further assistance, please write down your question directly or write menu to go back to Main Menu."
          },
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentStableYes', 
          type: 'default',
          position: { x: 950, y: 200 }, 
          data: { 
            label: 'Appointment Stable - Yes',
            content: "Find Doctor by location or Speciality\nSelect location:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'findDoctorOptions', 
          type: 'default',
          position: { x: 1300, y: 200 }, 
          data: { 
            label: 'Find Doctor Options',
            content: "1) Location\n2) Speciality"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'locationSelect', 
          type: 'default',
          position: { x: 1650, y: 150 }, 
          data: { 
            label: 'Location Select',
            content: "Please select City:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'specialitySelect', 
          type: 'default',
          position: { x: 1650, y: 250 }, 
          data: { 
            label: 'Speciality Select',
            content: "Please select Speciality"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'cityOptions', 
          type: 'default',
          position: { x: 2000, y: 150 }, 
          data: { 
            label: 'City Options',
            content: "1) Abha\n2) Ad Darb\n3) Ahad Rufaidah\n4) Al Ahsa\n5) Al Aqiq\n6) Al Badi'ah\n7) Al Faihaa\n8) Al Majmaah\n9) Al Mubarraz\n10) More"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'specialityOptions', 
          type: 'default',
          position: { x: 2000, y: 250 }, 
          data: { 
            label: 'Speciality Options',
            content: "1) General Practitioner\n2) Dentist\n3) Dietician\n4) Ear Nose Throat\n5) Gynaecologist\n6) Pediatrician\n7) Ophthalmologist\n8) Dermatologist"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'doctorSelect', 
          type: 'default',
          position: { x: 2350, y: 200 }, 
          data: { 
            label: 'Doctor Select',
            content: "Please Select Doctor:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'doctorList', 
          type: 'default',
          position: { x: 2700, y: 200 }, 
          data: { 
            label: 'Doctor List',
            content: "1) Dr. Ali Mohammad\n2) Dr. Dina Elkersh\n3) Dr. Amany"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'doctorDetails', 
          type: 'default',
          position: { x: 3050, y: 200 }, 
          data: { 
            label: 'Doctor Details',
            content: "Dr. Ali Mohammad Abdel-Latif Refai\nConsultant Family Medicine\n\nFacility : The First Clinic\nConsultation Type : Consultant\nSelect Day:"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentDaySelect', 
          type: 'default',
          position: { x: 3400, y: 200 }, 
          data: { 
            label: 'Appointment Day Select',
            content: "1) 7th Friday\n2) 8th Saturday\n3) 9th Sunday"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentTimePreference', 
          type: 'default',
          position: { x: 3750, y: 200 }, 
          data: { 
            label: 'Appointment Time Preference',
            content: "Select your preference:\n\n1) Morning\n2) Afternoon\n3) Evening"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentSlotSelect', 
          type: 'default',
          position: { x: 4100, y: 200 }, 
          data: { 
            label: 'Appointment Slot Select',
            content: "Select Slot:\n\n1) 12:00\n2) 12:15\n3) 12:30\n4) 12:45\n5) 01:00"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'appointmentConfirmation', 
          type: 'default',
          position: { x: 4450, y: 200 }, 
          data: { 
            label: 'Appointment Confirmation',
            content: "Dr. Ali Mohammad Abdel-Latif Refai\nConsultant Family Medicine\nThe First Clinic\nSaturday, March 8, 2025 - 05:15 PM\n\nPatient Details :\nGender : Male\nFirst Name: Sayyed\nLast Name : Jafar\nIqama Number : 2044313118\nDOB : 01- January- 1969\nEmail : sayyedj.vm@gmail.com\nphone number : +966 568067272"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'completeBooking', 
          type: 'default',
          position: { x: 4800, y: 200 }, 
          data: { 
            label: 'Complete Booking',
            content: "Complete Your Booking"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        { 
          id: 'bookingSuccess', 
          type: 'default',
          position: { x: 5150, y: 200 }, 
          data: { 
            label: 'Booking Success',
            content: "Your Appointment is Confirmed.\nDear Sayed, your request has been submitted and we will call you within 24 hours.\nRequest reference number is :\n156609"
          },
          sourcePosition: 'right',
          targetPosition: 'left' 
        },
        
        // End node
        { 
          id: 'endFlow', 
          type: 'default',
          position: { x: 6300, y: 0 }, 
          data: { 
            label: 'Flow End',
            content: "The flow ends here."
          },
          targetPosition: 'left' 
        }
      ],
      edges: [
        // Starting edge
        { id: 'e-flow-start', source: 'flowStart', target: 'welcome', animated: true },
        { id: 'e-welcome-options', source: 'welcome', target: 'serviceOptions', animated: true },
        { id: 'e-options-doctor', source: 'serviceOptions', target: 'contactDoctor', label: 'Option 4', animated: true },
        { id: 'e-doctor-options', source: 'contactDoctor', target: 'doctorOptions', animated: true },
        
        // Contact Tebtom Doctor path
        { id: 'e-options-tebtom', source: 'doctorOptions', target: 'tebtomDoctor', label: 'Option 1', animated: true },
        { id: 'e-tebtom-methods', source: 'tebtomDoctor', target: 'contactMethods', animated: true },
        { id: 'e-methods-chat', source: 'contactMethods', target: 'chatOption', label: 'Chat', animated: true },
        { id: 'e-methods-call', source: 'contactMethods', target: 'callOption', label: 'Call', animated: true },
        { id: 'e-chat-yes', source: 'chatOption', target: 'diseaseControlledYes', label: 'Yes', animated: true },
        { id: 'e-chat-no', source: 'chatOption', target: 'diseaseControlledNo', label: 'No', animated: true },
        { id: 'e-yes-busy', source: 'diseaseControlledYes', target: 'agentsBusy', animated: true },
        
        // End paths for Tebtom Doctor
        { id: 'e-call-end', source: 'callOption', target: 'endFlow', animated: true },
        { id: 'e-no-end', source: 'diseaseControlledNo', target: 'endFlow', animated: true },
        { id: 'e-busy-end', source: 'agentsBusy', target: 'endFlow', animated: true },
        
        // Telehealth path
        { id: 'e-options-telehealth', source: 'doctorOptions', target: 'telehealth', label: 'Option 2', animated: true },
        { id: 'e-telehealth-options', source: 'telehealth', target: 'telehealthOptions', animated: true },
        { id: 'e-telehealth-yes', source: 'telehealthOptions', target: 'telehealthStableYes', label: 'Yes', animated: true },
        { id: 'e-telehealth-no', source: 'telehealthOptions', target: 'telehealthStableNo', label: 'No', animated: true },
        { id: 'e-telehealth-no-end', source: 'telehealthStableNo', target: 'endFlow', animated: true },
        { id: 'e-telehealth-stable-confirm', source: 'telehealthStableYes', target: 'telehealthConfirm', animated: true },
        { id: 'e-confirm-member', source: 'telehealthConfirm', target: 'telehealthMemberSelect', animated: true },
        { id: 'e-member-list', source: 'telehealthMemberSelect', target: 'telehealthMembers', animated: true },
        { id: 'e-members-speciality', source: 'telehealthMembers', target: 'telehealthSpeciality', label: 'Select Member', animated: true },
        { id: 'e-speciality-month', source: 'telehealthSpeciality', target: 'telehealthMonth', label: 'Select Speciality', animated: true },
        { id: 'e-month-day', source: 'telehealthMonth', target: 'telehealthDay', label: 'Select Month', animated: true },
        { id: 'e-day-doctor', source: 'telehealthDay', target: 'telehealthDoctor', label: 'Select Day', animated: true },
        { id: 'e-doctor-consent', source: 'telehealthDoctor', target: 'telehealthConsent', label: 'Select Doctor', animated: true },
        { id: 'e-consent-options', source: 'telehealthConsent', target: 'telehealthConsentOptions', animated: true },
        { id: 'e-consent-slots', source: 'telehealthConsentOptions', target: 'telehealthSlots', label: 'I Agree', animated: true },
        { id: 'e-slots-condition', source: 'telehealthSlots', target: 'telehealthMedicalCondition', label: 'Select Slot', animated: true },
        { id: 'e-condition-options', source: 'telehealthMedicalCondition', target: 'telehealthBookingOptions', animated: true },
        
        // Telehealth confirmation paths
        { id: 'e-options-voice', source: 'telehealthBookingOptions', target: 'telehealthVoiceNote', label: 'Add Voice Note', animated: true },
        { id: 'e-options-upload', source: 'telehealthBookingOptions', target: 'telehealthUploadReport', label: 'Upload Report', animated: true },
        { id: 'e-options-confirm', source: 'telehealthBookingOptions', target: 'telehealthPayment', label: 'Confirm Booking', animated: true },
        { id: 'e-voice-options', source: 'telehealthVoiceNote', target: 'telehealthBookingOptions', animated: true },
        { id: 'e-upload-options', source: 'telehealthUploadReport', target: 'telehealthBookingOptions', animated: true },
        { id: 'e-payment-gateway', source: 'telehealthPayment', target: 'telehealthPaymentGateway', animated: true },
        { id: 'e-gateway-end', source: 'telehealthPaymentGateway', target: 'endFlow', animated: true },
        
        // International Telemedicine edges
        // Note: These should be inserted into the edges array of the contact doctor flow
        { id: 'e-options-international', source: 'doctorOptions', target: 'internationalTelemedicine', label: 'Option 3', animated: true },
        { id: 'e-international-options', source: 'internationalTelemedicine', target: 'internationalOptions', animated: true },
        { id: 'e-international-yes', source: 'internationalOptions', target: 'internationalStableYes', label: 'Yes', animated: true },
        { id: 'e-international-no', source: 'internationalOptions', target: 'internationalStableNo', label: 'No', animated: true },
        { id: 'e-international-no-end', source: 'internationalStableNo', target: 'endFlow', animated: true },
        { id: 'e-international-stable-confirm', source: 'internationalStableYes', target: 'internationalConfirm', animated: true },
        { id: 'e-international-confirm-member', source: 'internationalConfirm', target: 'internationalMemberSelect', animated: true },
        { id: 'e-international-member-list', source: 'internationalMemberSelect', target: 'internationalMembers', animated: true },
        { id: 'e-international-members-speciality', source: 'internationalMembers', target: 'internationalSpeciality', label: 'Select Member', animated: true },
        { id: 'e-international-speciality-month', source: 'internationalSpeciality', target: 'internationalMonth', label: 'Select Speciality', animated: true },
        { id: 'e-international-month-day', source: 'internationalMonth', target: 'internationalDay', label: 'Select Month', animated: true },
        { id: 'e-international-day-doctor', source: 'internationalDay', target: 'internationalDoctor', label: 'Select Day', animated: true },
        { id: 'e-international-doctor-consent', source: 'internationalDoctor', target: 'internationalConsent', label: 'Select Doctor', animated: true },
        { id: 'e-international-consent-options', source: 'internationalConsent', target: 'internationalConsentOptions', animated: true },
        { id: 'e-international-consent-slots', source: 'internationalConsentOptions', target: 'internationalSlots', label: 'I Agree', animated: true },
        { id: 'e-international-slots-condition', source: 'internationalSlots', target: 'internationalMedicalCondition', label: 'Select Slot', animated: true },
        { id: 'e-international-condition-options', source: 'internationalMedicalCondition', target: 'internationalBookingOptions', animated: true },

        // International Telemedicine confirmation paths
        { id: 'e-international-options-voice', source: 'internationalBookingOptions', target: 'internationalVoiceNote', label: 'Add Voice Note', animated: true },
        { id: 'e-international-options-upload', source: 'internationalBookingOptions', target: 'internationalUploadReport', label: 'Upload Report', animated: true },
        { id: 'e-international-options-confirm', source: 'internationalBookingOptions', target: 'internationalPayment', label: 'Confirm Booking', animated: true },
        { id: 'e-international-voice-options', source: 'internationalVoiceNote', target: 'internationalBookingOptions', animated: true },
        { id: 'e-international-upload-options', source: 'internationalUploadReport', target: 'internationalBookingOptions', animated: true },
        { id: 'e-international-payment-gateway', source: 'internationalPayment', target: 'internationalPaymentGateway', animated: true },
        { id: 'e-international-gateway-end', source: 'internationalPaymentGateway', target: 'endFlow', animated: true },
        
        // Appointment Booking path
        { id: 'e-options-appointment', source: 'doctorOptions', target: 'appointmentBooking', label: 'Option 4', animated: true },
        { id: 'e-appointment-options', source: 'appointmentBooking', target: 'appointmentOptions', animated: true },
        { id: 'e-appointment-yes', source: 'appointmentOptions', target: 'appointmentStableYes', label: 'Yes', animated: true },
        { id: 'e-appointment-no', source: 'appointmentOptions', target: 'appointmentStableNo', label: 'No', animated: true },
        { id: 'e-appointment-no-end', source: 'appointmentStableNo', target: 'endFlow', animated: true },
        { id: 'e-appointment-find', source: 'appointmentStableYes', target: 'findDoctorOptions', animated: true },
        { id: 'e-find-location', source: 'findDoctorOptions', target: 'locationSelect', label: 'Location', animated: true },
        { id: 'e-find-speciality', source: 'findDoctorOptions', target: 'specialitySelect', label: 'Speciality', animated: true },
        { id: 'e-location-cities', source: 'locationSelect', target: 'cityOptions', animated: true },
        { id: 'e-speciality-options', source: 'specialitySelect', target: 'specialityOptions', animated: true },
        { id: 'e-cities-doctor', source: 'cityOptions', target: 'doctorSelect', label: 'Select City', animated: true },
        { id: 'e-specialities-doctor', source: 'specialityOptions', target: 'doctorSelect', label: 'Select Speciality', animated: true },
        { id: 'e-doctor-select-list', source: 'doctorSelect', target: 'doctorList', animated: true },
        { id: 'e-doctor-list-details', source: 'doctorList', target: 'doctorDetails', label: 'Select Doctor', animated: true },
        { id: 'e-details-day', source: 'doctorDetails', target: 'appointmentDaySelect', animated: true },
        { id: 'e-day-time', source: 'appointmentDaySelect', target: 'appointmentTimePreference', label: 'Select Day', animated: true },
        { id: 'e-time-slot', source: 'appointmentTimePreference', target: 'appointmentSlotSelect', label: 'Select Time', animated: true },
        { id: 'e-slot-confirmation', source: 'appointmentSlotSelect', target: 'appointmentConfirmation', label: 'Select Slot', animated: true },
        { id: 'e-confirmation-complete', source: 'appointmentConfirmation', target: 'completeBooking', animated: true },
        { id: 'e-complete-success', source: 'completeBooking', target: 'bookingSuccess', animated: true },
        { id: 'e-success-end', source: 'bookingSuccess', target: 'endFlow', animated: true },
      ],
    },
  ];