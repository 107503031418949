import React, { useState } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ documentPreview }) => {
    const [error, setError] = useState(null);

    const handleError = (e) => {
      console.error('Error in PDF viewer:', e);
      setError('Failed to load PDF. Please check the file and try again.');
    };
  
    if (!documentPreview) {
      return <div>No PDF file selected</div>;
    }
  
    return (
      <div style={{ height: '200px', width: '100%', overflow: 'auto', border: '1px solid #ccc' }}>
        {error ? (
          <div style={{ color: 'red', padding: '10px' }}>{error}</div>
        ) : (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={documentPreview}
              defaultScale={SpecialZoomLevel.PageFit}
              onError={handleError}
            />
          </Worker>
        )}
      </div>
    );
  };

export default PDFViewer;