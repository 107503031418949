import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { HiBell } from "react-icons/hi";
import moment from "moment";
import "react-tabs/style/react-tabs.css";
import DeleteHealthTask from "./DeleteHealthTask";
import ViewHealthTaskModal from "./ViewHealthTaskModal";
import CompleteHealthTask from "./CompleteHealthTask";
import EditHealthTask from "./EditHealthTask";

function ProfileTabs() {
  // Initialize with null and update after loading
  const [assignedTasks, setAssignedTasks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [expandedTaskIndex, setExpandedTaskIndex] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState({ task: null, index: null });

  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const [currentMonth, setCurrentMonth] = useState(moment());

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showBgLogModal, setShowBgLogModal] = useState(false);
  const [taskToComplete, setTaskToComplete] = useState({
    task: null,
    index: null,
  });
  const [bgLevel, setBgLevel] = useState("");
  const [bgTime, setBgTime] = useState("11:15");
  const [timeFormat, setTimeFormat] = useState("pm");
  const [bgType, setBgType] = useState("Fasting"); // 'Fasting' or 'Postprandial'
  const [mealType, setMealType] = useState("Random");
  const [selectedMeal, setSelectedMeal] = useState(null); // 'Breakfast', 'Lunch', 'Snack', 'Dinner'

  const [showEditModal, setShowEditModal] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState({ task: null, index: null });
  const [editedTaskDescription, setEditedTaskDescription] = useState("");
  const [editedPeriodNumber, setEditedPeriodNumber] = useState("");
  const [editedPeriodUnit, setEditedPeriodUnit] = useState("Days");

  // Define a custom event name for consistency
  const STORAGE_UPDATE_EVENT = "healthTasksUpdated";
  const STORAGE_KEY = "healthTasksData";

  const localStorageSavedJSON = localStorage.getItem(STORAGE_KEY);

  // Fetch assigned tasks from local storage
  useEffect(() => {
    const loadTasks = () => {
      setIsLoading(true);
      try {
        const storedTasks = localStorageSavedJSON;
        if (storedTasks) {
          const parsedData = JSON.parse(storedTasks);
          setAssignedTasks(parsedData);
        } else {
          // Set to empty state if no data found
          setAssignedTasks({ condition: "", tasks: [] });
        }
      } catch (error) {
        console.error("Error loading tasks:", error);
        setAssignedTasks({ condition: "", tasks: [] });
      } finally {
        setIsLoading(false);
      }
    };

    loadTasks();

    // Listen for browser storage changes (for multi-tab scenarios)
    const handleStorageChange = (e) => {
      if (e.key === STORAGE_KEY) {
        loadTasks();
      }
    };
    
    // Listen for custom events (for same-tab updates)
    const handleCustomEvent = () => {
      loadTasks();
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener(STORAGE_UPDATE_EVENT, handleCustomEvent);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener(STORAGE_UPDATE_EVENT, handleCustomEvent);
    };
  }, [localStorageSavedJSON]);

  // Function to update tasks in local storage and trigger update event
  const updateTasksInStorage = (updatedTasks) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedTasks));
    setAssignedTasks(updatedTasks);
    
    // Use CustomEvent instead of Event for better compatibility
    window.dispatchEvent(new CustomEvent(STORAGE_UPDATE_EVENT));
  };

  // Progress circle component
  const ProgressCircle = ({ progress, color }) => {
    return (
      <div className="tw-relative tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center">
        <svg
          className="tw-w-8 tw-h-8 tw-transform tw-rotate-[-90deg] tw-scale-110"
          viewBox="0 0 36 36"
        >
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="tw-stroke-gray-200"
            strokeWidth="3"
          />
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="tw-stroke-current"
            strokeWidth="3"
            strokeDasharray="100"
            strokeDashoffset={100 - progress}
            strokeLinecap="round"
            style={{ color }}
          />
        </svg>
        <span className="tw-absolute tw-text-xs tw-font-medium tw-text-[#03335B]">
          {progress}%
        </span>
      </div>
    );
  };

  // Handle clicking on Actions button
  const toggleActionButtons = (index) => {
    setExpandedTaskIndex(expandedTaskIndex === index ? null : index);
  };

  // Handle action button functionalities
  const handleEditTask = (task, index) => {
    // Create a deep copy of the task to avoid reference issues
    const taskCopy = JSON.parse(JSON.stringify(task));
    
    setTaskToEdit({ 
      task: taskCopy, 
      index 
    });
    
    setEditedTaskDescription(taskCopy.description || "");
    setEditedPeriodNumber(taskCopy.periodNumber || "");
    setEditedPeriodUnit(taskCopy.periodUnit || "Days");
    
    setShowEditModal(true);
    setExpandedTaskIndex(null);
  };

  const cancelEditTask = () => {
    setShowEditModal(false);
    setTaskToEdit({ task: null, index: null });
  };

  const saveEditedTask = () => {
    if (!assignedTasks || !assignedTasks.tasks) {
      console.error("No tasks found to update");
      return;
    }

    // Create a deep copy of the tasks to avoid mutation issues
    const updatedTasks = JSON.parse(JSON.stringify(assignedTasks));

    // Validate index is in range
    if (taskToEdit.index < 0 || taskToEdit.index >= updatedTasks.tasks.length) {
      console.error("Task index out of bounds:", taskToEdit.index);
      return;
    }

    // Update the task at the specific index
    updatedTasks.tasks[taskToEdit.index] = {
      ...updatedTasks.tasks[taskToEdit.index],
      description: editedTaskDescription,
      periodNumber: editedPeriodNumber,
      periodUnit: editedPeriodUnit,
    };

    // Use the centralized update function
    updateTasksInStorage(updatedTasks);

    // Close the modal and reset state
    setShowEditModal(false);
    setTaskToEdit({ task: null, index: null });
  };

  const handleViewTask = (task, index) => {
    // Create a deep copy of the task
    const taskCopy = JSON.parse(JSON.stringify(task));
    setSelectedTask(taskCopy);
    setShowViewModal(true);
    setExpandedTaskIndex(null);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
    setSelectedTask(null);
  };

  const handleDeleteTask = (task, index) => {
    // Create a deep copy of the task
    const taskCopy = JSON.parse(JSON.stringify(task));
    setTaskToDelete({ task: taskCopy, index });
    setShowDeleteModal(true);
  };

  const confirmDeleteTask = () => {
    const { index } = taskToDelete;

    if (!assignedTasks || !assignedTasks.tasks) {
      return;
    }

    // Create a deep copy to avoid mutation issues
    const updatedTasks = JSON.parse(JSON.stringify(assignedTasks));
    updatedTasks.tasks = updatedTasks.tasks.filter((_, i) => i !== index);

    // Use the centralized update function
    updateTasksInStorage(updatedTasks);

    // Close the modal and reset states
    setShowDeleteModal(false);
    setTaskToDelete({ task: null, index: null });
    setExpandedTaskIndex(null);
  };

  const cancelDeleteTask = () => {
    setShowDeleteModal(false);
    setTaskToDelete({ task: null, index: null });
  };

  const handleCompleteTask = (task, index) => {
    // Create a deep copy of the task
    const taskCopy = JSON.parse(JSON.stringify(task));
    setTaskToComplete({ task: taskCopy, index });
    setShowCompleteModal(true);
    setExpandedTaskIndex(null);
  };

  // Function to handle Yes click on confirmation modal
  const handleConfirmYes = () => {
    setShowCompleteModal(false);

    // Show the blood glucose logging modal
    setShowBgLogModal(true);

    // Reset BG logging values
    setBgLevel("");
    setBgType("Fasting");
    setMealType("Random");
    setSelectedMeal(null);
  };

  // Function to handle No click on confirmation modal
  const handleConfirmNo = () => {
    setShowCompleteModal(false);
    setTaskToComplete({ task: null, index: null });
  };

  const closeBgLogModal = () => {
    setShowBgLogModal(false);
    setTaskToComplete({ task: null, index: null });
  };

  const saveBloodGlucoseData = () => {
    // Here you would save the data to your backend or local storage
    console.log("Saving blood glucose data:", {
      level: bgLevel,
      time: `${bgTime} ${timeFormat}`,
      type: bgType,
      mealType: bgType === "Postprandial" ? mealType : null,
      selectedMeal:
        bgType === "Postprandial" && mealType === "Before meal"
          ? selectedMeal
          : null,
      date: new Date().toISOString(),
      task: taskToComplete.task?.description,
    });

    // Also mark the task as complete by removing it
    if (assignedTasks && assignedTasks.tasks) {
      // Create a deep copy to avoid mutation issues
      const updatedTasks = JSON.parse(JSON.stringify(assignedTasks));
      updatedTasks.tasks = updatedTasks.tasks.filter(
        (_, i) => i !== taskToComplete.index
      );

      // Use the centralized update function
      updateTasksInStorage(updatedTasks);
    }

    // Close the modal and reset states
    setShowBgLogModal(false);
    setTaskToComplete({ task: null, index: null });

    // Show success message
    alert("Blood glucose data saved successfully!");
  };

  // Determine if Active Tasks tab should be shown
  const hasActiveTasks = !isLoading && assignedTasks && 
    assignedTasks.tasks && assignedTasks.tasks.length > 0;

  // Handle tab selection with proper bounds checking
  const handleTabSelect = (index) => {
    const maxTabs = hasActiveTasks ? 2 : 1;
    if (index < maxTabs) {
      setSelectedTabIndex(index);
    } else {
      setSelectedTabIndex(0);
    }
  };

  // Show loading state while fetching data
  if (isLoading) {
    return <div className="tw-p-4 tw-text-center">Loading tasks...</div>;
  }

  return (
    <div className="tw-w-full tw-mx-auto">
      <Tabs
        selectedIndex={selectedTabIndex}
        onSelect={handleTabSelect}
        forceRenderTabPanel={true}
      >
        <TabList>
          <Tab>
            <span className="tw-text-xs">Upcoming</span>
          </Tab>
          {hasActiveTasks && (
            <Tab>
              <span className="tw-text-xs">Active Tasks</span>
            </Tab>
          )}
        </TabList>

        {/* Appointments Tab Panel */}
        <TabPanel>
          <div className="content-aside-inner-rpm-footer">
            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Health appointment</h2>
                <h3>Mr Dok tomm</h3>
                <p>09:20AM - 11:30</p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>

            <div className="content-aside-inner-rpm-footer-item">
              <div className="content-aside-inner-rpm-footer-item-icon">
                <HiBell />
              </div>
              <div className="content-aside-inner-rpm-footer-item-text">
                <h2>Meeting with Tok Dalang</h2>
                <p>
                  07:00AM <span>Due Soon</span>
                </p>
              </div>
            </div>
          </div>
        </TabPanel>

        {/* Active Tasks Tab Panel - always render but conditionally show content */}
        <TabPanel>
          {hasActiveTasks ? (
            <div>
              {/* Display assigned tasks from local storage */}
              {assignedTasks.condition && (
                <div className="tw-bg-gray-50 tw-rounded-lg border tw-border-gray-100 tw-p-2 tw-shadow-lg">
                  <h3 className="tw-text-base tw-font-bold tw-mb-2 tw-text-gray-700">
                    {assignedTasks.condition.toUpperCase()}
                  </h3>

                  {assignedTasks.tasks.map((task, index) => (
                    <div key={index} className="tw-mb-2">
                      {expandedTaskIndex !== index ? (
                        // Normal task view with Actions button
                        <div className="tw-flex tw-items-center tw-justify-between tw-p-1 border-bottom tw-border-gray-200">
                          <div className="tw-flex tw-items-center">
                            <div className="tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-3">
                              <ProgressCircle
                                progress={Math.floor(Math.random() * 65)}
                                color="#03335B"
                              />
                            </div>
                            <span className="tw-text-[#03335B] tw-font-semibold tw-capitalize tw-text-sm">
                              {task.description}
                            </span>
                          </div>

                          <div className="tw-flex tw-items-center">
                            <div className="tw-flex tw-space-x-1">
                              <button
                                className="action-button blue-btn tw-text-xs tw-py-1 tw-px-2"
                                onClick={() => toggleActionButtons(index)}
                              >
                                Actions
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Expanded action buttons view
                        <div className="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-md border tw-border-gray-200">
                          <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                            <button
                              className="action-button blue-btn tw-text-xs tw-py-1 tw-px-2"
                              onClick={() => handleEditTask(task, index)}
                            >
                              <i className="bi bi-pencil-square tw-mr-1"></i>{" "}
                              Edit Task
                            </button>
                            <button
                              className="action-button blue-btn tw-text-xs tw-py-1 tw-px-2"
                              onClick={() => handleViewTask(task, index)}
                            >
                              <i className="bi bi-eye tw-mr-1"></i> View Task
                            </button>
                            <button
                              className="action-button tw-bg-[#03335B] hover:tw-bg-orange-700 tw-text-xs tw-py-1 tw-px-2"
                              onClick={() => handleDeleteTask(task, index)}
                            >
                              <i className="bi bi-trash tw-mr-1"></i> Delete
                              Task
                            </button>
                            <button
                              className="action-button tw-bg-[#03335B] hover:tw-bg-green-700 tw-text-xs tw-py-1 tw-px-2"
                              onClick={() => handleCompleteTask(task, index)}
                            >
                              <i className="bi bi-check-circle tw-mr-1"></i>{" "}
                              Complete Task
                            </button>
                          </div>
                          <button
                            className="action-button blue-btn tw-text-xs tw-py-1 tw-px-2 tw-mt-2 tw-w-full"
                            onClick={() => setExpandedTaskIndex(null)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="tw-p-4 tw-text-center tw-text-gray-500">
              No active tasks found.
            </div>
          )}
        </TabPanel>
      </Tabs>

      {/* Delete Confirmation Modal */}
      <DeleteHealthTask
        showDeleteModal={showDeleteModal}
        cancelDeleteTask={cancelDeleteTask}
        confirmDeleteTask={confirmDeleteTask}
        taskToDelete={taskToDelete}
      />  

      {/* View Task Progress Modal */}
      <ViewHealthTaskModal
        showViewModal={showViewModal}
        closeViewModal={closeViewModal}
        selectedTask={selectedTask}
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
      />

      {/* Complete Task Confirmation Modal */}
      <CompleteHealthTask
        showCompleteModal={showCompleteModal}
        handleConfirmYes={handleConfirmYes}
        handleConfirmNo={handleConfirmNo}
        taskToComplete={taskToComplete}
        showBgLogModal={showBgLogModal}
        closeBgLogModal={closeBgLogModal}
        bgLevel={bgLevel}
        setBgLevel={setBgLevel}
        bgType={bgType}
        setBgType={setBgType}
        mealType={mealType}
        setMealType={setMealType}
        selectedMeal={selectedMeal}
        setSelectedMeal={setSelectedMeal}
        saveBloodGlucoseData={saveBloodGlucoseData}
        bgTime={bgTime}
        setBgTime={setBgTime}
        timeFormat={timeFormat}
        setTimeFormat={setTimeFormat}
      />

      {/* Edit Task Modal */}
      <EditHealthTask
        showEditModal={showEditModal}
        cancelEditTask={cancelEditTask}
        editedTaskDescription={editedTaskDescription} 
        setEditedTaskDescription={setEditedTaskDescription}
        editedPeriodNumber={editedPeriodNumber} 
        setEditedPeriodNumber={setEditedPeriodNumber}
        saveEditedTask={saveEditedTask}
        editedPeriodUnit={editedPeriodUnit}
        setEditedPeriodUnit={setEditedPeriodUnit}
      />
    </div>
  );
}

export default ProfileTabs;