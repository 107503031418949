import React from "react";
import { Modal } from "react-bootstrap";

const EditHealthTask = ({
  showEditModal,
  cancelEditTask,
  editedTaskDescription,
  setEditedTaskDescription,
  editedPeriodNumber,
  setEditedPeriodNumber,
  saveEditedTask,
    editedPeriodUnit,
    setEditedPeriodUnit
}) => {
  return (
    <Modal show={showEditModal} onHide={cancelEditTask} centered>
      <Modal.Header className="tw-flex-row" closeButton>
        <Modal.Title className="tw-text-lg tw-mt-0 tw-w-[90%] tw-text-center tw-pl-[10%]">
          Edit Health Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-p-1">
          <div className="tw-mb-3">
            <label className="fw-bold d-block mb-2">
              Health Task Description
            </label>
            <input
              type="text"
              className="form-control input-field-ui"
              value={editedTaskDescription}
              onChange={(e) => setEditedTaskDescription(e.target.value)}
              placeholder="Enter health task description"
              style={{ borderRadius: "8px", border: "1px solid #e0e0e0" }}
            />
          </div>

          <div className="tw-mb-3">
            <label className="fw-bold d-block mb-2">For Period</label>
            <div className="row">
              <div className="col-md-6 mb-2 mb-md-0">
                <div className="input-group">
                  <div
                    className="input-group-prepend"
                    style={{
                      borderRadius: "8px 0 0 8px",
                      backgroundColor: "white",
                      padding: "0.375rem 0.75rem",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <span className="input-group-text bg-white border-0 p-0">
                      <i className="bi bi-calendar3"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Number"
                    value={editedPeriodNumber}
                    onChange={(e) => setEditedPeriodNumber(e.target.value)}
                    style={{
                      borderRadius: "0 8px 8px 0",
                      border: "1px solid #e0e0e0",
                      borderLeft: "none",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div
                    className="input-group-prepend"
                    style={{
                      borderRadius: "8px 0 0 8px",
                      backgroundColor: "white",
                      padding: "0.375rem 0.75rem",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <span className="input-group-text bg-white border-0 p-0">
                      <i className="bi bi-clock"></i>
                    </span>
                  </div>
                  <select
                    className="form-select"
                    value={editedPeriodUnit}
                    onChange={(e) => setEditedPeriodUnit(e.target.value)}
                    style={{
                      borderRadius: "0 8px 8px 0",
                      border: "1px solid #e0e0e0",
                      borderLeft: "none",
                    }}
                  >
                    <option value="Days">Days</option>
                    <option value="Weeks">Weeks</option>
                    <option value="Months">Months</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-border-0 tw-justify-center">
        <button
          className="action-button tw-bg-gray-400 tw-py-2 tw-px-4 me-2"
          onClick={cancelEditTask}
        >
          Cancel
        </button>
        <button
          className="action-button blue-btn tw-py-2 tw-px-4"
          onClick={saveEditedTask}
          disabled={!editedTaskDescription || !editedPeriodNumber}
        >
          Update Task
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditHealthTask;
