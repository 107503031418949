import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


function HeartRate({ data }) {
    // Use data.history for your chart rendering
    const heartRateData = data?.history || [];

    
    // Use history data from context for the graph
    const timeLabels = heartRateData.map(item => item.date);
    const heartRateValues = heartRateData.map(item => item.value);

    const options = {
        chart: {
            type: 'line',
            height: 260,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: timeLabels,
            labels: {
                rotation: 0
            }
        },
        yAxis: {
            title: {
                text: 'Heart Rate'
            },
            min: null,
            max: null,
        },
        series: [{
            name: 'Time (Minutes)',
            data: heartRateValues,
            color: '#4A90E2',
        }],
        tooltip: {
            valueSuffix: ' bpm'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
    };

    return (
        <div className="col-md-12 mb-3">
            <div className="member-engagement">
                <h1 className='dashboard-graph-title mb-2'>
                    <span>Heart Rate</span>
                </h1>
                <h1 className="card-title1">{data?.current} <span>bpm (Max)</span></h1>
                <div className='mt-3'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

export default HeartRate;
