import { useState } from "react";
import { HiBell } from "react-icons/hi";
import Modal from "react-bootstrap/Modal";
import useDashboard from "../../../Hooks/useDashboard";
import ProfileTabs from "./MemberProfileTaskTab/ProfileTabs";
import { useVitals } from "./VitalsDisplayPanel/VitalsContext";

function MemberProfileDetailsBox() {
  const dashboard = useDashboard();
  const { memberName } = useVitals();
  const [showModalMyJourney, setShowModalMyJourney] = useState(false);
  const [showModalTask, setShowModalTask] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState('');
  const [tasks, setTasks] = useState([
    { name: 'Check Blood Glucose (Before & After Meal)', frequency: 'Daily', assigned: true },
    { name: 'Check Blood Pressure', frequency: 'Once a Week', assigned: true },
    { name: 'Check Heart Rate', frequency: 'Daily', assigned: true },
    { name: 'Check Temperature', frequency: 'Daily', assigned: true }
  ]);

  // For managing multiple health task entries
  const [healthTasks, setHealthTasks] = useState([
    { 
      description: 'Check Blood Glucose (Before and After Meal)',
      periodNumber: '',
      periodUnit: 'Days'
    }
  ]);

  // Define a custom event name for consistency across components
  const STORAGE_UPDATE_EVENT = "healthTasksUpdated";
  const STORAGE_KEY = "healthTasksData";

  const handleMyJourneyClose = () => {
    setShowModalMyJourney(false);
  };

  const conditions = [
    'Arrhythmia', 'Asthma', 'COPD', 'Diabetes Mellitus', 'Diabetes with hypothyroidism',
    'Diabetic Kidney Disease', 'Dyslipidemia', 'Hypertension', 'Hyperthyroidism',
    'Hypothyroidism', 'Mental Health', 'Mental Health Club two', 'Physical Health Club One',
    'Rheumatoid Arthritis', 'Systemic Lupus Erythematosus'
  ];

  const handleOpen = () => setShowModalTask(true);
  
  const handleClose = () => {
    setShowModalTask(false);
    setHealthTasks([
      {
        description: "Check Blood Glucose (Before and After Meal)",
        periodNumber: "",
        periodUnit: "Days",
      },
    ]);
    setSelectedCondition("");
  };

  const handleAddMoreTasks = () => {
    setHealthTasks([
      ...healthTasks,
      { 
        description: 'Check Blood Glucose (Before and After Meal)',
        periodNumber: '',
        periodUnit: 'Days'
      }
    ]);
  };
  
  const updateTaskField = (index, field, value) => {
    const updatedTasks = [...healthTasks];
    updatedTasks[index][field] = value;
    setHealthTasks(updatedTasks);
  };
  
  const handleSave = () => {
    // Validate input fields before saving
    const isValid = healthTasks.every(task => 
      task.description.trim() !== "" && 
      task.periodNumber.toString().trim() !== ""
    );
    
    if (!isValid) {
      alert("Please fill in all required fields for each task.");
      return;
    }
    
    if (!selectedCondition) {
      alert("Please select a condition.");
      return;
    }
    
    // Create a clean copy of the data to save
    const dataToSave = {
      condition: selectedCondition,
      tasks: healthTasks.map(task => ({
        description: task.description.trim(),
        periodNumber: task.periodNumber.toString().trim(),
        periodUnit: task.periodUnit
      })),
      savedAt: new Date().toISOString()
    };
    
    // Save to local storage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToSave));
    console.log('Data saved to local storage:', dataToSave);
    
    // Reset form
    setHealthTasks([{ 
      description: 'Check Blood Glucose (Before and After Meal)',
      periodNumber: '',
      periodUnit: 'Days'
    }]);
    setSelectedCondition('');
    
    // Use CustomEvent to notify other components about the update
    window.dispatchEvent(new CustomEvent(STORAGE_UPDATE_EVENT));
    
    // Close modal
    handleClose();
  };

  const [showAddPrescriptions, setShowAddPrescriptions] = useState(false);
  const handleDemoClose = () => {
    setShowAddPrescriptions(false);
  };

  return (
    <>
      <div className="content-aside-inner-rpm">
        <div className="content-aside-inner-rpm-header">
          <img
            src="https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png"
            className="rpm-profile-images"
            alt="profile"
          />
          <h1>{memberName}</h1>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="row px-2">

                {
                  dashboard?.role?.roleName === 'Doctor' && (
                    <>
                      <div className={`p-1 col-6`}>
                        <button
                          className="action-button blue-btn btn-100"
                          style={{
                            fontSize: "11px",
                            fontWeight: "500",
                            width: "100% !important"
                          }}
                          onClick={() => setShowAddPrescriptions(true)}
                        >
                          Add Prescriptions
                        </button>
                      </div>
                      <div className="col-6 p-1">
                        <button
                          className="action-button blue-btn btn-100"
                          style={{
                            fontSize: "11px",
                            fontWeight: "500",
                            width: "100% !important"
                          }}
                          onClick={handleOpen}
                        >
                          Assign Health Task
                        </button>
                      </div>
                    </>
                  )
                }

                <div className="col-6 p-1">
                  <button
                    className="action-button blue-btn btn-100"
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      width: "100% !important"
                    }}
                  >
                    Download Reports
                  </button>
                </div>
                <div className="col-6 p-1">
                  <button
                    className="action-button blue-btn btn-100"
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      width: "100% !important"
                    }}
                    onClick={() => setShowModalMyJourney(true)}
                  >
                    My Journey
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-aside-inner-rpm-body">
          <div className="content-aside-inner-rpm-body-item">
            <h2>Height</h2>
            <h3>186cm</h3>
          </div>
          <div className="content-aside-inner-rpm-body-item">
            <h2>Weight</h2>
            <h3>90kg</h3>
          </div>
        </div>
      </div>

      <ProfileTabs />

      <Modal
        dialogClassName=" modal-dialog-centered div-10p"
        show={showModalMyJourney}
        onHide={handleMyJourneyClose}
        id="showListDataTable"
        backdrop="static"
        keyboard={false}
      >
        <div className="modal-backdrop modulepups">
          <div className="modal-content modal-lg" style={{ width: "50%" }}>
            <div className="modal-header">
              <h1 className="modal-title">My Journey</h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleMyJourneyClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div id="container-timeline" style={{ width: "100%" }}>
                    <ol className="timeline-profile">
                      <li className="call">
                        <time>
                          <small>29 Dec, 2024</small>
                          <div className="big-profile">
                            Call with CareNavigator
                          </div>
                        </time>
                        <article>
                          <p>
                            Call with CareNavigator Siddhki was missed at 12:00
                            am.
                          </p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>29 Dec, 2024</small>
                          <div className="big-profile">Onboarded</div>
                        </time>
                        <article>
                          <p>Onboarded by Agent AgentA A</p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>29 Dec, 2024</small>
                          <div className="big-profile">Enrolled</div>
                        </time>
                        <article>
                          <p>
                            Enrolled for Care Connect Model / Diabetes Mellitus
                          </p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>29 Dec, 2024</small>
                          <div className="big-profile">Enrolled</div>
                        </time>
                        <article>
                          <p>Enrolled for Care Connect Model / Hypertension</p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>07 Jan, 2025</small>
                          <div className="big-profile">Enrolled</div>
                        </time>
                        <article>
                          <p>Enrolled for Care Connect Model For COPD / COPD</p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>07 Jan, 2025</small>
                          <div className="big-profile">Pathway Assigned</div>
                        </time>
                        <article>
                          <p>
                            Care Connect Model For COPD / COPD / GOLD ABE
                            Category A pathway assigned by CareNavigator
                          </p>
                        </article>
                      </li>
                      <li className="call">
                        <time>
                          <small>07 Jan, 2025</small>
                          <div className="big-profile">Educational Journey</div>
                        </time>
                        <article>
                          <p>Educational Journey assigned by CareNavigator</p>
                        </article>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Health Task Modal */}
      <Modal show={showModalTask} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '18px', fontWeight: '600' }}>
            Assign Health Task Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <label className="fw-bold mb-2 d-block">Select Condition</label>
            <select
              className="form-select input-field-ui"
              value={selectedCondition}
              onChange={(e) => setSelectedCondition(e.target.value)}
              style={{ borderRadius: '4px', border: '1px solid #ced4da' }}
            >
              <option value="" disabled>Select Condition</option>
              {conditions.map((condition, index) => (
                <option key={index} value={condition}>
                  {condition}
                </option>
              ))}
            </select>
          </div>
          {selectedCondition && (
            <div className="mb-3">
              <h6 className="fw-bold mb-3">{selectedCondition}</h6>
            </div>
          )}

          {selectedCondition === 'Diabetes Mellitus' && (
            <div className="p-1">
              {healthTasks.map((task, index) => (
                <div 
                  key={index} 
                  className={`mb-4 ${index > 0 ? 'pt-3' : ''} tw-shadow-lg tw-p-3 tw-rounded-xl border tw-border-gray-100`}
                >
                  <div className="mb-3">
                    <div className=" tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-1">
                    <label className="fw-bold d-block mb-2">Health Task Description</label>
                    {index > 0 && (
                    <div className="text-end">
                      <button 
                        className="btn tw-bg-[#03335b] hover:tw-bg-[#be4b12] tw-text-white tw-rounded-full tw-px-2 tw-py-1"
                        onClick={() => {
                          const updatedTasks = [...healthTasks];
                          updatedTasks.splice(index, 1);
                          setHealthTasks(updatedTasks);
                        }}
                      >
                        <i className="bi bi-dash-circle"></i>
                      </button>
                    </div>
                  )}
                    </div>
                    <input 
                      type="text" 
                      className="form-control input-field-ui" 
                      value={task.description}
                      onChange={(e) => updateTaskField(index, 'description', e.target.value)}
                      placeholder="Enter health task description"
                      style={{ borderRadius: '8px', border: '1px solid #e0e0e0' }}
                    />
                  </div>
                  
                  <div className="mb-3">
                    <label className="fw-bold d-block mb-2">
                      For Period
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-2 mb-md-0">
                        <div className="input-group">
                          <div className="input-group-prepend" style={{ borderRadius: '8px 0 0 8px', backgroundColor: 'white', padding: '0.375rem 0.75rem', border: '1px solid #e0e0e0' }}>
                            <span className="input-group-text bg-white border-0 p-0">
                              <i className="bi bi-calendar3"></i>
                            </span>
                          </div>
                          <input 
                            type="number" 
                            className="form-control" 
                            placeholder="Enter Number"
                            value={task.periodNumber}
                            onChange={(e) => updateTaskField(index, 'periodNumber', e.target.value)}
                            style={{ borderRadius: '0 8px 8px 0', border: '1px solid #e0e0e0', borderLeft: 'none' }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group">
                          <div className="input-group-prepend" style={{ borderRadius: '8px 0 0 8px', backgroundColor: 'white', padding: '0.375rem 0.75rem', border: '1px solid #e0e0e0' }}>
                            <span className="input-group-text bg-white border-0 p-0">
                              <i className="bi bi-clock"></i>
                            </span>
                          </div>
                          <select 
                            className="form-select"
                            value={task.periodUnit}
                            onChange={(e) => updateTaskField(index, 'periodUnit', e.target.value)}
                            style={{ borderRadius: '0 8px 8px 0', border: '1px solid #e0e0e0', borderLeft: 'none' }}
                          >
                            <option value="Days">Days</option>
                            <option value="Weeks">Weeks</option>
                            <option value="Months">Months</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none', display: 'flex', justifyContent: 'center', marginBottom: '5px', gap: '10px' }}>
          {selectedCondition === 'Diabetes Mellitus' && (
            <button
              className="action-button blue-btn tw-text-lg tw-py-3 tw-px-4"
              onClick={handleAddMoreTasks}
            >
              Add more
            </button>
          )}
          <button
            className="action-button blue-btn tw-text-lg tw-py-3 tw-px-4"
            onClick={handleSave}
            disabled={!selectedCondition}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered dialogClassName=" modal-dialog-centered div-10p"
        show={showAddPrescriptions} onHide={handleDemoClose} id="showListDataTable" backdrop="static" keyboard={false}>
        <div className="modal-backdrop modulepups">
          <div className="modal-content modal-lg" style={{ width: "60%" }}>
            <div className="modal-header">
              <h1 className="modal-title">Add Prescriptions </h1>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleDemoClose}></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row medicine-row">
                  {/* Medicine Name Field */}
                  <div className="col-md-3">
                    <div className="col-12 d-flex align-items-center justify-center justify-content-between">
                      <label className="form-label me-2 mb-0">Name</label>
                    </div>
                    <div className="">
                      <div className="css-13cymwt-control">
                        <div className="css-hlgwow">
                          <div className="css-19bb58m" >
                            <input
                              type="text"
                              name="medicine"
                              placeholder="Select Medicine"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Dosage Field */}
                  <div className="col-md-2">
                    <label>Dosage</label>
                    <div className="">
                      <div className="css-13cymwt-control">
                        <div className="css-hlgwow">
                          <div className="css-19bb58m" data-value="">
                            <input
                              type="text"
                              name="dosage"
                              placeholder="Select Dosage"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Duration Field */}
                  <div className="col-md-2">
                    <label>Duration</label>
                    <input
                      type="number"
                      name="duration"
                      placeholder="Duration"
                      className="form-control"
                    />
                    <select
                      name="durationType"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      <option value="Days">Days</option>
                      <option value="Weeks">Weeks</option>
                      <option value="Months">Months</option>
                    </select>
                  </div>

                  {/* Frequency Field */}
                  <div className="col-md-2">
                    <label>Frequency</label>
                    <select
                      name="frequency"
                      className="form-control"
                    >
                      <option value="">Select</option>
                      <option value="OD-Morning">OD-Morning</option>
                      <option value="OD-Night">OD-Night</option>
                      <option value="BD">BD</option>
                      <option value="TD">TD</option>
                      <option value="QID">QID</option>
                      <option value="SOS">SOS</option>
                    </select>
                  </div>

                  {/* Instructions Field */}
                  <div className="col-md-3">
                    <label>Instructions</label>
                    <input
                      type="text"
                      name="instructions"
                      placeholder="Before food, After food, etc."
                      className="form-control"
                    />
                  </div>

                  {/* Final Instructions Field */}
                  <div className="col-md-3">
                    <label>Final Instructions</label>
                    <input
                      type="text"
                      name="finalInstructions"
                      placeholder="Before food, After food, etc."
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="row">
                  <div className="col-12 text-center mb-4">
                    <button type="button" className="btn btn-primary addNewRow-btn me-2">
                      <i className="bi bi-plus"></i> Add More Prescriptions
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-12 text-center">
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button type="button" className="btn btn-primary save-btn" >
                      Submit
                    </button>
                  </div>
                  <div className="col-auto">
                    <button type="button" className="btn btn-primary save-btn">
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MemberProfileDetailsBox;