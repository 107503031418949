import React, { useEffect } from 'react';
import VitalsDisplayPanel from './VitalsDisplayPanel/VitalsDisplayPanel';
import { useVitals } from './VitalsDisplayPanel/VitalsContext';

function MemberProfileDetails({ memberId }) {
    const { vitalsData, setMemberId, isLoading } = useVitals();

    useEffect(() => { 
        setMemberId(memberId); 
    }, [memberId]); 

    return (
        <VitalsDisplayPanel memberId={memberId} vitalsDataProp={vitalsData} isLoading={isLoading} />
    );
}

export default MemberProfileDetails;