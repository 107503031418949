import React from 'react';
import HourlyAnalyticsBar from './HourlyAnalyticsBar';

const HourlyDataSection = ({ graphData }) => {
  console.log(graphData);

  // Check if data is truly empty (all zeros or no data)
  const hasData = graphData.some((item) => 
    item.delivered_count !== "0" || item.read_count !== "0"
  );

  if (!hasData || graphData.length === 0) {
    return (
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>Hourly Data</span>
            </h1>
            <div className="emty-column">
              <h5 className="emty-title-graph">
                There are no data to display
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Define the time ranges for the X-axis
  const hourRanges = [
    '00-02', '02-04', '04-06', '06-08', '08-10',
    '10-12', '12-14', '14-16', '16-18', '18-20',
    '20-22', '22-24'
  ];

  // Aggregate data for duplicate hour ranges
  const aggregatedData = {};
  
  hourRanges.forEach(range => {
    aggregatedData[range] = { delivered: 0, read: 0 };
  });

  // Sum up values for each hour range
  graphData.forEach(item => {
    const range = item.hour_range;
    if (aggregatedData[range]) {
      aggregatedData[range].delivered += Number(item.delivered_count);
      aggregatedData[range].read += Number(item.read_count);
    }
  });

  // Convert aggregated data into arrays for the chart
  const deliveryData = hourRanges.map(range => aggregatedData[range].delivered);
  const readData = hourRanges.map(range => aggregatedData[range].read);

  // Chart data structure
  const chartData = {
    categories: hourRanges, // X-axis labels
    series: [
      {
        name: 'Delivery Count',
        data: deliveryData,
        color: '#1279BE'
      },
      {
        name: 'Read Count',
        data: readData,
        color: '#03335B'
      }
    ]
  };

  return (
    <div className="row tw-my-4">
      <div className="col-md-12 mb-3">
        <div className="dashboard-graph-box mb-3">
          <h1 className="dashboard-graph-title">
            <span>Hourly Data</span>
          </h1>
          <HourlyAnalyticsBar 
            categories={chartData.categories}
            series={chartData.series}
            height={400}
          />
        </div>
      </div>
    </div>
  );
};

export default HourlyDataSection;