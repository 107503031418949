import React, { useEffect, useState } from "react";
import "../../css/RPMMembeProfile.css";
import MemberProfileDetails from "../../components/MemberProfileDetails";
import MemberProfileDetailsBox from "../../components/MemberProfileDetailsBox";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { VitalsProvider } from "../../components/VitalsDisplayPanel/VitalsContext";
import { fetch_all_alerts, dismiss_alert } from "../../../../services/rpm/Service";
import Notifications from "../../components/Modals/Notification";



function RPMMembeProfile() {
  const handleBackButton = () => {
      navigate('/rpm-dashboard');
  }
  const navigate = useNavigate();
  const { id } = useParams();
  const [allAlerts, setAllAlerts] = useState([]);

  useEffect(() => {
    fetchAllAlerts();
  }, []);

  // fetdh all the alerts for the member
  function fetchAllAlerts(){ 
    fetch_all_alerts({
      userId: id
    }).then((response) => {
      setAllAlerts(response.data?.data?.data || []); 
    }).catch((error) => {
      console.log(error);
    });
  }


  // dismiss alert function
  function dismissAlert(alertId){
    dismiss_alert({
      alertId: alertId
    }).then((response) => {
      fetchAllAlerts();
    }).catch(() => { 
        console.log("error in dismissing alert");
    });
  }

  return (
    <>
    <VitalsProvider>
      <section className="ContentRPMMembeProfile ">
        <div className="container-fluid px-0 pb-3 mb-5">
          <div className="RPMMembeProfile-content-wrapper">
            <div className="RPMMembeProfile-content-wrapper-inner">
              <div className="row align-items-center pb-3 sticky-top-bar">
              <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
              <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                <h1 className="main-title mb-0">Dashboard Overview</h1>
              
              </div>

              {allAlerts.length > 0 && <Notifications allAlerts={allAlerts} dismissAlert={dismissAlert} />}
              </div>
              <MemberProfileDetails memberId={id} />
            </div>

            <div className="RPMMembeProfile-content-aside sticky-top-bar">
            <MemberProfileDetailsBox />
            </div>
          </div>
        </div>
      </section>
      </VitalsProvider>
    </>
  );
}

export default RPMMembeProfile;
