import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const VitalsCards = ({
  totalAlerts,
  selected,
  filteredAlertStats,
  highestAlertCount,
}) => {
  return (
    <div className="tw-mb-4 tw-flex tw-flex-col tw-w-full tw-gap-2 tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-3">
      <div className="tw-flex tw-flex-row tw-w-full tw-gap-2">
        {/* Total Alerts Card - Styled like the "ENROLLED" card */}
        <div className="tw-bg-[#d0edff] tw-rounded-lg tw-p-3 tw-w-1/5">
          <div className="tw-flex tw-items-center tw-justify-between">
            <div>
              <span className="tw-text-lg tw-font-bold">
                {totalAlerts}
                {/* /{selected.length === 0 ? 12 : selected.length * 4} */}
              </span>
              <div className="tw-flex tw-items-center">
                <h2 className="tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-0">
                  TOTAL VITAL ALERTS
                </h2>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {selected.length === 0
                        ? "Total number of vital sign alerts across all users"
                        : `Total alerts for ${selected.length} selected vital type(s)`}
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle tw-ml-2 tw-text-gray-500 tw-cursor-pointer"></i>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>

        {/* Individual Alert Type Cards */}
        <div className="tw-grid tw-grid-cols-4 tw-gap-4 tw-w-4/5">
          {filteredAlertStats.map((item, index) => {
            const isHighest =
              parseInt(item.value) === highestAlertCount &&
              highestAlertCount > 0;
            return (
              <div
                key={index}
                className={`tw-bg-[#f0f4ff] tw-rounded-lg tw-p-3 ${isHighest
                    ? "border tw-border-2 tw-border-[#1279BE]"
                    : "border tw-border-gray-200"
                  }`}
              >
                <div className="tw-flex tw-flex-col">
                  <span className="tw-text-2xl tw-font-bold tw-text-gray-800">
                    {item.value}
                  </span>
                  <div className="tw-flex tw-items-center tw-mt-1">
                    <span className="tw-text-sm tw-text-gray-500">
                      {item.title}
                    </span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{item.tooltip}</Tooltip>}
                    >
                      <i className="bx bx-info-circle tw-ml-1 tw-text-gray-500 tw-cursor-pointer"></i>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VitalsCards;
