import React from 'react';
import './MemberProfileBox.css';
import { useNavigate } from 'react-router-dom';
import ProfileCard from './ProfileCard/ProfileCard';
import { LoaderLoader } from '../../utils/CommonLibrary';

function MemberProfileBox({ 
  profiles = [], 
  pagination = {
    totalRecords: 0,
    totalPages: 0,
    currentPage: 1,
    perPage: 12,
    pageSizeOptions: [12, 24, 36],
    displayedRecords: 0,
    startRecord: 0,
    endRecord: 0
  },
  isLoading = false,
  onPageChange = () => {},
  onRowsPerPageChange = () => {}
}) {

  // Handler for page change buttons
  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  // Handler for rows per page dropdown
  const handleRowsPerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value, 10);
    onRowsPerPageChange(newPerPage);
  };

  return (
    <>
      <div className='row'>
        {isLoading ? (
          <LoaderLoader />
        ) : profiles.length > 0 ? (
          profiles.map(member => (
            <ProfileCard key={member.id} member={member} />
          ))
        ) : (
          <div className="col-12 text-center py-4">
            <h4>No members found matching your search criteria</h4>
          </div>
        )}
      </div>
      <div>
        <div className="pagination-container" style={{backgroundColor:"", justifyContent:"center"}}>
          <div className="rows-per-page">
            <label>Rows per page: </label>
            <select 
              value={pagination.perPage} 
              onChange={handleRowsPerPageChange}
            >
              {pagination.pageSizeOptions && pagination.pageSizeOptions.length > 0 
                ? pagination.pageSizeOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))
                : [12, 24, 36].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))
              }
            </select>
          </div>

          <div className="records-info">
            {pagination.displayedRecords > 0 ? 
              `${pagination.startRecord} - ${pagination.endRecord} of ${pagination.displayedRecords} records` :
              '0 records found'}
          </div>

          {/* pagination controls */}
          <div className="pagination-controls">
            <button onClick={() => handlePageChange(1)} disabled={pagination.currentPage === 1}>
              <i className='bx bx-first-page'></i>
            </button>
            <button onClick={() => handlePageChange(pagination.currentPage - 1)} disabled={pagination.currentPage === 1}>
              <i className='bx bx-chevron-left'></i>
            </button>
            <button onClick={() => handlePageChange(pagination.currentPage + 1)} disabled={pagination.currentPage === pagination.totalPages}>
              <i className='bx bx-chevron-right'></i>
            </button>
            <button onClick={() => handlePageChange(pagination.totalPages)} disabled={pagination.currentPage === pagination.totalPages}>
              <i className='bx bx-last-page'></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberProfileBox;