import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const CompleteHealthTask = ({
  showCompleteModal,
  handleConfirmYes,
  handleConfirmNo,
  taskToComplete,
  showBgLogModal,
  closeBgLogModal,
  bgLevel,
  setBgLevel,
  bgType,
  setBgType,
  mealType,
  setMealType,
  selectedMeal,
  setSelectedMeal,
  saveBloodGlucoseData,
  bgTime,
  timeFormat,
  setBgTime,
  setTimeFormat,
}) => {
  const generateMiniCalendar = () => {
    const today = moment();
    const currentDay = today.day(); // 0 (Sunday) to 6 (Saturday)

    // Adjust to make Monday the first day (if needed)
    const adjustedCurrentDay = currentDay === 0 ? 6 : currentDay - 1;

    // Start from Monday of the current week
    const startOfWeek = today.clone().subtract(adjustedCurrentDay, "days");

    const weekDays = [];
    const weekDates = [];

    // Generate the week days and dates
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, "days");
      weekDays.push(day.format("dd").charAt(0)); // M, T, W, etc.
      weekDates.push({
        date: day.date(),
        isToday: day.isSame(today, "day"),
      });
    }

    return { weekDays, weekDates };
  };

  const [miniCalendar, setMiniCalendar] = useState(() =>
    generateMiniCalendar()
  );

  useEffect(() => {
    if (showBgLogModal) {
      setMiniCalendar(generateMiniCalendar());
    }
  }, [showBgLogModal]);

  return (
    <>
      {/* Complete Task Confirmation Modal */}

      <Modal show={showCompleteModal} onHide={handleConfirmNo} centered>
        <Modal.Header className="tw-flex-row" closeButton>
          <Modal.Title className="tw-text-lg tw-mt-0 tw-w-[90%] tw-text-center tw-pl-[10%]">
            Complete Habit '{taskToComplete.task?.description}'
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pb-0">
          <p className="tw-text-center">
            Are you sure you want to mark this habit as complete?
            {/* <span className="fw-bold d-block">
            {taskToDelete.task?.description}
          </span> */}
          </p>
        </Modal.Body>
        <Modal.Footer className="tw-border-0 tw-justify-center">
          <button className="btn btn-success" onClick={handleConfirmYes}>
            Yes
          </button>
          <button className="btn btn-danger" onClick={handleConfirmNo}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* Blood Glucose Logging Modal */}
      <Modal show={showBgLogModal} onHide={closeBgLogModal} centered>
        <Modal.Header className="tw-flex-row" closeButton>
          <Modal.Title className="tw-text-lg tw-mt-0 tw-w-[90%] tw-text-center tw-pl-[10%]">
            Log Blood Glucose
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tw-pb-3">
          {/* Mini Calendar */}
          <div className="tw-bg-gray-100 tw-rounded-md tw-p-2 tw-mb-4">
            <div className="tw-flex tw-justify-between tw-text-center">
              {miniCalendar.weekDays.map((day, index) => (
                <div key={`day-${index}`} className="tw-w-10">
                  {day}
                </div>
              ))}
            </div>
            <div className="tw-flex tw-justify-between tw-text-center">
              {miniCalendar.weekDates.map((dateObj, index) => (
                <div
                  key={`date-${index}`}
                  className={`tw-w-10 ${
                    dateObj.isToday
                      ? "tw-bg-[#03335B] tw-text-white tw-rounded-full"
                      : ""
                  }`}
                >
                  {dateObj.date}
                </div>
              ))}
            </div>
            <div className="tw-text-center tw-mt-1">
              <span className="tw-inline-flex tw-items-center">
                <span className="tw-border tw-border-gray-400 tw-inline-block tw-mr-1 tw-w-3 tw-h-3"></span>
                TODAY
              </span>
            </div>
          </div>

          {/* Time Input */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700 tw-mb-1">Time</label>
            <div className="tw-flex tw-items-center">
              <input
                type="text"
                className="form-control tw-text-center"
                value={bgTime}
                onChange={(e) => setBgTime(e.target.value)}
              />
              <select
                className="form-select tw-ml-2 tw-w-20"
                value={timeFormat}
                onChange={(e) => setTimeFormat(e.target.value)}
              >
                <option value="am">am</option>
                <option value="pm">pm</option>
              </select>
            </div>
          </div>

          {/* Blood Glucose Level */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700 tw-mb-1">
              Blood Glucose Level
            </label>
            <div className="tw-flex tw-items-center">
              <input
                type="text"
                className={`form-control tw-text-center ${
                  bgLevel && parseInt(bgLevel) > 0 ? "tw-border-green-500" : ""
                }`}
                value={bgLevel}
                onChange={(e) => setBgLevel(e.target.value)}
                placeholder="000"
              />
              <span className="tw-ml-2">mg/dl</span>
            </div>
          </div>

          {/* Fasting/Postprandial Toggle */}
          <div className="tw-mb-4">
            <div className="tw-flex tw-rounded-md tw-overflow-hidden border tw-border-gray-300">
              <button
                className={`tw-flex-1 tw-py-2 tw-px-4 tw-border-none tw-font-semibold ${
                  bgType === "Fasting"
                    ? "tw-bg-[#03335b] tw-text-white"
                    : "tw-bg-white tw-text-[#03335B]"
                }`}
                onClick={() => setBgType("Fasting")}
              >
                Fasting
              </button>
              <button
                className={`tw-flex-1 tw-py-2 tw-px-4 tw-border-none tw-font-semibold ${
                  bgType === "Postprandial"
                    ? "tw-bg-[#03335b] tw-text-white"
                    : "tw-bg-white tw-text-[#03335B]"
                }`}
                onClick={() => setBgType("Postprandial")}
              >
                Postprandial
              </button>
            </div>
          </div>

          {/* Meal Type (shown only for Postprandial) */}
          {bgType === "Postprandial" && (
            <div className="tw-mb-4">
              <label className="tw-block tw-text-gray-700 tw-mb-1">
                Meal Type
              </label>
              <select
                className="form-select"
                value={mealType}
                onChange={(e) => setMealType(e.target.value)}
              >
                <option value="Random">Random</option>
                <option value="Before meal">Before meal</option>
                <option value="After meal (2 hrs)">After meal (2 hrs)</option>
              </select>
            </div>
          )}

          {/* Meal Selection (shown only for "Before meal") */}
          {bgType === "Postprandial" && mealType === "Before meal" && (
            <div className="tw-mb-4">
              <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                <button
                  className={`tw-py-2 tw-px-4 tw-rounded-md border ${
                    selectedMeal === "Breakfast"
                      ? "tw-bg-[#03335B] tw-text-white"
                      : "tw-bg-white tw-text-gray-700"
                  }`}
                  onClick={() => setSelectedMeal("Breakfast")}
                >
                  Breakfast
                </button>
                <button
                  className={`tw-py-2 tw-px-4 tw-rounded-md border ${
                    selectedMeal === "Lunch"
                      ? "tw-bg-[#03335B] tw-text-white"
                      : "tw-bg-white tw-text-gray-700"
                  }`}
                  onClick={() => setSelectedMeal("Lunch")}
                >
                  Lunch
                </button>
                <button
                  className={`tw-py-2 tw-px-4 tw-rounded-md border ${
                    selectedMeal === "Snack"
                      ? "tw-bg-[#03335B] tw-text-white"
                      : "tw-bg-white tw-text-gray-700"
                  }`}
                  onClick={() => setSelectedMeal("Snack")}
                >
                  Snack
                </button>
                <button
                  className={`tw-py-2 tw-px-4 tw-rounded-md border ${
                    selectedMeal === "Dinner"
                      ? "tw-bg-[#03335B] tw-text-white"
                      : "tw-bg-white tw-text-gray-700"
                  }`}
                  onClick={() => setSelectedMeal("Dinner")}
                >
                  Dinner
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="tw-border-0 tw-justify-center">
          <button
            className="action-button blue-btn tw-py-2 tw-px-4"
            onClick={saveBloodGlucoseData}
            disabled={!bgLevel}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompleteHealthTask;
