import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
// import WhatsAppAnalyticsBar from "./WhatsAppAnalyticsBar";
import WhatsAppAnalyticsPie from "./WhatsAppAnalyticsPie";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { decryptData, encryptData } from "../../utils/Utils";
import { MultiSelect } from "react-multi-select-component";
import {
  fetch_count,
  fetch_carenavigator,
  fetch_admin_insights,
  fetch_admins,
  fetchDashboardList,
  fetch_agent_insights,
  fetch_unregistered_insights,
  fetchReaderGenderGraphAge,
  fetchHourlyDatagraph,
  fetchUserChatList,
  fetchAnalyticRoleList,
  fetchAgentList,
  optout_users_download,
} from "../../../services/whatsapp/Service";
// import WhatsAppAnalyticsBar2 from "./WhatsAppAnalyticsBar2";
import { LoaderLoader } from "../../utils/CommonLibrary";
// import { set } from "firebase/database";
import AnalyticsTable from "./AnalyticsTable";
import DemographicsSection from "./DemographicsSection";
import HourlyDataSection from "./HourlyDataSection";

const AnalyticsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [seriesGraph, setSeriesGraph] = useState([]);
  const [adminGraph, setAdminGraph] = useState([]);
  const [agentGraph, setAgentGraph] = useState([]);
  const [unregisteredGraph, setUnregisteredGraph] = useState([]);
  const [readerGraph, setReaderGraph] = useState([]);
  const [hourlyDataGraph, setHourlyDataGraph] = useState([]);
  const [userDataGraph, setUserDataGraph] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRowData, setTotalRowData] = useState(0);

  const [adminsUsers, setAdminusers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Optional if provided by API

  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );
  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const [selectedDashboard, setSelectedDashboard] = useState("all");

  const [dashboardList, setDashboardList] = useState([]);
  const [roleAnalyticList, setRoleAnalyticList] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const [agents, setAgents] = useState("");
  const [role, setRole] = useState("");
  const [msgType, setMsgType] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [agentList, setAgentList] = useState([]);

  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Add this handler function
  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Mock the localStorage for the date range picker
  useEffect(() => {
    // const mockStoredDates = [
    //   moment().subtract(7, "days"),
    //   moment(),
    //   moment().startOf("year"),
    //   moment(),
    // ];
    // localStorage.setItem(
    //   "selectedDates",
    //   encryptData(JSON.stringify(mockStoredDates))
    // );
    fetchDashboard();
    fetchAnalyticRole();
  }, []);

  useEffect(() => {
    if (role) {
      // Reset agent selection when role changes
      setSelectedOptions([]);
      setSelectedAgent("");
      fetchAgents();
    }
  }, [role]);

  const fetchDashboard = async () => {
    setLoading(true);
    try {
      const resp = await fetchDashboardList();
      if (resp.data.code === 200) {
        setDashboardList(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetchAnalyticRole = async () => {
    setLoading(true);
    try {
      const resp = await fetchAnalyticRoleList();
      if (resp.data.code === 200) {
        setRoleAnalyticList(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetchAgents = async () => {
  setLoading(true);
  try {
    const resp = await fetchAgentList({ roleId: role }); 
    if (resp.data.code === 200) {
      const data = resp.data.data.map((t) => ({
        label: t.fullName,
        value: t.id,
      }));
      setAgentList(data);
    }
  } catch (error) {
    console.error("Error fetching agents: ", error);
  } finally {
    setLoading(false);
  }
};

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_count({
        roleId: role,
        agents: selectedAgent,
        startDate: start,
        endDate: end,
        msgType:msgType,
        dashboardId: selectedDashboard,
      });
      if (response.data.code == 200 && response.data.data.insights.length > 0) {
        setAnalyticsData(response.data.data.insights);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetchReaderDemograph = async (start, end) => {
    try {
      const response = await fetchReaderGenderGraphAge({
        roleId: role,
        agents: selectedAgent,
        startDate: start,
        endDate: end,
        msgType:msgType,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setReaderGraph(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchHourlyDatagraphlist = async (start, end) => {
    try {
      const response = await fetchHourlyDatagraph({
        roleId: role,
        agents: selectedAgent,
        startDate: start,
        endDate: end,
        msgType:msgType,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setHourlyDataGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchUserChatData = async (start, end) => {
    try {
      const response = await fetchUserChatList({
        roleId: role,
        agents: selectedAgent,
        startDate: start,
        endDate: end,
        msgType:msgType,
        dashboardId: selectedDashboard,
        pageNumber:currentPage,
        perPage:perPage
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setUserDataGraph(response.data.data);
        setTotalRowData(response.data.totalRows);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admin_insights_data = async (start, end) => {
    setLoading(true);
    try {
      const response = await fetch_admin_insights({
        startDate: start,
        endDate: end,
        roleId:role,
        agents:selectedAgent,
        msgType:msgType,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminGraph(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetch_agent_insights_data = async (start, end) => {
    try {
      const responseRow = await fetch_agent_insights({
        startDate: start,
        endDate: end,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (responseRow.data.code == 200 && responseRow.data.data) {
        setAgentGraph(responseRow.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetch_admins_data = async (start, end, page = 1, limit = 10) => {
    setLoading(true);
    try {
      const response = await fetch_admins({
        startDate: start,
        endDate: end,
        page: page,
        limit: limit,
      }); // Pass the current page to the API
      if (response.data.code == 200 && response.data.data) {
        setAdminusers(response.data.data.list);
        setTotalRecords(response.data.data.total);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };

  const fetch_unregistered_insights_data = async (start, end) => {
    try {
      const responseunRow = await fetch_unregistered_insights({
        startDate: start,
        endDate: end,
        dashboardId: selectedDashboard,
      }); // Pass the current page to the API
      if (responseunRow.data.code == 200 && responseunRow.data.data) {
        setUnregisteredGraph(responseunRow.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    // Reset pagination when filters change
    if (currentPage !== 1) {
      setCurrentPage(1);
      return; // Avoid double fetch
    }
    
    const start = formatDate(startDate);
    const end = formatDate(endDate);
    
    // Batch these operations to avoid multiple loading state changes
    const fetchMainData = async () => {
      setLoading(true);
      try {
        // Fetch all non-paginated data in parallel
        await Promise.all([
          fetchData(start, end),
          fetchReaderDemograph(start, end),
          fetchHourlyDatagraphlist(start, end),
          msgType !== 2 ? fetch_admin_insights_data(start, end) : Promise.resolve(),
          fetch_agent_insights_data(start, end),
          fetch_unregistered_insights_data(start, end)
        ]);
      } catch (error) {
        console.error("Error fetching main dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchMainData();
  }, [role, selectedAgent, msgType, startDate, endDate, selectedDashboard]);

  useEffect(() => {
    const start = formatDate(startDate);
    const end = formatDate(endDate);
    
    // No need to set loading state here as it's handled by the table component
    fetchUserChatData(start, end);
    
  }, [role, selectedAgent, msgType, startDate, endDate, selectedDashboard, currentPage, perPage]);

  useEffect(() => {
    fetch_admins_data(
      formatDate(startDate),
      formatDate(endDate),
      currentPage,
      perPage
    );
  }, [startDate, endDate, msgType, selectedDashboard]);

  console.log(adminGraph, "adminGraph");
  console.log(adminGraph.adminCount, "seriesGraph.adminCount");
  console.log("selectedDashboard", selectedDashboard);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    // Create the comma-separated string of IDs
    const valueArray = selected.map((option) => option.value).join(",");
    setSelectedAgent(valueArray);
    
    // Reset to page 1 when changing agent selection
    setCurrentPage(1);
  };

  const handleMsgTypeChange = (e) => {
    setMsgType(Number(e.target.value));
    setCurrentPage(1);
  };

 const handleDownloadOptOutMembers = async () => {
    try {
       setLoading(true);
      // This is a dummy API call that you will replace with the actual one
      const response = await optout_users_download({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `OptOutMembers_${formatDate(startDate)}_to_${formatDate(endDate)}.csv`);

      // Append, trigger click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading opt-out members:", error);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <div className="container-fluid tw-mb-16">
      <LoaderLoader isLoading={loading} />
      <div className="tw-sticky tw-top-14 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
        <div className="tw-w-full  tw-pb-3">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-4 tw-w-full">
            <h4 className="tw-text-gray-600 tw-m-0">Engagement Analytics</h4>
            <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-4">
              {/* <select
                value={selectedDashboard}
                onChange={(e) => setSelectedDashboard(e.target.value)}
                className="form-select tw-rounded-xl"
                style={{
                  width: "50%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                 <option value="all">All</option>
                {dashboardList?.map((option, index) => (
                  <option key={index} value={option.dashboardId}>
                    {option.dashboardName}
                  </option>
                ))}
              </select> */}
              <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="form-select tw-rounded-xl  tw-max-w-[20%]"
                style={{
                  width: "50%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                <option value="all">Select Role</option>
                {roleAnalyticList?.map((option, index) => (
                  <option key={index} value={option.roleName}>
                    {option.roleName} {option.roleId}
                  </option>
                ))}
              </select>
              <MultiSelect
                options={agentList}
                value={selectedOptions}
                onChange={handleChange}
                labelledBy="Select"
                hasSelectAll={false}
                className="form-select select-input-ui-select tw-rounded-xl tw-max-w-[20%]"
                disableSearch={true}
                overrideStrings={{
                  selectAll: "Select All",
                  allItemsAreSelected: "All items are selected",
                  selectSomeItems: "Select Agents",
                }}
                style={{ fontSize: "1rem" }}
              />
              <select
                value={msgType}
                onChange={handleMsgTypeChange}
                className="form-select tw-rounded-xl"
                placeholder="Select Dashboard"
                style={{
                  width: "50%",
                  padding: "10px 14px",
                  border: "1px solid #DCDCDC !important",
                }}
              >
                {/* <option disabled value="">Select Message Type</option> */}
                <option value="0">All</option>
                <option value="1">Template</option>
                <option value="2">Free-Form</option>
              </select>
              <div
                className="form-group  tw-max-w-[25%]"
                style={{ width: "50%" }}
              >
                <CalendarDatePicker
                  startDate={startDate}
                  endDate={endDate}
                  onDateChange={handleDateChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reports-programs mb-3">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <img
              src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/programperformance.png"
              alt="Invited Members icon"
              style={{ height: "140px" }}
              className="img-fluid mb-0"
            />
          </div>
          <div className="col-md">
            <p className="mb-3">
              The Engagememt Analytics Dashboard provides key performance
              metrics for Engagement Admins and Agents, including total
              conversations, responses, pending responses, and opt-outs. It
              features visualizations like wait time, average response time,
              opt-out trends, and template usage. A performance table with
              detailed metrics and tooltips helps team leads monitor efficiency
              and member engagement.
            </p>
          </div>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-bg-white tw-rounded-xl tw-shadow-md tw-text-center tw-p-2 tw-mt-2">
        {analyticsData.map((item, index) => (
          <div key={index} className="tw-px-1">
            <div className="tw-bg-[#f0f4ff] tw-rounded-[14px]">
              <div className="tw-p-4 tw-relative">
            {(item.title === "Opt-Out Members" && item.value > 0) && (
              <span className=" tw-absolute tw-right-4 tw-top-2 tw-text-[#03335B] tw-cursor-pointer tw-px-1 tw-rounded-lg"
              onClick={handleDownloadOptOutMembers}
              >
                <i class="bi bi-download tw-font-bold"></i>
              </span>
                )}
                <div className="tw-flex tw-flex-col">
                  <div className="tw-relative">
                    <h1 className="tw-text-[24px] tw-font-semibold tw-text-[#333] tw-mb-1 tw-text-start tw-font-ibm-plex">
                      {item.value}
                    </h1>
                    <p className="tw-text-sm tw-mb-0 tw-text-[#636773] tw-flex tw-items-center tw-justify-start">
                      {item.title}
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{item.tooltip}</Tooltip>}
                      >
                        <i className="bx bx-info-circle tw-ml-1 tw-cursor-pointer"></i>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      

      {/* Template Breakdown Pie Chart */}
      {msgType !== 2 && (
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Template-wise Breakdown
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of template usage
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            {adminGraph.templateResult &&
            adminGraph.templateResult.length > 0 ? (
              <WhatsAppAnalyticsPie series={adminGraph.templateResult} />
            ) : (
              <div className="emty-pie">
                <h5 className="emty-title-graph">
                  There are no data to display
                </h5>
              </div>
            )}        
          </div>
        </div>
      </div>
      )}

      {/* Reader Demographics Section */}
      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                Reader Demographics
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">
                      Distribution of readers by gender and age
                    </Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            <DemographicsSection graphData={readerGraph} />
          </div>
        </div>
      </div>

      <HourlyDataSection graphData={hourlyDataGraph} />

      <div className="row tw-my-4">
        <div className="col-md-12 mb-3">
          <div className="dashboard-graph-box mb-3">
            <h1 className="dashboard-graph-title">
              <span>
                All Data
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="custom-tooltip">All Data</Tooltip>
                  }
                >
                  <i className="bx bx-info-circle ms-1"></i>
                </OverlayTrigger>
              </span>
            </h1>
            <AnalyticsTable userDataGraph={userDataGraph} totalRowData={totalRowData} fetchUserChatData={fetchUserChatData} startDate={startDate} endDate={endDate} setCurrentPage={setCurrentPage} currentPage={currentPage} setPerPage={setPerPage} perPage={perPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
