import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function BodyTemperature({ data }) {
    // Use data.history for your chart rendering
    const temperatureData = data?.history || [];

    // Extract data from history if available
    const timeLabels = temperatureData.map(item => item.date);
    const tempValues = temperatureData.map(item => item.value);

    const options = {
        chart: {
            type: 'line',
            height: 260,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: timeLabels,
            labels: {
                rotation: 0 
            }
        },
        yAxis: {
            title: {
                text: 'Body Temperature'
            },
            min: null,
            max: null
        },
        series: [{
            name: 'Temperature',
            data: tempValues,
            color: '#FF6B6B',
        }],
        tooltip: {
            valueSuffix: ' °F'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
    };

    return (
        <div className="col-md-12 mb-3">
            <div className="member-engagement">
                <h1 className='dashboard-graph-title mb-2'>
                    <span>Body Temperature</span>
                </h1>
                <h1 className="card-title1">{data?.current || '-'}<span>°F (Avg)</span></h1>
                <div className='mt-3'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}

export default BodyTemperature;