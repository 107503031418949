import React from 'react';
import WhatsAppAnalyticsPie from './McWhatsAppAnalyticsPie';

const McDemographicsSection = ({graphData}) => {
   // Dummy data for Gender distribution
   console.log("graphData",graphData);
   const formatNumber = (num) => {
     const number = Number(num) || 0;
     return number.toLocaleString(); // Formats with commas (e.g., 3,731)
   };
   
   const safeNumber = (value) => isNaN(value) ? 0 : parseInt(value);
 
   const genderData = [
     { name: 'Female', y: safeNumber(graphData.female_count) || 0, color: '#FF1493', label: formatNumber(graphData.female_count)},  // Pink
     { name: 'Male', y: safeNumber(graphData.male_count) || 0, color: '#00CED1', label: formatNumber(graphData.male_count)}     // Cyan
   ];
 
   // Dummy data for Age distribution
   const ageData = [
     { name: '50+ Y', y: Number(graphData.age_above_50) || 0, color: '#FFA500', label: formatNumber(graphData.age_above_50) },    // Orange
     { name: '40Y - 50Y', y: Number(graphData.age_40_50) || 0, color: '#32CD32', label: formatNumber(graphData.age_40_50) }, // Green
     { name: '30Y - 40Y', y: Number(graphData.age_30_40) || 0, color: '#87CEEB' , label: formatNumber(graphData.age_30_40)}, // Light Blue
     { name: '20Y - 30Y', y: Number(graphData.age_20_30) || 0, color: '#4682B4', label: formatNumber(graphData.age_20_30) }   // Steel Blue
   ];
 
   console.log(genderData.every(item => item.y === 0));
   
   return (
     <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4">
       <div className="dashboard-graph-box">
         <h1 className="dashboard-graph-title tw-mb-4">
           <span className="tw-text-lg tw-font-semibold">Gender</span>
         </h1>
         {genderData.every((item) => item.y === 0) ? (
           <div className="emty-pie">
             <h5 className="emty-title-graph">There are no data to display</h5>
           </div>
         ) : (
           <WhatsAppAnalyticsPie series={genderData} />
         )}
       </div>
 
       <div className="dashboard-graph-box">
         <h1 className="dashboard-graph-title tw-mb-4">
           <span className="tw-text-lg tw-font-semibold">Age</span>
         </h1>
         {ageData.every((item) => item.y === 0) ? (
           <div className="emty-pie">
             <h5 className="emty-title-graph">There are no data to display</h5>
           </div>
         ) : (
             <WhatsAppAnalyticsPie series={ageData} />
         )}
       </div>
     </div>
   );
}

export default McDemographicsSection;